import { useEffect, useRef, useState } from 'react'
import { Row, Col, Card, Select, Collapse, Typography } from 'antd'
import { saveAs } from 'file-saver'
import { _getSpesification, _getEmissionSpecification, _getEquipmentSpecification, _getMeServiceSpecification, _getSimSearchSpecification, _getRycSpecification } from '../Utils/apiCalls'
import _notification from '../Utils/notification'
import _handleErrors from '../Utils/handleErrors'
import useFilter from '../Utils/useFilter'
import CustomButton from './CustomButton'
import CustomInput from './CustomInput'
import CustomSelect from './CustomSelect'

import PanelHeader from './PanelHeader'
import SpecificationLabel from './SpesificationLabel'
import { RightOutlined } from '@ant-design/icons'
import CustomSwitch from './CustomSwitch'
import ExampleSpesificationData from "../Assets/ExampleSpesification/example_specification_vehicles.json"
import ExampleEmissionData from "../Assets/ExampleSpesification/example_specification_emissions.json"
import ExampleEquipmentData from "../Assets/ExampleSpesification/example_specification_equipments.json"
import ExampleMeServiceData from "../Assets/ExampleSpesification/example_specification_meservice.json"
import ExampleSimSearchData from "../Assets/ExampleSpesification/example_specification_simsearch.json"
import { marketList } from '../Constants/marketList'
import { useTranslation } from 'react-i18next'
import SpecificationPanel from "./SpecificationPanel"
import JsonWithSearchAndSave from './JsonWithSearchAndSave'
import { CopyOutlined } from '@ant-design/icons'


const { Option } = Select;
const { Panel } = Collapse;
const { Paragraph } = Typography;

const ExpandIconRightOutlined = ({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />

function Specification(props) {
  const { width, setApikeyEntered, tabNumber, apikeyRef } = props;
  const [apikey, setApikey] = useState(null);
  const [vin, setVin] = useState(null);
  const [marketId, setMarketId] = useState("de_DE");
  const [specificationBtn, setSpecificationBtn] = useState(false);
  const [emissionBtn, setEmissionBtn] = useState(true);
  const [equipmentsBtn, setEquipmentsBtn] = useState(true);
  const [meServiceBtn, setMeServiceBtn] = useState(true);
  const [simSearchBtn, setSimSearchBtn] = useState(true);
  const [rycBtn, setRycBtn] = useState(true);

  const [additionalSpecsBtn, setAdditionalSpecsBtn] = useState(false);
  const [optionsNullDescriptionBtn, setOptionsNullDescriptionBtn] = useState(true);
  const [optionsBtn, setOptionsBtn] = useState(false);
  const [technicalDataBtn, setTechnicalDataBtn] = useState(false);
  const [payloadNullValuesBtn, setPayloadNullValuesBtn] = useState(true);

  const [spesificationData, setSpesificationData] = useState(null);
  const [isImagesLoading, setIsImagesLoading] = useState(false);
  const [fileName, setFileName] = useState(true);
  const [isApiKeyData, setIsApiKeyData] = useState(false);
  const [notifyKey, setNotifyKey] = useState();
  const [prevVin, setPrevVin] = useState(null);
  const [nullFilter, setNullFilter] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const renderSpesificationData = useFilter(spesificationData, filterInput, nullFilter)


  // Panels
  let availableCategories = [];
  const [panels, setPanels] = useState(availableCategories);
  const [availablePanels, setAvailablePanels] = useState(availableCategories);

  const { t } = useTranslation();

  const prevNotifyKey = useRef();
  useEffect(() => prevNotifyKey.current = notifyKey);
  useEffect(() => {
    if(apikey){
      apikeyRef.current[tabNumber-1] = 1
      setApikeyEntered((prev => {
        let newApikeyEntered = [...prev];
        newApikeyEntered[tabNumber - 1] = 1;
        return newApikeyEntered;
      }))
    } else {
      apikeyRef.current[tabNumber-1] = 0
      setApikeyEntered((prev) => {
        let newApikeyEntered = [...prev];
        newApikeyEntered[tabNumber - 1] = 0;
        return newApikeyEntered;
      });
    }
  }, [apikey])

  // Data for the tables
  const specificationsSettingsDataTable = [
    {
      key: 1,
      type: t("specificationsTab.specificationsTabName"),
      desc: t("specificationsTab.infoTable.contentSettings.specificationsDesc"),
    },
    // {
    //   key: 2,
    //   type: t("specificationsTab.emissionsTabName"),
    //   desc: t("specificationsTab.infoTable.contentSettings.emissionsDesc"),
    // },
    {
      key: 3,
      type: t("specificationsTab.equipmentsTabName"),
      desc: t("specificationsTab.infoTable.contentSettings.equipmentsDesc"),
    },
    {
      key: 4,
      type: t("specificationsTab.meServicesTabName"),
      desc: t("specificationsTab.infoTable.contentSettings.meServicesDesc"),
    },
    {
      key: 5,
      type: t("specificationsTab.simSearchTabName"),
      desc: t("specificationsTab.infoTable.contentSettings.simSearchDesc"),
    },
    {
      key: 6,
      type: t("specificationsTab.rycTabName"),
      desc: t("specificationsTab.infoTable.contentSettings.rycDesc"),
    },
    {
      key: 7,
      type: t("components.selectMarket"),
      desc: t("specificationsTab.infoTable.selectMarket"),
      default: "de_DE",
    },
  ];

  const specificationsParametersDataTable = [
    {
      key: 1,
      type: t("specificationsTab.specificationsParametersLabels.additionalSpecs"),
      desc: t("specificationsTab.infoTable.specificationsParameters.additionalSpecsDesc"),
    },
    {
      key: 2,
      type: t("specificationsTab.specificationsParametersLabels.optionsNullDescription"),
      desc: t("specificationsTab.infoTable.specificationsParameters.optionsNullDescriptionDesc"),
    },
    {
      key: 3,
      type: t("specificationsTab.specificationsParametersLabels.options"),
      desc: t("specificationsTab.infoTable.specificationsParameters.optionsDesc"),
    },
    {
      key: 4,
      type: t("specificationsTab.specificationsParametersLabels.technicalData"),
      desc: t("specificationsTab.infoTable.specificationsParameters.technicalDataDesc"),
    },
    {
      key: 5,
      type: t("specificationsTab.specificationsParametersLabels.payloadNullValues"),
      desc: t("specificationsTab.infoTable.specificationsParameters.payloadNullValuesDesc"),
    }
  ];

  useEffect(() => {
    if(!spesificationData) return;
    availableCategories = []
    if (spesificationData.specifications && Object.keys(spesificationData.specifications).length > 0) availableCategories.push("1")
    if (spesificationData.emissions && Object.keys(spesificationData.emissions).length > 0) availableCategories.push("2")
    if (spesificationData.equipments && Object.keys(spesificationData.equipments).length > 0) availableCategories.push("3")
    if (spesificationData.meservice && Object.keys(spesificationData.meservice).length > 0) availableCategories.push("4")
    if (spesificationData.simsearch && Object.keys(spesificationData.simsearch).length > 0) availableCategories.push("5")
    if (spesificationData.ryc && Object.keys(spesificationData.ryc).length > 0) availableCategories.push("6")

    setAvailablePanels(availableCategories)
  }, [spesificationData])

  const getSpesification = async () => {
    const params = { marketId, vin, apikey };
    const specificationQuery = { //reverse button values due to on/off button positions
      additionalSpecs: !additionalSpecsBtn, 
      optionsNullDescription: !optionsNullDescriptionBtn, 
      options: !optionsBtn, 
      technicalData: !technicalDataBtn, 
      payloadNullValues: !payloadNullValuesBtn
    };
    const tempFileName = vin
    if (prevVin !== vin) {
      setSpesificationData(null)
      setPrevVin(vin)
    }

    setIsImagesLoading(true);
    
    const specificationParams = {...params, ...specificationQuery};

    try {
      const specificationResponse = !specificationBtn && await _getSpesification(specificationParams);
      const equipmentsResponse = !equipmentsBtn && await _getEquipmentSpecification(params);
      // const emissionResponse = !emissionBtn && await _getEmissionSpecification(params);
      const meServiceResponse = !meServiceBtn && await _getMeServiceSpecification(params);
      const simSearchResponse = !simSearchBtn && await _getSimSearchSpecification(params);
      const rycResponse = !rycBtn && await _getRycSpecification(params);



      const specData = {};
      specData.specifications = specificationResponse.data && specificationResponse.status === 200 && specificationResponse.data.statusCode !== 404 ? specificationResponse.data : null;
      // specData.emissions = emissionResponse.data && (emissionResponse.status === 200 || emissionResponse.status === 204) && emissionResponse.data.statusCode !== 404 ? emissionResponse.data : null;
      specData.equipments = equipmentsResponse.data && equipmentsResponse.status === 200 && equipmentsResponse.data.statusCode !== 404 ? equipmentsResponse.data : null;
      specData.meservice = meServiceResponse.data && meServiceResponse.status === 200 && meServiceResponse.data.statusCode !== 404 ? meServiceResponse.data : null;
      specData.simsearch = simSearchResponse.data && simSearchResponse.status === 200 && simSearchResponse.data.statusCode !== 404 ? simSearchResponse.data : null;
      specData.ryc = rycResponse.data && rycResponse.status === 200 && rycResponse.data.statusCode !== 404 ? rycResponse.data : null;


      if (specificationResponse.status === 404) {
        openNotification(t("notification.error"), t("notification.vehiclesError") + vin + "\n", "load_with_specification_error_notification")
      }
      // if ((emissionResponse.data && emissionResponse.status === 204)) {
      //   openNotification(t("notification.error"), t("notification.emissionError") + vin + "\n", "load_with_emission_error_notification")
      // }
      if ((equipmentsResponse.status === 204)) {
        openNotification(t("notification.error"), t("notification.equipmentError") + vin + "\n", "load_with_equipment_error_notification")
      }

      setIsImagesLoading(false)


      if (specData.specifications || specData.emissions || specData.equipments || specData.meservice || specData.simsearch || specData.ryc) {
        openNotification(t("notification.finished"), `${t("notification.specificationLoaded")} ${tempFileName}.`, "load_notification")
        setSpesificationData(specData)
        setFileName(tempFileName)
        setIsApiKeyData(true)
      }

    } catch (error) {
      const errorMessage = _handleErrors(error, vin);
      console.log(errorMessage);

      setIsImagesLoading(false);
      openNotification(t("notification.error"), errorMessage)
    }
  }

  const getSampleSpesification = () => {
    setSpesificationData()
    setIsApiKeyData(false)
    setSpesificationData({ specifications: ExampleSpesificationData, emissions: ExampleEmissionData, equipments: ExampleEquipmentData, meservice: ExampleMeServiceData, simsearch: ExampleSimSearchData })
    openNotification(t("notification.finished"), `${t("notification.specificationLoaded")} ${t("notification.sample")}.`, "load_notification")
  }

  const saveSpesification = () => {
    const dataKeys = Object.keys(spesificationData);
    const zip = require("jszip")();
    let folderName;
    let commissionNumber;

    // Handle emission data
    if (spesificationData.emissions && spesificationData.emissions === "No data found") {
      spesificationData.emissions = null;
    }

    dataKeys.forEach((key) => {
      if (spesificationData[key] !== null && key === "simsearch") {
        spesificationData[key].forEach((element, index) => {
          if (index !== 0) {
            commissionNumber = element.vehicles[0].identification.commissionNumber;
          }
          if(index === 0) {
            folderName = `${fileName}_stocksearch.json`
          } else {
            folderName = `${fileName}_stockvehicles_${commissionNumber}.json`;
          }
          const fileContent = JSON.stringify(element, null, 2);
          zip.file(folderName, fileContent);
        });
      } else if (spesificationData[key] !== null) {
        const folderName = `${fileName}_${key}.json`;
        const fileContent = JSON.stringify(spesificationData[key], null, 2);
        zip.file(folderName, fileContent);
      }
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${fileName}_specification.zip`);
    });
  }

  const openNotification = (title, message, key) => {
    const config = {
      title: title,
      message: message,
      key: key,
      onClose: () => {
        setNotifyKey(undefined);
      }
    }
    setNotifyKey(_notification(config));
  }

  const copyCollapsable = (data, panelNumber) => {
    return <Paragraph
      copyable={{
        icon: <CopyOutlined style={{fontSize: 24, color: panels.includes(panelNumber) ? "white" : ""}} />,
        text: JSON.stringify(data, null, 2),
        tooltips: [t("specificationsTab.copyTheData"), t("specificationsTab.copiedData")],
      }} />
  }

  const panelHandler = (mode) => {
    switch (mode) {
      case 0: //Show All
        setPanels(availablePanels)
        break;
      case 1: //Collapse All
        setPanels([])
        break;
    }
  }

  return (
    <div className="BusinessTab specificationPanel" id='Spesification'>
      <Card className="PackagePanelInfo" bordered={false}>
        <Row>
          <Col className="DownloadModels">
            {t("specificationsTab.downloadText")}
          </Col>
        </Row>
        <div className="InputPackage">
          <Row gutter={width < 710 ? 0 : 20} style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "64px", marginTop: "-7px" }}>
            <Col flex={width < 769 ? "auto" : "2"} ><CustomInput label={t("placeholders.apiKeyPlaceholder")} getValue={setApikey} password={true} /></Col>
            <Col flex={width < 769 ? "auto" : "2"} ><CustomInput label={t("placeholders.vinNumberPlaceholder")} getValue={setVin} /></Col>
          </Row>

          <Collapse
            ghost={false}
            className="SpecificationsSettingsCollapse"
            expandIconPosition="start"
            key="SpecificationsSettings"
          >
            <Panel key={1} header={<PanelHeader headerName={t("specificationsTab.specificationsContentSettings")} description={t("specificationsTab.specificationsContentSettingsDesc")} data={specificationsSettingsDataTable} width={width} />} className="SpecificationsPanelSettings"> 
              <Row className="ImageSwitchGroup" gutter={[48, 10]} >
                <Col><CustomSwitch label={t("specificationsTab.specificationsTabName")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setSpecificationBtn} defaultValue={specificationBtn} /></Col>
                {/* <Col><CustomSwitch label={t("specificationsTab.emissionsTabName")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setEmissionBtn} defaultValue={emissionBtn} /></Col> */}
                <Col><CustomSwitch label={t("specificationsTab.equipmentsTabName")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setEquipmentsBtn} defaultValue={equipmentsBtn} /></Col>
                <Col><CustomSwitch label={t("specificationsTab.meServicesTabName")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setMeServiceBtn} defaultValue={meServiceBtn} /></Col>
                <Col><CustomSwitch label={t("specificationsTab.simSearchTabName")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setSimSearchBtn} defaultValue={simSearchBtn} /></Col>
                <Col><CustomSwitch label={t("specificationsTab.rycTabName")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setRycBtn} defaultValue={simSearchBtn} /></Col>
                <Col span={24}><CustomSelect showSearch label={t("components.selectMarket")} value={marketId} setValue={setMarketId} options={marketList.map(market => {
                    return {
                      id: market.market_code,
                      value: market.market_code,
                      name: market.market_name
                    }})
                } /></Col>
              </Row>
            </Panel>
            <Panel key={2} header={<PanelHeader headerName={t("specificationsTab.specificationsParameters")} description={t("specificationsTab.specificationsParametersDesc")} data={specificationsParametersDataTable} width={width} />} className="SpecificationsParameters">
              <Row className="ImageSwitchGroup" gutter={[48, 10]} >
                <Col><CustomSwitch label={t("specificationsTab.specificationsParametersLabels.additionalSpecs")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setAdditionalSpecsBtn} defaultValue={additionalSpecsBtn} /></Col>
                <Col><CustomSwitch label={t("specificationsTab.specificationsParametersLabels.optionsNullDescription")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setOptionsNullDescriptionBtn} defaultValue={optionsNullDescriptionBtn} /></Col>
                <Col><CustomSwitch label={t("specificationsTab.specificationsParametersLabels.options")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setOptionsBtn} defaultValue={optionsBtn} /></Col>
                <Col><CustomSwitch label={t("specificationsTab.specificationsParametersLabels.technicalData")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setTechnicalDataBtn} defaultValue={technicalDataBtn} /></Col>
                <Col><CustomSwitch label={t("specificationsTab.specificationsParametersLabels.payloadNullValues")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setPayloadNullValuesBtn} defaultValue={payloadNullValuesBtn} /></Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
        <div className="ButtonPackage">
          <Row gutter={[16, 16]} justify={"end"}>
            <Col><CustomButton label={t("loadSaveButtons.loadSampleSpecs")} onClick={getSampleSpesification} disabled={isImagesLoading} /></Col>
            <Col><CustomButton label={t("loadSaveButtons.loadSpecs")} onClick={getSpesification} loading={isImagesLoading} disabled={!apikey || !vin || (specificationBtn && emissionBtn && equipmentsBtn && meServiceBtn && simSearchBtn)} /></Col>
          </Row>
        </div>

      </Card>
      {spesificationData && <Row gutter={20} justify="end" style={{ marginBottom: "40px" }}>
        {isApiKeyData && <Col><CustomButton label={t("loadSaveButtons.saveSpecs")} filledIn={false} onClick={saveSpesification} isImagesLoading={isImagesLoading} style={{ float: "right" }} /></Col>}
      </Row>}

      {
        spesificationData && 
        <>
          <div>
            <SpecificationPanel panelHandler={panelHandler} panels={panels} availablePanels={availablePanels} />
          </div>
            <JsonWithSearchAndSave collapse={[panels, setPanels]} title={t("specificationsTab.filterSpecs")} filterInput={[filterInput, setFilterInput]} nullFilter={[nullFilter, setNullFilter]} >
                {
                  spesificationData.specifications &&
                  <Panel header={t("specificationsTab.specificationsTabName")} key="1" className="ModelsClasses" extra={renderSpesificationData && copyCollapsable(renderSpesificationData.specifications, "1")}>
                    <SpecificationLabel data={renderSpesificationData?.specifications} />
                  </Panel>
                }
                {/* {
                  spesificationData.emissions &&
                  <Panel header={t("specificationsTab.emissionsTabName")} key="2" className="ModelsClasses" extra={renderSpesificationData && copyCollapsable(renderSpesificationData.emissions, "2")}>
                    <SpecificationLabel data={renderSpesificationData?.emissions} />
                  </Panel>
                } */}
                {
                  spesificationData.equipments &&
                  <Panel header={t("specificationsTab.equipmentsTabName")} key="3" className="ModelsClasses" extra={renderSpesificationData && copyCollapsable(renderSpesificationData.equipments, "3")}>
                    <SpecificationLabel data={renderSpesificationData?.equipments} />
                  </Panel>
                }
                {
                  spesificationData.meservice &&
                  <Panel header={t("specificationsTab.meServicesTabName")} key="4" className="ModelsClasses" extra={renderSpesificationData && copyCollapsable(renderSpesificationData.meservice, "4")}>
                    <SpecificationLabel data={renderSpesificationData?.meservice} />
                  </Panel>
                }
                {
                  spesificationData.simsearch &&
                  <Panel header={t("specificationsTab.simSearchTabName")} key="5" className="ModelsClasses" extra={renderSpesificationData && copyCollapsable(renderSpesificationData.simsearch, "5")}>
                    <Collapse bordered={false}
                      expandIcon={ExpandIconRightOutlined}
                      className="ModelsMenu"
                      {...(panels && setPanels && {
                        activeKey: panels,
                        onChange: setPanels,
                      })}
                      >
                      {spesificationData.simsearch.map((vehicle, index) =>
                      {
                        const key = 5 + index + 1;
                        return <Panel header={index === 0 ? "Search result list" : "Stock vehicle " + vehicle.vehicles[0].identification.commissionNumber} key={key} className="ModelsClasses" extra={vehicle && copyCollapsable(vehicle, key.toString())}>
                          <SpecificationLabel data={vehicle} />
                        </Panel>
                      }
                      )}
                    </Collapse>
                  </Panel>
                }
                {
                  spesificationData.ryc &&
                  <Panel header={t("specificationsTab.rycTabName")} key="6" className="ModelsClasses" extra={renderSpesificationData && copyCollapsable(renderSpesificationData.ryc)}>
                    <SpecificationLabel data={renderSpesificationData?.ryc} />
                  </Panel>
                }
            </JsonWithSearchAndSave>
      </>
      }


      {spesificationData && <Row gutter={20} justify="end" style={{ marginTop: "40px", marginBottom: "120px" }}>
        {isApiKeyData && <Col><CustomButton label={t("loadSaveButtons.saveSpecs")} filledIn={false} onClick={saveSpesification} isImagesLoading={isImagesLoading} style={{ float: "right" }} /></Col>}
      </Row>}
    </div>
  )
}

export default Specification;