export default (obj, fileName) => {
    const fileContent = JSON.stringify(obj, null, 4);
    const blob = new Blob([fileContent], { type: "application/json" });
    const href = URL.createObjectURL(blob)
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}