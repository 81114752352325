import { Wb360Viewer } from '@workbench/react';

function Exterior360View(props) {
  const { images } = props;
  return (
    <Wb360Viewer preloadHighResImages={true} images={
      images.map((image) => ({
        lowRes: {
          src: image,
        },
        highRes: {
          src: image,
        },
      }))
    }/>
  )
}

export default Exterior360View;
