import { Col, Row, Collapse, Input, Slider, Select, notification, Tooltip, Divider } from "antd";
import { useEffect, useState, useRef } from "react";

import CustomButton from './CustomButton';

import ImageInput from "./ImageInput";
import mergeImages from '../Utils/mergeImages';
import { saveAs } from "file-saver";
import CustomInput from './CustomInput';
import _notification from '../Utils/notification';
import useDebouncedEffect from "../Utils/useDebouncedEffect";
import PanelHeader from "./PanelHeader";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { unstable_batchedUpdates } from "react-dom";
import CustomSwitch from "./CustomSwitch";
import saveJson from "../Utils/saveJson";

const { Panel } = Collapse;


const ImageCompose = (props) => {

    const { images, saveFileName, imageType, cropped, loadedBackground, loadedFileFormat, sample = false } = props;

    const [compositionItems, setCompositionItems] = useState({
        background: sample ? null : JSON.parse(localStorage.getItem("background")),
        banner: JSON.parse(localStorage.getItem("banner")),
        logo1: JSON.parse(localStorage.getItem("logo1")),
        logo2: JSON.parse(localStorage.getItem("logo2")),
    });

    const [composedImages, setComposedImages] = useState({});
    const [sliderValue, setSliderValue] = useState(0);
    const [activeImage, setActiveImage] = useState(null);
    const [composing, setComposing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [currentVin, setCurrentVin] = useState(Object.keys(images)[0]);
    const [firstTopPx, setFirstTopPx] = useState(20);
    const [firstLeftPx, setFirstLeftPx] = useState(20);
    const [secondTopPx, setSecondTopPx] = useState(20);
    const [secondRightPx, setSecondRightPx] = useState(20);
    const [logoOneSize, setLogoOneSize] = useState([0, 0]);
    const [logoTwoSize, setLogoTwoSize] = useState([0, 0]);
    const [resizePercentLogo1, setResizePercentLogo1] = useState(100);
    const [resizePercentLogo2, setResizePercentLogo2] = useState(100);
    const [notifyKey, setNotifyKey] = useState();
    const [scaleToFit, setScaleToFit] = useState(false);
    const prevNotifyKey = useRef();

    useEffect(() => prevNotifyKey.current = notifyKey);

    const { t } = useTranslation();

    const fileInputRef = useRef(null);

    const editVehicleImagesDataTable = [
        {
            key: 1,
            type: t("imagesTab.imageCompose.backgroundImage"),
            desc: t("imagesTab.imageCompose.editVehicleImagesDataTable.backgroundImageDesc"),
        },
        {
            key: 2,
            type: t("imagesTab.imageCompose.bannerImage"),
            desc: t("imagesTab.imageCompose.editVehicleImagesDataTable.bannerImageDesc"),
        },
        {
            key: 3,
            type: t("imagesTab.imageCompose.logoUpperLeft"),
            desc: t("imagesTab.imageCompose.editVehicleImagesDataTable.logoUpperLeftDesc"),
        },
        {
            key: 4,
            type: t("imagesTab.imageCompose.logoUpperRight"),
            desc: t("imagesTab.imageCompose.editVehicleImagesDataTable.logoUpperRightDesc"),
        }
    ];

    const openNotification = (title, message, vin) => {
        notification.destroy();
        const config = {
            title: title,
            message: message,
            key: vin,
            duration: 5,
            onClose: () => {
                setNotifyKey(0);
            }
        }
        setNotifyKey(_notification(config));
    }

    const calculateStepSize = () => {
        let exteriorImagesCount = 0;
        images[currentVin].forEach(data => {
            if (data["title"].startsWith("EXT")) {
                exteriorImagesCount++;
            }
        });

        if (exteriorImagesCount > 1) {
            return 350 / (exteriorImagesCount - 1);
        }
        else {
            return 0;
        }

    };


    const [sliderStepSize, setSliderStepSize] = useState(calculateStepSize());

    const getDegreeFromIndex = index => {
        return parseInt(images[currentVin][index]['title'].slice(-3));
    };

    const [currentDegree, setCurrentDegree] = useState(getDegreeFromIndex(0));

    const IMAGE_SIZE = cropped ? [1600, 1200] : [1920, 1080];

    const prevComposedImages = useRef();
    useEffect(() => { prevComposedImages.current = composedImages }, [composedImages]);

    useEffect(() => {
        previewComposedImage(sliderValue);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compositionItems, currentVin, resizePercentLogo1, resizePercentLogo2, scaleToFit])

    useEffect(() => {
        validateLogoPosition()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstLeftPx, firstTopPx, secondRightPx, secondTopPx])

    useDebouncedEffect(() => {
        previewComposedImage(sliderValue);
    }, [firstLeftPx, firstTopPx, secondRightPx, secondTopPx], 500);

    const getPerspectiveFromDegree = degree => {
        const perspective = prevComposedImages.current[currentVin].find(image => {
            return image["title"] === getStringValue(degree);
        });

        return perspective['b64'] || "";
    }

    const getStringValue = value => {
        let stringVal = value + "";
        if (stringVal.length < 2) {
            stringVal = "00" + stringVal;
        }
        else if (stringVal.length < 3) {
            stringVal = "0" + stringVal;
        }
        return 'EXT' + stringVal;
    };

    function dataURItoBlob(dataURI) {
        let byteString = window.atob(dataURI.split(',')[1]);
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        let bb = new Blob([ab]);
        return bb;
    }

    const previewComposedImage = async (sliderValue) => {
        const image = images[currentVin][sliderValue / sliderStepSize];
        const preparedImages = prepareImageForCompose(image);

        const tmpComposedImages = {
            [currentVin]: []
        };

        images[currentVin].forEach(image => {
            tmpComposedImages[currentVin].push({ 'title': image['title'], 'blob': image['blob'], 'b64': image['url'] });
        })

        const composedImageURI = await mergeImages(preparedImages, { width: IMAGE_SIZE[0], height: IMAGE_SIZE[1] });
        const index = tmpComposedImages[currentVin].findIndex(item => item.title === image['title'])
        tmpComposedImages[currentVin][index] = { 'title': image['title'], 'blob': dataURItoBlob(composedImageURI), 'b64': composedImageURI };
        setComposedImages(tmpComposedImages);
        setActiveImage(tmpComposedImages[currentVin][sliderValue / sliderStepSize]['b64']);
        setSliderStepSize(calculateStepSize());
    }

    function getResizePercent(resizePercent) {
        return (!resizePercent || resizePercent > 400 || resizePercent < 25) ? 100 : resizePercent;
    }

    const prepareImageForCompose = (image) => {
        let composingImages = [];

        const tmpResizePercentLogo1 = getResizePercent(resizePercentLogo1);
        const tmpResizePercentLogo2 = getResizePercent(resizePercentLogo2);

        const addBackground = (compositionItems.background != null && !loadedBackground && !sample);
        const addBanner = (compositionItems.banner != null);
        const addLogo1 = (compositionItems.logo1 != null);
        const addLogo2 = (compositionItems.logo2 != null);

        if (addBackground) {
            const background = { src: compositionItems.background.url, x: 0, y: 0 };
            if (!scaleToFit) {
                background.width = IMAGE_SIZE[0];
                background.height = IMAGE_SIZE[1];
            }
            composingImages.push(background);
        }

        if (addBanner) {
            const banner = { src: compositionItems.banner.url, x: 0, y: IMAGE_SIZE[1] - compositionItems.banner.height };
            if (!scaleToFit) {
                banner.width = IMAGE_SIZE[0];
                banner.height = compositionItems.banner.height;
            }
            composingImages.push(banner);
        }

        if (addLogo1) {
            composingImages.push({ src: compositionItems.logo1.url, x: firstLeftPx, y: firstTopPx, width: compositionItems.logo1.width * tmpResizePercentLogo1 * 0.01, height: compositionItems.logo1.height * tmpResizePercentLogo1 * 0.01 });
        }

        if (addLogo2) {
            composingImages.push({ src: compositionItems.logo2.url, x: IMAGE_SIZE[0] - secondRightPx - compositionItems.logo2.width * tmpResizePercentLogo2 * 0.01, y: secondTopPx, width: compositionItems.logo2.width * tmpResizePercentLogo2 * 0.01, height: compositionItems.logo2.height * tmpResizePercentLogo2 * 0.01 });
        }

        const isExtImage = image["title"].startsWith("EXT");
        if (isExtImage) {
            const shouldUnshift = loadedBackground || loadedFileFormat === "jpeg" || sample;
            composingImages[shouldUnshift ? 'unshift' : 'push']({ src: image["url"], x: 0, y: 0 });
        } else {
            composingImages = [{ src: image["url"], x: 0, y: 0 }];
        }

        return composingImages;
    }


    const composeImages = async () => {
        setComposing(true)

        const tmpComposedImages = {
            [currentVin]: []
        };

        for await (const image of images[currentVin]) {
            const preparedImages = prepareImageForCompose(image)
            const composedImageURI = await mergeImages(preparedImages, { width: IMAGE_SIZE[0], height: IMAGE_SIZE[1] });
            tmpComposedImages[currentVin].push({ 'title': image['title'], 'blob': dataURItoBlob(composedImageURI), 'b64': composedImageURI });
        }

        setActiveImage(tmpComposedImages[currentVin][sliderValue / sliderStepSize]['b64']);
        setComposing(false);
        setSliderStepSize(calculateStepSize());
        return tmpComposedImages;
    }

    const sliderOnChange = (e) => {
        const degree = getDegreeFromIndex(e / sliderStepSize);
        setSliderValue(e);
        setActiveImage(getPerspectiveFromDegree(degree));
        setCurrentDegree(degree);
    }

    const onImageSet = (details) => {
        switch (details.name) {
            case 'background':
                setCompositionItems({ ...compositionItems, background: details });
                localStorage.setItem(details.name, JSON.stringify(details));
                break;
            case 'banner':
                setCompositionItems({ ...compositionItems, banner: details });
                localStorage.setItem(details.name, JSON.stringify(details));
                break;
            case 'logo1':
                setCompositionItems({ ...compositionItems, logo1: details });
                setLogoOneSize([details.width, details.height]);
                localStorage.setItem(details.name, JSON.stringify(details));
                break;
            case 'logo2':
                setCompositionItems({ ...compositionItems, logo2: details });
                setLogoTwoSize([details.width, details.height]);
                localStorage.setItem(details.name, JSON.stringify(details));
                break;
            default:
                break;
        }
    }

    const onImageDelete = (name) => {
        switch (name) {
            case 'background':
                setCompositionItems({ ...compositionItems, background: null })
                localStorage.removeItem("background");
                break;
            case 'banner':
                setCompositionItems({ ...compositionItems, banner: null });
                localStorage.removeItem("banner");
                break;
            case 'logo1':
                setCompositionItems({ ...compositionItems, logo1: null });
                localStorage.removeItem("logo1");
                break;
            case 'logo2':
                setCompositionItems({ ...compositionItems, logo2: null });
                localStorage.removeItem("logo2");
                break;
            default:
                break;
        }
    }

    const saveComposedImages = async () => {
        setSaving(true);
        const composedImages = await composeImages();
        const zip = require("jszip")();
        let imageFolder = zip.folder(saveFileName);

        Object.keys(composedImages).forEach(vin => {
            composedImages[vin].forEach(image => {
                imageFolder.file(`${vin}/${image["title"]}.${imageType}`, image["blob"], { createFolders: true });
            });
        });

        zip.generateAsync({ type: "blob" }).then(content => {
            saveAs(content, saveFileName);
            setSaving(false);
        }).catch(err => {
            console.log(err);
            setSaving(false);
        })
    }

    const validateSettings = obj => {
        let validationSchema = {
            "logo1": Joi.object({
                "name": Joi.string().required(),
                "url": Joi.string().required(),
                "width": Joi.number().integer().required(),
                "height": Joi.number().integer().required(),
                "top": Joi.number().integer().required(),
                "left": Joi.number().integer().required(),
                "percentage": Joi.number().integer().required()
            }),
            "logo2": Joi.object({
                "name": Joi.string().required(),
                "url": Joi.string().required(),
                "width": Joi.number().integer().required(),
                "height": Joi.number().integer().required(),
                "top": Joi.number().integer().required(),
                "right": Joi.number().integer().required(),
                "percentage": Joi.number().integer().required()
            }),
            "banner": Joi.object({
                "name": Joi.string().required(),
                "url": Joi.string().required(),
                "width": Joi.number().integer().required(),
                "height": Joi.number().integer().required()
            }),
            "background": Joi.object({
                "name": Joi.string().required(),
                "url": Joi.string().required(),
                "width": Joi.number().integer().required(),
                "height": Joi.number().integer().required()
            })
        }

        const schema = Joi.object(validationSchema).options({ abortEarly: false })

        let value = schema.validate(obj);

        if (value.error) {
            _notification({ title: t("notification.info"), message: t("notification.error"), duration: 10 });
            return false;
        } else {
            _notification({ title: t("notification.info"), message: t("notification.fullyLoad"), duration: 10 });
            return true;
        }
    }

    const handleLoadContent = (event) => {
        let file = event.target.files[0];
        if (!file) {
            event.target.value = null;
            return;
        }
        const fileReader = new FileReader();
        fileReader.readAsText(file, "UTF-8");
        fileReader.onload = e => {
            const result = e.target?.result;
            try {
                const resultJson = JSON.parse(result);
                const isValid = validateSettings(resultJson);

                if (!isValid) {
                    event.target.value = null;
                    return;
                }

                unstable_batchedUpdates(() => {
                    const nullItems = Object.fromEntries(
                        Object.keys(compositionItems).map((key) => [key, null])
                    );

                    Object.entries(resultJson).forEach(([key, value]) => {
                        localStorage.setItem(key, JSON.stringify(value));

                        switch (key) {
                            case "logo1":
                                setFirstLeftPx(value.left);
                                setFirstTopPx(value.top);
                                setResizePercentLogo1(value.percentage);
                                break;
                            case "logo2":
                                setSecondRightPx(value.right);
                                setSecondTopPx(value.top);
                                setResizePercentLogo2(value.percentage);
                                break;
                            default:
                                break;
                        }
                    });

                    setCompositionItems({ ...nullItems, ...resultJson });
                });
            } catch (e) {
                _notification({ title: "Error", message: `${t("notification.error")} ${e}`, duration: 10 });
            }
        };

        event.target.value = null;
    }

    const handleSaveSettings = async () => {
        const fileName = "mb-compose-settings";
        let composeSettings = {}

        if (compositionItems.logo1) {
            composeSettings["logo1"] = {
                ...compositionItems.logo1,
                "top": firstTopPx || "0",
                "left": firstLeftPx || "0",
                "percentage": resizePercentLogo1 || 100
            };
        }
        if (compositionItems.logo2) {
            composeSettings["logo2"] = {
                ...compositionItems.logo2,
                "top": secondTopPx || "0",
                "right": secondRightPx || "0",
                "percentage": resizePercentLogo2 || 100
            };
        }
        if (compositionItems.banner) {
            composeSettings["banner"] = compositionItems.banner;
        }
        if (compositionItems.background) {
            composeSettings["background"] = compositionItems.background;
        }

        if (Object.keys(composeSettings).length === 0) {
            _notification({ title: t("notification.information"), message: t("notification.nothingToSave"), duration: 10 });
            return
        }

        // Data Post-Processing
        // Data Post-Processing
        saveJson(composeSettings, fileName)
    }

    const handleResetSettings = () => {
        clearSettings();
    }

    const clearSettings = () => {
        setResizePercentLogo1(100);
        setResizePercentLogo2(100);
        setFirstLeftPx(20);
        setFirstTopPx(20);
        setSecondRightPx(20);
        setSecondTopPx(20);
        setCompositionItems(Object.fromEntries(
            Object.keys(compositionItems).map((key) => [key, null])
        ));
        localStorage.removeItem("background");
        localStorage.removeItem("banner");
        localStorage.removeItem("logo1");
        localStorage.removeItem("logo2");
    }

    const validateLogoPosition = () => {
        if (((firstTopPx > 100) && (logoOneSize[1] < IMAGE_SIZE[1])) || firstTopPx < 0 || isNaN(firstTopPx)) {
            setFirstTopPx(20);
            openNotification(t("imagesTab.imageCompose.rangeErrorTitle"), t("imagesTab.imageCompose.notification.topFirst"));
        }
        if (((secondTopPx > 100) && (logoTwoSize[1] < IMAGE_SIZE[1])) || secondTopPx < 0 || isNaN(secondTopPx)) {
            setSecondTopPx(20);
            openNotification(t("imagesTab.imageCompose.rangeErrorTitle"), t("imagesTab.imageCompose.notification.topSecond"));
        }
        if (((firstLeftPx > 100) && (logoOneSize[0] < IMAGE_SIZE[0])) || firstLeftPx < 0 || isNaN(firstLeftPx)) {
            setFirstLeftPx(20);
            openNotification(t("imagesTab.imageCompose.rangeErrorTitle"), t("imagesTab.imageCompose.notification.leftFirst"));
        }
        if (((secondRightPx > 100) && (logoTwoSize[0] < IMAGE_SIZE[0])) || secondRightPx < 0 || isNaN(secondRightPx)) {
            setSecondRightPx(20);
            openNotification(t("imagesTab.imageCompose.rangeErrorTitle"), t("imagesTab.imageCompose.notification.rightSecond"));
        }
    }

    return (
        <div className="collapse">
            <Collapse bordered={true}
                expandIconPosition="start"
                className="editVehicleImagesMain"
                ghost={false}
            >
                <Panel header={<PanelHeader headerName={t("imagesTab.imageCompose.editVehicleImages")} data={editVehicleImagesDataTable} description={t("imagesTab.imageCompose.editVehicleImagesDataTable.dataTableDesc")} />}>
                    <Row gutter={[64, {xs:8, sm:12, lg:32}]} className="editVehicleImagesSettings">
                        {/* background upload */}
                        <Col xs={24} lg={12}>
                            <Row gutter={[16, {xs: 8, sm:12, lg: 24}]}>
                                <Col xs={24} lg={6} style={{display: "flex", alignItems: "center"}}>
                                    <Row>
                                        <Col span={24}>
                                            <div className="CustomSwitchLabel" >
                                                {t("imagesTab.imageCompose.backgroundImage")}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} lg={18}>
                                    <Row className="CustomImageInput CustomImageInput--big">
                                        <Col span={24}>
                                            <ImageInput oldImageUrl={compositionItems.background?.url} disabled={loadedBackground || loadedFileFormat === "jpeg" || sample} onImageSet={onImageSet} onImageDelete={onImageDelete} name="background" imageSize={IMAGE_SIZE} fileSize={10} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        {/* banner upload */}
                        <Col xs={24} lg={12}>
                            <Row gutter={[16, {xs: 8, sm: 12, lg: 24}]}>
                                <Col xs={24} lg={6} style={{display: "flex", alignItems: "center"}}>
                                    <Row>
                                        <Col span={24}>
                                            <div className="CustomSwitchLabel">
                                                {t("imagesTab.imageCompose.bannerImage")}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} lg={18}>
                                    <Row>
                                        <Col span={24} className="CustomImageInput CustomImageInput--big">
                                            <ImageInput oldImageUrl={compositionItems.banner?.url} onImageSet={onImageSet} onImageDelete={onImageDelete} name="banner" imageSize={IMAGE_SIZE} fileSize={10} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        {/* left logo upload */}
                        <Col xs={24} lg={12}>
                            <Row gutter={[16, {xs: 8, sm:12, lg: 24}]}>
                                <Col xs={24} lg={6} style={{display: "flex"}}>
                                    <Row>
                                        <Col span={24}>
                                            <div className="CustomSwitchLabel">
                                                {t("imagesTab.imageCompose.logoUpperLeft")}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} lg={18}>
                                    <Row gutter={[10, 10]}>
                                        <Col span={24} className="CustomImageInput">
                                            <ImageInput oldImageUrl={compositionItems.logo1?.url} onImageSet={onImageSet} onImageDelete={onImageDelete} name="logo1" imageSize={IMAGE_SIZE} fileSize={10} />
                                        </Col>
                                        <Tooltip title={t("imagesTab.imageCompose.tooltipOnPosition")}>
                                            <Col className="MarginPxDistanceBox" xs={24} sm={8}>
                                                <CustomInput className="CustomInputLabel-small" setId="1Top" setType="number" label={t("imagesTab.imageCompose.logoTop")} getValue={setFirstTopPx} disabled={compositionItems.logo1 == null} val={firstTopPx}></CustomInput>
                                            </Col>
                                        </Tooltip>
                                        <Tooltip title={t("imagesTab.imageCompose.tooltipOnPosition")}>
                                            <Col className="MarginPxDistanceBox" xs={24} sm={8}>
                                                <CustomInput className="CustomInputLabel-small" setId="1Left" setType="number" label={t("imagesTab.imageCompose.logoLeft")} getValue={setFirstLeftPx} disabled={compositionItems.logo1 == null} val={firstLeftPx}></CustomInput>
                                            </Col>
                                        </Tooltip>
                                        <Tooltip title={t("imagesTab.imageCompose.tooltipOnResize")}>
                                            <Col className="MarginPxDistanceBox" xs={24} sm={8}>
                                                <CustomInput className="CustomInputLabel-small" setId="LogoLeftResize" setType="number" label={t("imagesTab.imageCompose.logoResize")} getValue={setResizePercentLogo1} disabled={compositionItems.logo1 == null} val={resizePercentLogo1}></CustomInput>
                                            </Col>
                                        </Tooltip>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        {/* right logo upload */}
                        <Col xs={24} lg={12}>
                            <Row gutter={[16, {xs: 8, sm: 12, lg: 24}]}>
                                <Col xs={24} lg={6} style={{display: "flex"}}>
                                    <Row>
                                        <Col span={24}>
                                            <div className="CustomSwitchLabel">
                                                {t("imagesTab.imageCompose.logoUpperRight")}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} lg={18}>
                                    <Row gutter={[10, 10]}>
                                        <Col span={24} className="CustomImageInput">
                                            <ImageInput oldImageUrl={compositionItems.logo2?.url} onImageSet={onImageSet} onImageDelete={onImageDelete} name="logo2" imageSize={IMAGE_SIZE} fileSize={10} />
                                        </Col>
                                        <Tooltip title={t("imagesTab.imageCompose.tooltipOnPosition")}>
                                            <Col className="MarginPxDistanceBox"  xs={24} sm={8}>
                                                <CustomInput className="CustomInputLabel-small" setId="2Top" setType="number" label={t("imagesTab.imageCompose.logoTop")} getValue={setSecondTopPx} disabled={compositionItems.logo2 == null} val={secondTopPx}>                                                        </CustomInput>
                                            </Col>
                                        </Tooltip>
                                        <Tooltip title={t("imagesTab.imageCompose.tooltipOnPosition")}>
                                            <Col className="MarginPxDistanceBox" xs={24} sm={8}>
                                                <CustomInput className="CustomInputLabel-small" setId="2Right" setType="number" label={t("imagesTab.imageCompose.logoRight")} getValue={setSecondRightPx} disabled={compositionItems.logo2 == null} val={secondRightPx}></CustomInput>
                                            </Col>
                                        </Tooltip>
                                        <Tooltip title={t("imagesTab.imageCompose.tooltipOnResize")}>
                                            <Col className="MarginPxDistanceBox" xs={24} sm={8}>
                                                <CustomInput className="CustomInputLabel-small" setId="LogoRightResize" setType="number" label={t("imagesTab.imageCompose.logoResize")} getValue={setResizePercentLogo2} disabled={compositionItems.logo2 == null} val={resizePercentLogo2}></CustomInput>
                                            </Col>
                                        </Tooltip>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        {/* scale to fit switch */}
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Row align="middle">
                                <Col xs={12} md={6} sm={12}>
                                    <Row>
                                        <span className="CustomSwitchLabel" data-testid = "label">{t("imagesTab.imageCompose.scaleToFit")}</span>
                                    </Row>
                                </Col>
                                <Col xs={12} md={18} sm={12}>
                                    <Row justify={{sm:"end", lg: "start"}}>
                                        <CustomSwitch className="editVehicleImagesMain__CustomSwitch" onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setScaleToFit} defaultValue={scaleToFit} />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        {/* save&load&reset buttons */}
                        <Col xs={24} md={24} lg={24} style={{marginBottom: "12px", marginTop:"12px"}}>
                            <Row justify={{xs: "center", sm:"center", md: "end", lg: "end"}}>
                                <Col md={12} lg={11} xxl={9}>
                                    <Row gutter={[16, 12]} justify={{xs: "center", sm:"center", lg: "end"}}>
                                        <Col xs={24} md={24} lg={8} ><CustomButton  disabled={!(compositionItems.background || compositionItems.banner || compositionItems.logo1 || compositionItems.logo2)} label={t("loadSaveButtons.saveSettings")} filledIn={false} onClick={handleSaveSettings} /></Col>
                                        <Col xs={24} md={24} lg={8}><CustomButton  label={t("loadSaveButtons.loadSettings")} filledIn={false} onClick={() => fileInputRef.current?.click()} /></Col>
                                        <Col xs={24} md={24} lg={8}><CustomButton  label={t("loadSaveButtons.resetSettings")} filledIn={false} onClick={handleResetSettings} /></Col>
                                        {/* HIDDEN INPUT */}
                                        <input type="file" onChange={handleLoadContent} ref={fileInputRef} name="" id="uploadSettings" hidden></input>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={[64, {xs:8, sm:12, md: 32}]} className="editVehicleImagesPreview" align="middle">
                        {/* select vin */}
                        <Col xs={24} md={12} lg={12}>
                            <Row gutter={[16, {xs: 8, lg: 24}]} justify={{md: "space-between", lg: "start"}} align="middle">
                                <Col xs={24} sm={6}>
                                    <Row>
                                        <span className="CustomSwitchLabel">{t("imagesTab.imageCompose.selectVIN")}</span> 
                                    </Row>
                                </Col>
                                <Col xs={24} sm={18} className="editVehicleImagesMain__CustomSelect">
                                    <Row justify={{sm:"end", lg: "start"}}>
                                        <Select
                                            size={"large"}
                                            onChange={value => {
                                                setCurrentVin(value);
                                            }}
                                            value={currentVin}
                                            defaultValue={currentVin}
                                            listHeight={192}
                                            disabled={composing}
                                        >
                                            {
                                                Object.keys(images).filter(vin => images[vin]
                                                    .some(img => 'title' in img && img['title'].startsWith('EXT')))
                                                    .map(vin => {
                                                        return <Select.Option key={vin} value={vin}> {vin} </Select.Option>
                                                    })
                                            }
                                        </Select>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        {/* compose button */}
                        <Col xs={24} md={12}>
                            <Row>
                                <Col span={24}>
                                    <CustomButton disabled={!(compositionItems.background || compositionItems.logo1 || compositionItems.logo2 || compositionItems.banner) || saving || sample} label={t("loadSaveButtons.saveComposedImage")} onClick={saveComposedImages} filledIn={false} style={{ float: "right" }} />
                                </Col>
                            </Row>
                        </Col>
                        {/* image preview */}
                        <Col className="compositionPreviewHolder" span={24}>
                            <div className="compositionPreviewPanel">
                                <div className="CustomInput">
                                    {<div className="CustomInputLabel">{t("imagesTab.imageCompose.preview")}</div>}
                                    {(<Input className="CustomInputValue" value={`${currentDegree}°`} bordered={false} disabled={true} />)}
                                </div>
                                <img className="composePreviewImage" src={activeImage} alt=""></img>
                                <div className="composePreviewSlider">
                                    <Slider onAfterChange={previewComposedImage} onChange={sliderOnChange} value={sliderValue} min={0} max={350} step={sliderStepSize} tipFormatter={() => currentDegree} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </div>
    );
}


export default ImageCompose;