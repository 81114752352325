import { useEffect, useRef, useState } from 'react'
import { Row, Col, Card, Collapse } from 'antd'

import { _getVehicleImages, _getImage, _getAvailableBackgrounds } from '../Utils/apiCalls'
import _notification from '../Utils/notification'
import _handleErrors from '../Utils/handleErrors'

import ImageList from './ImageList'
import CustomInput from './CustomInput'
import CustomSwitch from './CustomSwitch'
import CustomButton from './CustomButton'
import { loadExampleImages } from "./ExampleImages"
import ExampleImageList from './ExampleImageList'
import CustomSelect from './CustomSelect'
import PanelHeader from './PanelHeader'
import { useTranslation } from 'react-i18next'
import ImageProvider, { useImageProviderContext } from '../Providers/ImageProvider'
import { convertImage } from '../Utils/convertImage'
import { getFromCache } from '../Utils/getFromCache'

const { Panel } = Collapse;

const fileFormatOptions = (background) => {
  const options = [{
    name: "PNG",
    value: "png"
  },
  {
    name: "WEBP",
    value: "webp"
  }];
  if (background) {
    options.push(
      {
        name: "JPEG",
        value: "jpeg"
      }
    )
  }
  return options;
}

const ImagesWithProvider = (props)=>{
  return (
      <ImageProvider>
      <Images {...props} />
    </ImageProvider>
  )
}

function Images(props) {

  const { rowCount, width, setVideoImageList, background, setBackground, setApikeyEntered, apikeyRef, tabNumber } = props;
  const [vin, setVin] = useState(null);
  const [night, setNight] = useState(false);       
  const [roofOpen, setRoofOpen] = useState(false);
  const [cropped, setCropped] = useState(false);
  const [uniformImage, setUniformImage] = useState("standard");
  const [isImagesLoading, setIsImagesLoading] = useState(false);
  const [notifyKey, setNotifyKey] = useState();
  const [fileFormat, setFileFormat] = useState("png");
  const [cropPosition, setCropPosition] = useState("both");
  const [noFallback, setNoFallback] = useState(false);
  const [examples, setExamples] = useState(false);
  const [bulk, setBulk] = useState(false);
  const [saveFileName, setSaveFileName] = useState("");
  // File format of the latest downloaded VIN.
  const [loadedFileFormat, setLoadedFileFormat] = useState("");
  const { t, i18n } = useTranslation();
  // Is background active for latest downloaded VIN ?
  const [loadedBackground, setLoadedBackground] = useState(false);
  const [backgroundSelection, setBackgroundSelection] = useState(false);
  const [availableBackgrounds, setAvailableBackgrounds] = useState([]);
  const [selectedBackground, setSelectedBackground] = useState("");
  const [defaultBackground, setDefaultBackground] = useState("");
  const [lastBackgroundVin, setLastBackgroundVin] = useState("");

  const useImageProvider = useImageProviderContext()

  const prevNotifyKey = useRef();
  const prevLoadedVins = useRef();

  useEffect(() => prevNotifyKey.current = notifyKey);
  useEffect(() => prevLoadedVins.current = useImageProvider.imageList);
  useEffect(() => {
    if(useImageProvider.apikey){
      apikeyRef.current[tabNumber-1] = 1
      setApikeyEntered((prev => {
        let newApikeyEntered = [...prev];
        newApikeyEntered[tabNumber - 1] = 1;
        return newApikeyEntered;
      }))
    } else {
      apikeyRef.current[tabNumber-1] = 0
      setApikeyEntered((prev) => {
        let newApikeyEntered = [...prev];
        newApikeyEntered[tabNumber - 1] = 0;
        return newApikeyEntered;
      });
    }
  }, [useImageProvider.apikey])

  // Data for the tables
  const imageSettingsDataTable = [
    {
      key: 1,
      type: t("components.light"),
      desc: t("imagesTab.infoTable.lightDesc"),
    }, {
      key: 2,
      type: t("components.roof"),
      desc: t("imagesTab.infoTable.roofDesc"),
    }, {
      key: 3,
      type: t("components.background"),
      desc: t("imagesTab.infoTable.backgroundDesc"),
    }, {
      key: 4,
      type: t("components.backgroundScene"),
      desc: t("imagesTab.infoTable.backgroundSelectDesc"),
    }, {
      key: 5,
      type: t("components.fileFormat"),
      desc: t("imagesTab.infoTable.fileFormatDesc"),
    }, {
      key: 6,
      type: t("components.imageFormat"),
      desc: t("imagesTab.infoTable.imageFormatDesc"),
    }, {
      key: 7,
      type: t("components.cropPosition"),
      desc: t("imagesTab.infoTable.cropPositionDesc"),
    },
    {
      key: 8,
      type: t("components.vehiclePosition"),
      desc: t("imagesTab.infoTable.vehiclePositionDesc"),
    },
    {
      key: 9,
      type: t("components.fallback"),
      desc: t("imagesTab.infoTable.fallbackDesc"),
    }
  ]

  const cropPositionOptions = [
    {
      name: t("imagesTab.crop.both"),
      value: "both"
    },
    {
      name: t("imagesTab.crop.top"),
      value: "top",
    },
    {
      name: t("imagesTab.crop.bottom"),
      value: "bottom"
    }
  ]

  const positionList = [
    {
      value: "standard",
      name: t("imagesTab.position.standard")
    },
    {
      value: "stage",
      name: t("imagesTab.position.centerStage")
    },
    {
      value: "center",
      name: t("imagesTab.position.center")
    },
    {
      value: "wheel",
      name: t("imagesTab.position.frontWheel")
    },
    {
      value: "middle",
      name: t("imagesTab.position.middle")
    }
  ]

  const vinValidationNotification = (vins) => {
    const length = vins.length;
    if(length > 5) {
      openNotification(t("notification.warning"), t("notification.invalidVINCount"));
    } else if(length === 1 ) {
      openNotification(t("notification.warning"), t("notification.invalidVINDesc"));
    }
  }

  const shouldIncludeBackgroundItemQueryParam = (vinsLength) => background && backgroundSelection && vinsLength === 1
        && defaultBackground !== selectedBackground
        && (selectedBackground.length === 3 || selectedBackground.length === 2)
        && selectedBackground.startsWith("T")
        && lastBackgroundVin === vin

  const getVehicleImages = async () => {
    setExamples(false);
    
    if (!useImageProvider.apikey || !vin) {
      console.log("empty apikey and/or vin");
      return;
    }

    const vins = cleanAndSplitVINs(vin)
    const {isValid, isBulk} = vinCheck(vins)
    
    if (!isValid){
      vinValidationNotification(vins);
      return;
    } 

    const fileName = generateFileNameByVin(vins);

    setSaveFileName(fileName)
    setBulk(isBulk)

    //if vin reloaded and, invalid background given.
    if (vin !== lastBackgroundVin && selectedBackground.startsWith("T")) {
      setAvailableBackgrounds([]);
      setSelectedBackground(t('imagesTab.default'));
      setDefaultBackground("");
      setLastBackgroundVin("");
      openNotification(t("notification.warning"), t("notification.invalidBackgroundErrorMessage"));
      return;
    }

    useImageProvider.setImageList({})

    //Remove old data
    for (let key in prevLoadedVins.current) {
      delete prevLoadedVins.current[key];
    }
    setVideoImageList([]);


    let isVideoImageListSet = false;

    // Traverse over vins
    for (const vinElement of vins) {

      //Skip if the VIN used before, to avoid duplicate VINs
      if (typeof prevLoadedVins.current === 'object' && vinElement in prevLoadedVins.current)
        continue;

      setLoadedBackground(background);
      setLoadedFileFormat(fileFormat);

      const params = { apikey:useImageProvider.apikey, queries: { night, roofOpen, background, fileFormat, cropped, cropPosition, fallback: !noFallback }};

      if (shouldIncludeBackgroundItemQueryParam(vins.length)) {
        params.queries["backgroundItem"] = selectedBackground;
      }

      await getVehicleImagesByVIN(vinElement, params);

      // Set first eligible list as video image list.
      if (!isVideoImageListSet && prevLoadedVins.current[vinElement] && !("errorMessage" in prevLoadedVins.current[vinElement][0])) {
        setVideoImageList(prevLoadedVins.current[vinElement]);
        isVideoImageListSet = true;
      }
    }

  }

  const getImage = (imageId) => {
    const params = { apikey:useImageProvider.apikey, imageId };
    return _getImage(params).then(resp => {
      if (resp.data) {
        return resp.data;
      }
    })
  }

  const loadImages = async (newImageList, vinElement) => {
    // filter out empty imageIds
    newImageList = newImageList.filter(image => image.imageId !== "");
    
    const loadedImages = await Promise.all(newImageList.map(async (nextImage) => {
      const { imageId, title } = nextImage;
      try {
        const imageData = await getImage(imageId);
        const image = convertImage(imageData);
        console.log(title + ": is loaded");
        return { imageId, title, ...image };
      } catch (err) {
        _notification({ title: t("notification.error"), message: t("notification.failedImage") + vinElement + " - " + title });
        console.log(err);
        return { imageId, title, url: "", blob: null };
      }
    }));
    // sort loadedImages to be in the same order as newImageList
    loadedImages.sort((a, b) => newImageList.findIndex(e => e.imageId === a.imageId) - newImageList.findIndex(e => e.imageId === b.imageId));

    // set the imageId to a random uuid
    loadedImages.forEach((image) => {
      image.imageId = Math.random().toString(36);
    });

    useImageProvider.setImageList((prev) => ({ ...prev, [vinElement]: loadedImages.filter(Boolean) }));
  }

  const openNotification = (title, message, vin) => {
    const config = {
      title: title,
      message: message,
      key: vin,
      onClose: () => {
        setNotifyKey(undefined);
      }
    }
    setNotifyKey(_notification(config));
  }

  const getExampleImages = async() => {
    const exampleImages = await loadExampleImages();
    useImageProvider.setImageList(exampleImages);
    setExamples(true);
  }

  const shouldIncludeUniformImageQueryParam = (!background && uniformImage !== "standard");

  const getVehicleImagesByVIN = async (vinElement, params) => {

    if (vinElement.length !== 17) {
      const err = new Error();
      err.response = { status: 400 };
      openNotification(t("notification.warning"), t("notification.invalidVINLongDesc"));
      return;
    } 
    
    params["vin"] = vinElement;

    if(shouldIncludeUniformImageQueryParam) {
      params.queries.uniformImage = uniformImage;
    }

    let vehicleImages;
    setIsImagesLoading(true);

    useImageProvider.setImageList((prev)=> ({...prev, [vinElement]: [ ] }))
    openNotification(t("notification.loading"), "Getting images for: " + vinElement, vinElement);
    const cachedFIN = getFromCache(params);
    if (cachedFIN) {
      vehicleImages = Promise.resolve(cachedFIN.imageList);
    } else {
      vehicleImages = _getVehicleImages(params).then(resp => {
        if (resp.data) {
          const imageList = Object.keys(resp.data).map(key => ({ title: key, imageId: resp.data[key] }));
          const cacheData = {
            vin: vin,
            imageList: imageList,
            expiry: new Date().getTime() + 7200000,
            night: night,
            fallback: !noFallback,
            roofOpen: roofOpen,
            background: background,
            uniformImage: uniformImage,
            fileFormat: fileFormat,
            cropPosition: cropPosition,
            cropped: cropped,
            apikey: useImageProvider.apikey,
            backgroundItem: params.queries.backgroundItem
          }
          let storedImages = JSON.parse(localStorage.getItem("images"));

          if (storedImages.length >= 5) {
            storedImages.shift();
          }
          storedImages.push(cacheData);
          localStorage.setItem("images", JSON.stringify(storedImages));

          return imageList;
        }
      }
      );
    }

    await vehicleImages.then(async newImageList => {
      console.log("Images started to Load");
      await loadImages(newImageList, vinElement);
      console.log("Images finished loading");
      setIsImagesLoading(false);
      openNotification(t("notification.finished"), t("notification.loadedImages") + vinElement, vinElement);
    }).catch(err => {
      // Code maynot reach below. Error exception handled in loadImages.
      const errorMessage = _handleErrors(err, vinElement);
      useImageProvider.setImageList((prev)=> ({...prev, [vinElement]: [...prev?.[vinElement] || [], { "errorMessage": errorMessage }] }))
      console.log(errorMessage);
      setIsImagesLoading(false);
      openNotification(t("notification.error"), errorMessage);
    });
  }

  function getBackgroundList(bg, defaultBackground, response, t, i18n) {
    let backgroundList = [];
    if (bg !== defaultBackground && bg !== "T9" && bg !== "T99") {
      let description = "";
      if (i18n.exists('imagesTab.backgrounds.' + bg)) {
        description = t('imagesTab.backgrounds.' + bg);
      } else {
        description = response.data.backgrounds.available[bg].description;
      }
      backgroundList.push({
        name: description,
        value: bg
      });
    }
    return backgroundList;
  }


  const loadBackgrounds = async () => {
    if (useImageProvider.apikey && vin && !vin.includes(",")) {
      const params = { vin, apikey :useImageProvider.apikey};
      const response = await _getAvailableBackgrounds(params);
      if (response.status === 200) {
        let backgroundList = [];

        const defaultBackground = Object.keys(response.data.backgrounds.default)[0];
        setDefaultBackground(defaultBackground);
        let description = i18n.exists('imagesTab.backgrounds.' + defaultBackground) ? t('imagesTab.backgrounds.' + defaultBackground) : response.data.backgrounds.default.description;
        backgroundList.push({
          name: description + " (" + t("imagesTab.default") + ")",
          value: defaultBackground
        });

        Object.keys(response.data.backgrounds.available).forEach(bg => {
          getBackgroundList(bg, defaultBackground, response, t, i18n).forEach(item => {
            backgroundList.push(item);
          });
        });

        setSelectedBackground(defaultBackground);
        setAvailableBackgrounds(backgroundList);
        setLastBackgroundVin(vin);
      }
      else {
        setSelectedBackground(t('imagesTab.default'));
        setAvailableBackgrounds([]);
      }
      setBackgroundSelection(true);
    }
  };

  const backgroundOnChange = (e) => {
    setBackground(e);
    setAvailableBackgrounds([]);
    setSelectedBackground(t('imagesTab.default'));
    setDefaultBackground("");
    setLastBackgroundVin("");
    if(fileFormat === "jpeg") {
      setFileFormat("png");
    }

    openNotification(t("components.background"), `${t("notification.switched")} ${e ? t("notification.backgroundWith2") : t("notification.backgroundWithout2")}`, vin);

  };

  return (
    <div className="ImagesTab imagePanel">
      <Card className="PackagePanelInfo" bordered={false} >
        <Row>
          <Col className="DownloadModels">
            {t("imagesTab.downloadText")}
          </Col>
        </Row>
        <div className="InputPackage">
          <Row gutter={width < 710 ? 0 : 20} style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "64px", marginTop: "-7px" }}>
            <Col flex={width < 758 ? "auto" : "2"} ><CustomInput label={t("placeholders.apiKeyPlaceholder")} getValue={useImageProvider.setApikey} password={true} /></Col>
            <Col flex={width < 758 ? "auto" : "2"}><CustomInput label={t("placeholders.vinNumberPlaceholder")} getValue={setVin} message={t("imagesTab.vin")} /></Col>
          </Row>

          <Collapse
            ghost={false}
            className="ImageSettingsCollapse"
            expandIconPosition="start"
            key="ImageSettings"
          >
            <Panel key={1} header={<PanelHeader headerName={t("imagesTab.imagesSettings")} description={t("imagesTab.imagesSettingsDesc")} data={imageSettingsDataTable} width={width} />} className="ImagePanelSettings">
              <Row className="ImageSwitchGroup" gutter={[48, 10]} >
                <Col><CustomSwitch label={t("components.light")} onBtn={t("components.dayLight")} offBtn={t("components.nightLight")} status={[t("components.dayLight"), t("components.nightLight")]} getValue={setNight} /></Col>
                <Col><CustomSwitch label={t("components.roof")} onBtn={t("components.roofClosed")} offBtn={t("components.roofOpen")} status={[t("components.roofClosed"), t("components.roofOpen")]} getValue={setRoofOpen} /></Col>
                <Col><CustomSwitch label={t("components.background")} onBtn={t("components.withoutBackground")} offBtn={t("components.withBackground")} status={[t("notification.backgroundWithout1"), t("notification.backgroundWith1")]} getValue={backgroundOnChange} /></Col>
                <Col><CustomSelect label={t("components.backgroundScene")} value={selectedBackground} setValue={setSelectedBackground} options={availableBackgrounds} disabled={!background || !backgroundSelection} backgroundSelectMessage={true} adjustableWith /></Col>
                <Col><CustomSelect label={t("components.fileFormat")} value={fileFormat} setValue={setFileFormat} options={fileFormatOptions(background)} adjustableWith /></Col>
                <Col><CustomSwitch label={t("components.imageFormat")} onBtn="16:9" offBtn="4:3" status={[t("notification.imageFormat16_9"), t("notification.imageFormat4_3")]} getValue={setCropped}  /></Col>
                <Col><CustomSelect label={`4:3 ${t("components.cropPosition43")}`} value={cropPosition} setValue={setCropPosition} options={cropPositionOptions} disabled={!cropped} adjustableWith /></Col>
                <Col><CustomSelect label={t("components.vehiclePosition")} span={5} xs={22} value={uniformImage} setValue={setUniformImage} options={positionList} disabled={background} defaultValue={"standard"} adjustableWith={true} /></Col>
                <Col><CustomSwitch label={t("components.fallback")} onBtn={t("components.fallbackOn")} offBtn={t("components.fallbackOff")} status={[t("components.fallbackOn"), t("components.fallbackOff")]} getValue={setNoFallback} defaultValue={noFallback} /></Col>

              </Row>
            </Panel>
          </Collapse>
        </div>
        <div className="ButtonPackage">
          <Row gutter={[16, 16]} justify="end">
            <Col><CustomButton label={t("loadSaveButtons.loadBackgrounds")} onClick={loadBackgrounds} disabled={!background} /></Col>
            <Col><CustomButton label={t("loadSaveButtons.loadSampleImages")} onClick={getExampleImages} disabled={isImagesLoading} /></Col>
            <Col><CustomButton label={t("loadSaveButtons.loadImages")} onClick={getVehicleImages} loading={isImagesLoading} disabled={!useImageProvider.apikey || !vin} /></Col>
          </Row>
        </div>
      </Card>
      {!examples && Object.keys( useImageProvider.imageList).length > 0 && <ImageList bulk={bulk} imageList={  useImageProvider.imageList} saveFileName={saveFileName} isImagesLoading={isImagesLoading} imageType={fileFormat} rowCount={rowCount} background={background} cropped={cropped} loadedBackground={loadedBackground} loadedFileFormat={loadedFileFormat} />}
      {examples && <ExampleImageList imageList={ useImageProvider.imageList } rowCount={rowCount} />}

    </div>
  )
}

const generateFileNameByVin = (vins) => {
  if(vins.length > 1) {
    return "BULK_VINS_" + vins.length
  } else if(vins.length === 1) {
    return vins[0]
  }
}

const vinCheck = (vins) => {
  const isValid = vins.length <= 5 && vins.length !== 0
  const isBulk = vins.length > 1
  return {isValid, isBulk}
}

const cleanAndSplitVINs = (vin) => {
  //Remove whitespaces
  const cleanVin = vin.replace(/ +/g, '');
  // Split vin text element with comma and take first five of them.
  let vins = cleanVin.split(",").filter(e => e);
  return vins;
}

export default ImagesWithProvider;
