import { Table } from "antd";
import { useTranslation } from "react-i18next";

function InfoPanelsTable(props) {
  const { data, descText, width } = props;

  const { t } = useTranslation();

  const twoColumns = [
    {
      title: t("placeholders.parameter"),
      dataIndex: "type",
      key: "type",
      width: 100,
    },
    {
      title: t("placeholders.description"),
      dataIndex: "desc",
      key: "desc",
      width: 330,
    },
  ];
  
  const smallScreenTwoColumns = [
    {
      title: t("placeholders.parameter"),
      dataIndex: "type",
      key: "type",
      width: 100,
    },
    {
      title: t("placeholders.description"),
      dataIndex: "desc",
      key: "desc",
    },
  ];

  let scrollHeight;

  if (width > 768) {
    scrollHeight = 500;
  } else if (width > 500) {
    scrollHeight = 400;
  } else if (width > 320) {
    scrollHeight = 300;
  } else {
    scrollHeight = 250;
  }

  return (
      <div className="videoPanelInfoPanel InfoPopupContent">
        <div className="InfoPopupTitle" style={{ marginBottom: 20 }}>
          {descText}
        </div>
        {data &&
            <Table
                columns={width > 500 ? twoColumns : smallScreenTwoColumns}
                dataSource={data}
                pagination={false}
                bordered
                size="small"
                scroll={{ y: scrollHeight }}
            />}
      </div>
  );

}

export default InfoPanelsTable;
