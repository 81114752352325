import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "./CustomButton";

const EquipmentPanel = (props) => {

    const { panelHandler, panels, availablePanels } = props

    const { t } = useTranslation();

    const [collapseLabel, setCollapseLabel] = useState("Collapse All")

    useEffect(() => {
        handlePanelName(panels);
    }, [panels]);

    const changePanel = () => {
        if (collapseLabel === "Show All") {
            panelHandler(0)

        }
        else {
            panelHandler(1)

        }
    }

    function handlePanelName(panels) {
        if (panels.length === 0 && collapseLabel === "Collapse All") {
            setCollapseLabel("Show All")
        }
        else if (panels.length === availablePanels.length && collapseLabel === "Show All") {
            setCollapseLabel("Collapse All")
        }
    }

    return (
        <Row className="PanelComponent">
            <Col>
                <CustomButton filledIn={false}
                    defaultValue={true}
                    label={collapseLabel === "Show All" ? t("components.showAllButton") : t("components.collapseAll")}
                    onClick={changePanel} />
            </Col>
        </Row>
    );
}

export default EquipmentPanel;