import { Col, Row } from "antd";
import ImageCard from "./ImageCard";
import { useMemo, useState } from "react";

export default function ImageRenderItem(props) {

    const { vin, imageList, imageType, background, rowCount, isDownload } = props;

    const [renderList, setRenderList] = useState([]);

    useMemo(() => {
        prepareRenderList(imageList)
    }, [imageList, rowCount])

    return (
        <Row className="ImageList">
            {renderList.length > 0 && renderList.map((rowList, indexRow) => {
                const rowKey = rowList.map(({imageId}) => imageId.split("-")[0]).join("-"); // unique key by joining imageId's of a row
                if ("errorMessage" in rowList[0]) {
                    return (<div key={rowKey}>{rowList[0]["errorMessage"]}</div>)
                }
                else {
                    return (
                        <Row key={rowKey} style={{ width: "-webkit-fill-available" }} gutter={[24, 24]} >
                            {rowList.map(({ url, title, imageId}, indexCol) => {
                                return (
                                    <Col key={imageId} span={24 / rowCount} >
                                        <ImageCard vin={vin} imageId={imageId} url={url} title={title} imageType={imageType} background={background} isDownload={isDownload} />
                                    </Col>
                                )
                            })}
                        </Row>
                    )
                }

            })}
        </Row>)


    function prepareRenderList(imageList) {
        const tempImageList = []
        for (let i = 0; i < imageList.length; i += rowCount) {
            tempImageList.push(imageList.slice(i, i + rowCount));
        }

        setRenderList(tempImageList)
    }
}