import { useState } from "react"
import { Input } from 'antd'

function CustomInput(props) {
  
  const { label, password = false, defaultValue = "", getValue, disabled = false, val, name, onChange, message, setType, setId, Info, className} = props;
  const [value, setValue] = useState(defaultValue);

  const isValueExist = ((!val && value !=="") || (value ==="" && val)) || (val);
  const checkClassName = () => isValueExist ? "CustomInputValue" : "CustomInputNoValue";
  
  const handleChange = (e) => {
    const input = e.target.value;
    setValue(input);
    getValue(input);
  }

  return (
    <div className={`CustomInput ${className}`}>
      {isValueExist && <div className="CustomInputLabel" >{message || label}</div>}
      {password ? (<Input.Password className={checkClassName()} placeholder={label} bordered={false} value={value} onChange={handleChange} />) :
        (<Input suffix={Info && <Info/>} id={setId} type={setType} className={checkClassName()} placeholder={label} bordered={false} name={name} value={val || value} onChange={onChange || handleChange}  disabled = {disabled}/>)}
      
    </div>
  )
}

export default CustomInput;