import { notification } from 'antd'

import { v4 as uuidv4 } from 'uuid';

function _notification(params) {
  const { title, message, duration = 60, key = uuidv4(), onClose } = params;
  const args = {
    message: title,
    key: key,
    description: message,
    duration: duration,
    onClose: onClose
  };
  notification.open(args);
  return key;
}


export default _notification;