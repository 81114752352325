import { useEffect, useState } from 'react'
import { Row, Col, Card } from 'antd'

import FallbackImage from '../Assets/Images/fallback_vehicle.jpeg';

function ModelsImageList(props) {
  const { imageList, imageType, rowCount = 6 } = props;
  const [renderedList, setRenderedList] = useState([]);
  useEffect(() => {
    const tempList = [];
    for (let i = 0; i < imageList.length; i += rowCount) {
      tempList.push(imageList.slice(i, i + rowCount));
    }
    setRenderedList(tempList);
  }, [imageList, rowCount]);
  return (
      <div>
        {/* <Row justify="space-between" style={{ marginBottom: "40px" }}>
        <Col span={4}><div style={{ paddingTop: "10px" }} ><strong>{`${count} image${count > 1 ? "s" : ""} found`}</strong></div></Col>
        <Col span={4}><CustomButton label="SAVE IMAGES" filledIn={false} onClick={() => saveImages()} loading={saving} disabled={isImagesLoading} style={{ float: "right" }} /></Col>
      </Row> */}
        <Row className="ImageList">
          {imageList && renderedList.map((rowList, indexRow) => {
            const rowKey = rowList.map(({url}) => url.split("/").pop().split("-")[0]).join("-")
            return (
                <Row key={rowKey} gutter={16} style={{ width: "-webkit-fill-available" }} >
                  {rowList.map(({ url, title, blob }, indexCol) => {
                    return (
                        <Col key={url.split("/").pop()} span={24 / rowCount} >
                          <ImageCard url={url} title={title} blob={blob} imageType={imageType} />
                        </Col>
                    )
                  })}
                </Row>
            )
          })}
        </Row>
        {/* <Row justify="space-between" style={{ marginTop: "40px", marginBottom: "120px" }}>
        <Col span={4}></Col>
        <Col span={4}><CustomButton label="SAVE IMAGES" filledIn={false} onClick={() => saveImages()} loading={saving} disabled={isImagesLoading} style={{ float: "right" }} /></Col>
      </Row> */}
      </div>
  )
}

function ImageCard(props) {
  const { url, title, imageType } = props;
  const download = () => {
    const link = document.createElement("a");
    link.href = url;
    link.download = `${title}.${imageType}`
    link.click();
  }

  const ErrorImage = FallbackImage;

  return (
      <Card className="ImageCard" onClick={() => download()} bordered={false}>
        <img className="ImageCardUrl" src={url || ErrorImage} alt={title} />
        <div className="ImageCardTitle ImageCardTitleModels" >{title}</div>
      </Card>
  )
}

export default ModelsImageList;