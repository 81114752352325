
export const loadExampleImages = async () =>{
    const EXT000 = await import("../../src/Assets/ExampleImages/EXT000.json");
    const EXT010 = await import("../../src/Assets/ExampleImages/EXT010.json");
    const EXT020 = await import("../../src/Assets/ExampleImages/EXT020.json");
    const EXT030 = await import("../../src/Assets/ExampleImages/EXT030.json");
    const EXT040 = await import("../../src/Assets/ExampleImages/EXT040.json");
    const EXT050 = await import("../../src/Assets/ExampleImages/EXT050.json");
    const EXT060 = await import("../../src/Assets/ExampleImages/EXT060.json");
    const EXT070 = await import("../../src/Assets/ExampleImages/EXT070.json");
    const EXT080 = await import("../../src/Assets/ExampleImages/EXT080.json");
    const EXT090 = await import("../../src/Assets/ExampleImages/EXT090.json");
    const EXT100 = await import("../../src/Assets/ExampleImages/EXT100.json");
    const EXT110 = await import("../../src/Assets/ExampleImages/EXT110.json");
    const EXT120 = await import("../../src/Assets/ExampleImages/EXT120.json");
    const EXT130 = await import("../../src/Assets/ExampleImages/EXT130.json");
    const EXT140 = await import("../../src/Assets/ExampleImages/EXT140.json");
    const EXT150 = await import("../../src/Assets/ExampleImages/EXT150.json");
    const EXT160 = await import("../../src/Assets/ExampleImages/EXT160.json");
    const EXT170 = await import("../../src/Assets/ExampleImages/EXT170.json");
    const EXT180 = await import("../../src/Assets/ExampleImages/EXT180.json");
    const EXT190 = await import("../../src/Assets/ExampleImages/EXT190.json");
    const EXT200 = await import("../../src/Assets/ExampleImages/EXT200.json");
    const EXT210 = await import("../../src/Assets/ExampleImages/EXT210.json");
    const EXT220 = await import("../../src/Assets/ExampleImages/EXT220.json");
    const EXT230 = await import("../../src/Assets/ExampleImages/EXT230.json");
    const EXT240 = await import("../../src/Assets/ExampleImages/EXT240.json");
    const EXT250 = await import("../../src/Assets/ExampleImages/EXT250.json");
    const EXT260 = await import("../../src/Assets/ExampleImages/EXT260.json");
    const EXT270 = await import("../../src/Assets/ExampleImages/EXT270.json");
    const EXT280 = await import("../../src/Assets/ExampleImages/EXT280.json");
    const EXT290 = await import("../../src/Assets/ExampleImages/EXT290.json");
    const EXT300 = await import("../../src/Assets/ExampleImages/EXT300.json");
    const EXT310 = await import("../../src/Assets/ExampleImages/EXT310.json");
    const EXT320 = await import("../../src/Assets/ExampleImages/EXT320.json");
    const EXT330 = await import("../../src/Assets/ExampleImages/EXT330.json");
    const EXT340 = await import("../../src/Assets/ExampleImages/EXT340.json");
    const EXT350 = await import("../../src/Assets/ExampleImages/EXT350.json");
    const INT1 = await import("../../src/Assets/ExampleImages/INT1.json");
    const INT2 = await import("../../src/Assets/ExampleImages/INT2.json");
    const INT3 = await import("../../src/Assets/ExampleImages/INT3.json");
    const INT4 = await import("../../src/Assets/ExampleImages/INT4.json");
    const BIS1 = await import("../../src/Assets/ExampleImages/BIS1.json");
    const BIS2 = await import("../../src/Assets/ExampleImages/BIS2.json");
    const BIS3 = await import("../../src/Assets/ExampleImages/BIS3.json");
    const BIS4 = await import("../../src/Assets/ExampleImages/BIS4.json");
    const BIS5 = await import("../../src/Assets/ExampleImages/BIS5.json");
    const BIS6 = await import("../../src/Assets/ExampleImages/BIS6.json");

    const exampleImages = {
        W1K2971552A00000: [
            {
                title: "EXT000",
                url: EXT000.b64,
                imageId: " EXT000"
            },
            {
                title: "EXT010",
                url: EXT010.b64,
                imageId: " EXT010"
            },
            {
                title: "EXT020",
                url: EXT020.b64,
                imageId: " EXT020"
            },
            {
                title: "EXT030",
                url: EXT030.b64,
                imageId: " EXT030"
            },
            {
                title: "EXT040",
                url: EXT040.b64,
                imageId: " EXT040"
            },
            {
                title: "EXT050",
                url: EXT050.b64,
                imageId: " EXT050"
            },
            {
                title: "EXT060",
                url: EXT060.b64,
                imageId: " EXT060"
            },
            {
                title: "EXT070",
                url: EXT070.b64,
                imageId: " EXT070"
            },
            {
                title: "EXT080",
                url: EXT080.b64,
                imageId: " EXT080"
            },
            {
                title: "EXT090",
                url: EXT090.b64,
                imageId: " EXT090"
            },
            {
                title: "EXT100",
                url: EXT100.b64,
                imageId: " EXT100"
            },
            {
                title: "EXT110",
                url: EXT110.b64,
                imageId: " EXT110"
            },
            {
                title: "EXT120",
                url: EXT120.b64,
                imageId: " EXT120"
            },
            {
                title: "EXT130",
                url: EXT130.b64,
                imageId: " EXT130"
            },
            {
                title: "EXT140",
                url: EXT140.b64,
                imageId: " EXT140"
            },
            {
                title: "EXT150",
                url: EXT150.b64,
                imageId: " EXT150"
            },
            {
                title: "EXT160",
                url: EXT160.b64,
                imageId: " EXT160"
            },
            {
                title: "EXT170",
                url: EXT170.b64,
                imageId: " EXT170"
            },
            {
                title: "EXT180",
                url: EXT180.b64,
                imageId: " EXT180"
            },
            {
                title: "EXT190",
                url: EXT190.b64,
                imageId: " EXT190"
            },
            {
                title: "EXT200",
                url: EXT200.b64,
                imageId: " EXT200"
            },
            {
                title: "EXT210",
                url: EXT210.b64,
                imageId: " EXT210"
            },
            {
                title: "EXT220",
                url: EXT220.b64,
                imageId: " EXT220"
            },
            {
                title: "EXT230",
                url: EXT230.b64,
                imageId: " EXT230"
            },
            {
                title: "EXT240",
                url: EXT240.b64,
                imageId: " EXT240"
            },
            {
                title: "EXT250",
                url: EXT250.b64,
                imageId: " EXT250"
            },
            {
                title: "EXT260",
                url: EXT260.b64,
                imageId: " EXT260"
            },
            {
                title: "EXT270",
                url: EXT270.b64,
                imageId: " EXT270"
            },
            {
                title: "EXT280",
                url: EXT280.b64,
                imageId: " EXT280"
            },
            {
                title: "EXT290",
                url: EXT290.b64,
                imageId: " EXT290"
            },
            {
                title: "EXT300",
                url: EXT300.b64,
                imageId: " EXT300"
            },
            {
                title: "EXT310",
                url: EXT310.b64,
                imageId: " EXT310"
            },
            {
                title: "EXT320",
                url: EXT320.b64,
                imageId: " EXT320"
            },
            {
                title: "EXT330",
                url: EXT330.b64,
                imageId: " EXT330"
            },
            {
                title: "EXT340",
                url: EXT340.b64,
                imageId: " EXT340"
            },
            {
                title: "EXT350",
                url: EXT350.b64,
                imageId: " EXT350"
            },
            {
                title: "INT1",
                url: INT1.b64,
                imageId: "INT1"
            },
            {
                title: "INT2",
                url: INT2.b64,
                imageId: "INT2"
            },
            {
                title: "INT3",
                url: INT3.b64,
                imageId: "INT3"
            },
            {
                title: "INT4",
                url: INT4.b64,
                imageId: "INT4"
            },
            {
                title: "BIS1",
                url: BIS1.b64,
                imageId: "BIS1"
            },
            {
                title: "BIS2",
                url: BIS2.b64,
                imageId: "BIS2"
            },
            {
                title: "BIS3",
                url: BIS3.b64,
                imageId: "BIS3"
            },
            {
                title: "BIS4",
                url: BIS4.b64,
                imageId: "BIS4"
            },
            {
                title: "BIS5",
                url: BIS5.b64,
                imageId: "BIS5"
            },
            {
                title: "BIS6",
                url: BIS6.b64,
                imageId: "BIS6"
            },
        ],
    };

    return exampleImages;
}



