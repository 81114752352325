export const translationsDe = {
    imagesTab: {
        imagesTabName: "Bilder",
        downloadText: "Fahrzeugbilder herunterladen",
        vin: "Sie können bis zu 5 FINs getrennt mit Komma eingeben.",
        imagesSettings: "Bildeinstellungen",
        imagesSettingsDesc: "Sie können die folgenden Parameter ändern, um Bilder für den gewünschten Einsatzzweck zu erhalten.",
        editImage: "Fahrzeugbilder bearbeiten",
        editDesc: "Sie können die Außenbilder bearbeiten und optional ein Hintergrundbild, bis zu 2 Logos und ein Bannerbild hinzufügen.",
        crop: {
            both: "beide",
            top: "oben",
            bottom: "unten"
        },
        position: {
            standard: "Standard",
            centerStage: "Center Bühne",
            center: "Center",
            frontWheel: "Vorderrad",
            middle: "Mittig"
        },
        layouts: {
            ext: "Außenbilder (Exterieur)",
            int: "Innenbilder (Interieur)",
            bis: "360° Innenraum"
        },
        infoTable: {
            lightDesc: "Sie können zwischen Tagansicht Fahrzeugbildern (Licht = Tag, voreingestellter Wert) und Nachtansicht Fahrzeugbildern (Licht = Nacht) wählen.",
            roofDesc: "Dieser Parameter ist nur anwendbar auf Cabriolets. Sie können wählen zwischen Fahrzeugbildern mit offenem Dach (Dach offen = Ja) oder geschlossenem Dach (Dach offen = Nein; Standardwert).",
            backgroundDesc: `Sie können zwischen Fahrzeugbildern mit transparentem Hintergrund (Background = Ohne) oder mit einem der verfügbaren Hintergründe (Hintergrund = Mit) wählen. Die Auswahl der verfügbaren Hintergründe wird sichtbar, sobald der Parameter auf "Mit" gesetzt wird"`,
            backgroundSelectDesc: "Sie können zwischen einem der gelisteten Hintergründe wählen. Der voreingestellte Hintergrund ist bereits ausgewählt und hängt vom jeweiigen Fahrzeugmodell ab.",
            fileFormatDesc: "Sie können eines der Dateiformate wählen: PNG (voreingesteller Wert), JPEG oder WEBP. Das JPEG Format ist nicht empfohlen für den transparenten Hintergrund, da man für diese Bildaufrufe einen weißen oder schwarzen Hintergrund erhält. WEBP ist die Empfehlung für die transparenten Hintergrundbilder, da dieses Format von den meisten modernen Browsern unterstützt werden und gleichzeitig die kleinste Dateigröße aufweisen.",
            imageFormatDesc: `Sie können zwischen den Bildformaten 16:9 (voreingestelltes Format) und 4:3 wählen. Alle Fahrzeugbilder im 4:3 Format werden auf diese Bildauflösung zugeschnitten und erhalten dabei einen weißen Rand. Die Position des weißen Rands kann mit dem Paramter "4:3 Rahmen" festgelegt werden.`,
            cropPositionDesc: "Sie können die Position des weißen Rands wie folgt festlegen: beide (oben und unten), (ausschließlich) oben, (ausschließlich) unten.",
            vehiclePositionDesc: `Dieser Wert uniformiert die Fahrzeugposition und -größe und kann die visuelle Platzierung des Bildes harmonisieren. Die angebotenen uniformierten Fahrzeugpositionen können beispielsweise bei der Ergebnisliste der Bestandsfahrzeugsuche als "Hero"-Bild oder für eine Modellübersichtsliste verwendet werden. Sie können zwischen folgenden möglichen Werten wählen: Standard (voreingestellter Wert - ohne Uniformierung), Center Bühne, Center, Vorderrad, Mittig.`,
            fallbackDesc: "Mit der Fallback=Ja Auswahl erhalten Sie auch dann Bilder, wenn für die angegebene Parameterkombination keine Bilder verfügbar sind. Dies hilft, wenn für das Fahrzeugmodell oder die konkreten Parameter, z.B. Dach offen für Nicht-Cabrio-Modelle, der Fehler “Fahrzeug nicht gefunden” gemeldet wird."
        },
        imageCompose: {
            preview: "Vorschau",
            selectVIN: "FIN Auswahl",
            logoUpperRight: "Logo oben rechts",
            logoUpperLeft: "Logo oben links",
            backgroundImage: "Hintergrundbild",
            bannerImage: "Bannerbild",
            logoLeft: "Abstand links in px",
            logoRight: "Abstand rechts in px",
            logoTop: "Abstand oben in px",
            logoResize: "Größenänderung in %",
            tooltipOnPosition: "Gültige Werte 0 .. 100.",
            tooltipOnResize: "Gültige Werte 25 .. 400",
            editVehicleImages: "Bilder bearbeiten",
            editVehicleImagesDataTable: {
                dataTableDesc: "Sie können die Außenbilder optional mit einem Hintergrundbild, mit bis zu zwei Logobildern und einem Bannerbild bearbeiten.",
                backgroundImageDesc: "Laden Sie Ihr eigenes Hintergrundbild hoch. Wir empfehlen eine HD-Auflösung von 1920x1080px oder höher bis 4512x3384px für 16:9 Bilder oder eine hohe Auflösung von 1920x1440 für 4:3 Bilder.",
                logoUpperLeftDesc: "Laden Sie ein Logobild hoch, das in der oberen linken Ecke platziert wird. Wir empfehlen eine HD-Auflösung, die zum Hintergrundbild passt. Sie können Randabstand und Größe anpassen.",
                logoUpperRightDesc: "Laden Sie ein Logobild hoch, das in der oberen rechten Ecke platziert wird. Wir empfehlen eine HD-Auflösung, die zum Hintergrundbild passt. Sie können Randabstand und Größe anpassen.",
                bannerImageDesc: "Laden Sie ein Bild hoch, das unten platziert wird. Wir empfehlen eine HD-Auflösung, die zum Hintergrundbild passt."
            },
            imageSizeRangeError: "Wert <Right/left/top> für Abstand des <first/second> Logos ist ungültig. Wert muss im Bereich 0-100 sein. Standardwert 20px wird verwendet.",
            rangeErrorTitle: "Überschrittener Bereich",
            notification: {
                topFirst: "Abstand oben in px muss im Bereich 0-100 liegen (Default 20px).",
                topSecond: "Abstand oben in px muss im Bereich 0-100 liegen (Default 20px).",
                leftFirst: "Abstand links in px muss im Bereich 0-100 liegen (Default 20px).",
                rightSecond: "Abstand rechts in px muss im Bereich 0-100 liegen (Default 20px).",
                resizeImage: "Größenänderung in % muss im Bereich 25-400 liegen (Default 100%).",
            },
            scaleToFit: "Bild Skalieren"
        },
        backgrounds: {
            T0: "Weiss",
            T1: "Grau",
            T5: "Holodeck",
            T6: "SSP",
            T7: "SSP-AMG",
            T8: "Showroom",
            T9: "Freisteller",
            T10: "Outdoor",
            T11: "Museum",
            T12: "Loft",
            T13: "Hall Graz (G-Class only)",
            T14: "EQ",
            T15: "Salt Lake (US only)",
            T40: "T40 (Vans only)",
            T41: "T41 (Vans only)",
            T42: "T42 (Vans only)",
            T99: "T99"
        },
        default: "Standard",
        image360View: {
            title: "360° Bilderansicht",
            description: "Sie können die 360°-Bilder des ausgewählten Fahrzeugs von außen und innen betrachten. Ändern Sie die 360° Ansicht mit der Mausbewegung.",
            headingExterior: "360° Außenansicht",
            headingInterior: "360° Innenansicht",
        },
        reloadToClick:'Klicken Sie zum Neuladen'
    },
    equipmentsTab: {
        equipmentsTabName: "Ausstattungen",
        downloadText: "Ausstattungsbilder herunterladen",
        engineComponent: "Motor",
        trimComponent: "Zierelemente",
        rimComponent: "Felgen",
        upholsteryComponent: "Polster",
        paintComponent: "Farben",
        upperPaintComponent: "Farbe 2 (Lack oben)",
        equipmentComponent: "Ausstattungen",
        filterEquipments: "Filter Ausstattungen",
        filterEquipmentsIntroText: `Sie können nach Ausstattungscodes und Bildtypen filtern. Lassen Sie das Feld leer um alle Bilder zu erhalten. Um einzelne Ausstattungen zu filtern geben Sie die SA-Codes mit Komma getrennt ein (Beispiel: 233, A51, P69). Um einzelne Bildtypen zu filtern geben Sie die Werte mit Komma getrennt ein (Beispiel: A23, A24).`,
        showImageTypesHoverInfoBox: "Zeige nur das erste SA-Bild",
        disabledFilterText: 'Bildtyp Filter ist deaktiviert. Zum Aktivieren, "Zeige Bildtypen" Schalter auf Alle ändern',
        applyFiltersHoverInfoBox: "Die Ausstattungsbilder sind in mehreren Auflösungen verfügbar. Dies ist über den Bildtyp definiert und verfügbare Werte in der Tabelle aufgelistet.",
        equipmentFilter: {
            resolution: "Auflösung",
            aspectRatio: "Seitenverhältnis",
            size: "Größe",
            small: "Klein",
            medium: "Mittel",
            large: "Groß",
            square: "Quadratisch",
        }
    },
    videosTab: {
        videosTabName: "Videos",
        downloadText: "Fahrzeugvideos herunterladen",
        videoEditBlackColor: "Textfarbe schwarz",
        editVideoScenesIntroText: `In diesem Abschnitt können Sie den Standardumfang der Szenen anpassen sowie Ihren händlerspezifischen Inhalt festlegen. Sie aktualisieren das Video nach Änderungen über den Button VIDEO LADEN. Wenn Sie eine neue FIN laden oder die Sprache ändern, werden alle Inhalte neu geladen und dadurch Ihre bisherigen Änderungen überschrieben.`,
        editVideoScenesClosureText: `Sie können Ihre Änderungen in einer lokalen Textdatei speichern und diese jederzeit wieder laden. Sie können auch auf die Standardwerte zurücksetzen.`,
        editSceneTablesPopover: {
            videoAdjustText: "Mit diesen Einstellungen können Sie das Video Player Plugin ändern, sofern der Browser des Users diese Funktionen unterstützt.",
            videoContentText: `Mit diesen Einstellungen können Sie den Fahrzeugbezogenen Umfang des Videos ändern.`,
            videoSceneText: "Sie können die im Video enthaltenen Szenen anpassen. Dadurch können Sie einzelne Szenen herausnehmen und auch die Länge des Video verändern.",
            videoAdjustDataTable: {
                autoPlay: `Einstellung, ob das Video erst startet, wenn der User das Video anklickt ("Aus") oder ob das Video automatisch startet ("An").`,
                autoReplay: `Einstellung, ob das Video in einer Schleife wiederholt abgespielt werden soll. Mit der Einstellung "Aus" wird das Video nur einmal abgespielt.`
            },
            videoContentDataTable: {
                touchDevice: `Definiert die Touch-Fähigkeit des Benutzergeräts und die Interaktivität des Videos. Video zeigt entweder einen Button (Touch Gerät = “An”) oder einen QR-Code (Touch Gerät = “Aus”). Bitte beachten Sie, dass interaktive Buttion in dem MP4 Videoformat nicht verfügbar sind. Bitte wählen Sie stattdessen die QR-Code Option.`,
                muteAudio: "Einstellung für den Ton des Videos: Aus oder An. Default: das Video wird mit Ton abgespielt.",
                light: "Sie können wählen ob die Szenen die Fahrzeuge in Taglicht (Standard) oder mit Nachtlicht darstellen. Bitte beachten Sie, dass die Nachtszenen nicht für alle Modelle verfübar sind.",
                outputFormat: "Wählen Sie die Videoausrichtung in Querformat (normale 16:9 Bildschirme) oder Hochformat (mobile Geräte).",
                selectMarket: "Einstellung für Land und Sprache, die für die Textelemente und Fahrzeugbeschreibungen genutzt wird.",
                equipmentCodes: "Sie können eine Videoszene mit zwei oder drei Ausstattungsdetails definieren. Geben Sie dazu 2 oder 3 SA-Codes mit Komma getrennt ein. Eine Prüfung der Codes auf Gültigkeit erfolgt nicht.",
            },
            videoScenesDataTable: {
                startScene: "Video Szene mit Außen- und Innenbildern. Das Textelement kann geändert werden.",
                specScene: "Video Szene mit bis zu 4 Infos zur Fahrzeugspezifikation. Sie können alle Texte ändern.",
                twoEquipmentsScene: "Video Szene mit 2 Aussstattungscodes inkl. Beschreibungen und SA-Bild. Die Szene kann entweder mit 2 oder 3 SA-Codes genutzt werden.",
                threeEquipmentsScene: "Video Szene mit 3 Ausstattungscodes inkl. Beschreibunen und SA-Bild. Die Szene kann entweder mit 2 oder 3 SA-Codes genutzt werden.",
                outroScene: "Video Abschluss Szene mit Fahrzeuganimation. Sie können den Text ändern.",
                endScene: "Video Ende Szene with verschiedenen Elementen sowie Button bzw. QR-Code. Sie können alle Einträge ändern.",
            },
            videoOutputSettingsDataTable: {
                videoLan: "de",
                description: "Sie können als Ausgabeformat MP4 oder SDK Daten wählen.",
                outputFormat: `Sie können als Ausgabeformat MP4 oder SDK Daten wählen. with de toggle lables “SDK Daten”`,
                outputFormatDefault: "SDK Daten",
                outputFormatDefault2: "MP4",
                videoResolution: "Video Auflösung für das MP4 Ausgabeformat. Die Auflösung beeinflusst die Video Qualität sowie die MP4 Dateigröße (sehr gering, gering, mittel, hoch, sehr hoch).",
            },
            videoAdjustPanel: {
                videoAdjustText: "In diesem Abschnitt können Sie den Standardumfang der Szenen anpassen sowie Ihren händlerspezifischen Inhalt festlegen. Sie aktualisieren das Video nach Änderungen über den VIDEO LADEN button. Wenn Sie eine neue FIN laden oder die Sprache ändern, werden alle Inhalte neu geladen und dadurch Ihre bisherigen Änderungen überschrieben.",
                videoAdjustFileInputText: "Sie können Ihre Änderungen in einer lokalen Textdatei speichern (WERTE SPEICHERN) und diese jederzeit wieder laden (WERTE LADEN). Sie können auch auf die Defaultwerte zurücksetzen (WERTE ZURÜCKSETZEN).",
                videoEditText: "Sie können die Elemente der Video Ende Szene nach Ihren Bedürfnissen anpassen.",
                videoEditTextStartScene: "Sie können die Elemente der Video Start Szene nach Ihren Bedürfnissen anpassen.",
                videoEditTextSpecScene: "Sie können die Elemente der Video Details Szene nach Ihren Bedürfnissen anpassen.",
                videoEditTextOutroScene: "Sie können die Elemente der Video Abschluss Szene nach Ihren Bedürfnissen anpassen.",
                videoEditBlackColor: "Textfarbe schwarz",
                editVideoEndSceneDataTable: {
                    dealerLogo: "Bild Web-URL für Ihr Händler Logo im Format png oder jpeg.",
                    backgroundImage: "Bild Web-URL für Ihr eigenes Hintergrundbild.",
                    textMessage: "Text-Beschreibung der Video Ende Szene.",
                    buttonLabel: 'Beschreibung des Call-2-Action Buttons. Der Button ist sichtbar, wenn der Parameter "Touch Gerät" auf "Ja" eingestellt ist.',
                    buttonLink: "Web-Link auf den der Call-2-Action Button verlinked.",
                    qrCode: `Bild Web-URL für den QR-Code. Der QR-Code ist sichtbar, wenn der Parameter "Touch Gerät" = "Nein" eingestellt ist.`,
                    vehiclePerspective: `Definiert die gezeigte Fahrzeugperspektive. Auswahl von 0° .. 350° möglich (Default 40°)".`
                },
                editVideoStartSceneDataTable: {
                    scene1text: "Erstes Textelement der Start Szene.",
                    scene2text: "Zweites Textelement der Start Szene.",
                    scene3text: "Drittes Textelement der Start Szene.",
                },
                editVideoSpecSceneDataTable: {
                    title1: "Titel der ersten Detailinformation.",
                    text1: "Beschreibung der ersten Detailinformation.",
                    title2: "Titel der zweiten Detailinformation.",
                    text2: "Beschreibung der zweiten Detailinformation.",
                    title3: "Titel der dritten Detailinformation.",
                    text3: "Beschreibung der dritten Detailinformation.",
                    title4: "Titel der vierten Detailinformation.",
                    text4: "Beschreibung der vierten Detailinformation.",
                    textColourBlack: "Definiert die Textfarbe: weiß (Standardwert) oder schwarz. Die Einstellung kann bei hellem bzw. dunklem Hintergrund der Szene geändert werden."
                },
                editVideo2EquipmentScene: {
                    title: "Überschrift der Ausstattungsszene.",
                    image1: "Bild Web-URL des ersten SA-Codes.",
                    text1: "Beschreibung des ersten SA-Codes.",
                    image2: "Bild Web-URL des zweiten SA-Codes.",
                    text2: "Beschreibung des zweiten SA-Codes.",
                },
                editVideo3EquipmentScene: {
                    title: "Überschrift der Ausstattungsszene.",
                    image1: "Bild Web-URL des ersten SA-Codes.",
                    text1: "Beschreibung des ersten SA-Codes.",
                    image2: "Bild Web-URL des zweiten SA-Codes.",
                    text2: "Beschreibung des zweiten SA-Codes.",
                    image3: "Bild Web-URL des dritten SA-Codes.",
                    text3: "Beschreibung des dritten SA-Codes.",
                },
                editVideoOutroSceneDataTable: {
                    outroText: "Text der animierten Abschlussszene."
                }
            }
        },
        editVideoSceneHeadings: {
            editVideoStartSceneTitle: "Video Start Szene bearbeiten",
            editVideoSpecSceneTitle: "Video Details Szene bearbeiten",
            editVideoEndSceneTitle: "Video Ende Szene bearbeiten",
            editVideoOutroSceneTitle: "Video Abschlussszene bearbeiten",
            editAllScenes: "Video Szenes bearbeiten"
        },
        editVideoSceneLabels: {
            sceneLabel: "Text Szene {{number}}",
            titleLabel: "Titel {{number}}",
            textLabel: "Text {{number}}",
            videoEditBlackColor: "Textfarbe schwarz",
            outroText: "Abschlusstext",
        },
        endSceneEntries: {
            dealerLogo: "Händlerlogo",
            buttonLabel: "Buttontext",
            backgroundImage: "Hintergrundbild",
            buttonLink: "Button Zielwebadresse",
            textMessage: "Aktionstext",
            qrCode: "QR Code",
            vehiclePerspective: "Bildperspektive",
        },
        videoOutputFormatSection: {
            outputFormat: "Ausgabeformat",
            videoResolution: "Videoqualität",
            videoResolutionSettings: {
                videoResolutionVeryLow: "Sehr Gering",
                videoResolutionLow: "Gering",
                videoResolutionMedium: "Mittel",
                videoResolutionHigh: "Hoch",
                videoResolutionVeryHigh: "Sehr Hoch"
            }
        },
        videoConfirmationSection: {
            confirmationOnContinueMessage: "Möchten Sie fortfahren?",
            confirmationOnContinueContent: "Die Erstellung der MP4 Videodatei dauert bis zu 5 Minuten.",
            confirmationOnContinueOkText: "OK",
            confirmationOnContinueCancelText: "Abbrechen",
            confirmationOnReloadData: "Die Daten für das Fahrzeug {{vin}} müssen neu geladen werden",
            confirmationOnSaveConfiguration: "Die Daten für das Fahrzeug {{vin}} müssen neu geladen werden. Möchten Sie Ihre Änderungen speichern?",
            confirmationYesButton: "Ja",
            confirmationNoButton: "Nein",
            confirmationCheckDialog: "Es wurden neue FIN-Daten geladen und Ihre vorherigen Werte zusammengeführt. Bitte prüfen Sie das neue Video."
        },
        videoProcessingSection: {
            processingFirstMessage: "Schritt 1 von 2: Videodaten bearbeiten…",
            processingSecondMessage: "Schritt 2 von 2: Videodatei erstellen und Herunterladen…",
        }
    },
    specificationsTab: {
        specificationsTabName: "Spezifikationen",
        emissionsTabName: "WLTP Verbrauchswerte",
        equipmentsTabName: "Ausstattungsdetails",
        meServicesTabName: "Digitale Extras",
        simSearchTabName: "Verfügbare ähnliche Lagerfahrzeuge",
        rycTabName: "Verfügbares aktuelles Modell",
        specificationsContentSettings: "Spezifikationen Einstellungen",
        specificationsContentSettingsDesc: "Sie können die folgenden Parameter ändern, um Spezifikationen für den gewünschten Einsatzzweck zu erhalten.",
        specificationsParameters: "Spezifikationen Parameter",
        specificationsParametersDesc: "Sie können die weiteren Parameter ändern, um die Daten für den gewünschten Einsatzzweck zu filtern.",
        specificationsParametersLabels: {
            additionalSpecs: "Erweiterte Attribute",
            optionsNullDescription: "Ausstattung ohne Beschreibung",
            options: "Ausstattungen",
            technicalData: "Technische Daten",
            payloadNullValues: "Payload Null Werte"
        },
        downloadText: "Fahrzeugspezifikationen herunterladen",
        filterSpecs: "Filteroptionen",
        specificationsAccordionName: "Spezifikationen",
        copyTheData: "Kopieren",
        copiedData: "Kopiert",
        infoTable: {
            contentSettings: {
                specificationsDesc: "Aktivieren oder Deaktivieren der Datenabfrage für Spezifikationen",
                emissionsDesc: "Aktivieren oder Deaktivieren der Datenabfrage für WLTP Verbrauchswerte",
                equipmentsDesc: "Aktivieren oder Deaktivieren der Datenabfrage für Ausstattungsdetails",
                meServicesDesc: "Aktivieren oder Deaktivieren der Datenabfrage für Digitale Extras",
                simSearchDesc: "Aktivieren oder Deaktivieren der Datenabfrage für Verfügbare ähnliche Lagerfahrzeuge",
                rycDesc: "Aktivieren oder Deaktivieren der Datenabfrage für aktuelle Modellabfrage",
                selectMarket: "Einstellung für Land und Sprache, die für die Textelemente und Fahrzeugbeschreibungen genutzt wird.",
            },
            specificationsParameters: {
                additionalSpecsDesc: "Reduziert den Datenumfang und lässt das Object additionalSpecs{} mit den Zusatzdaten weg.",
                optionsNullDescriptionDesc: "Reduziert den Datenumfang und lässt Ausstattungscodes (option codes) ohne Beschreibung weg.",
                optionsDesc: "Reduziert den Datenumfang und lässt das Object options{} mit den Ausstattungen weg.",
                technicalDataDesc: "Reduziert den Datenumfang und lässt das Object technicalData{}  mit den technische Daten weg.",
                payloadNullValuesDesc: "Reduziert den Datenumfang und lässt alle “null” Attribute weg."
            }
        }
    },
    filter: {
        searchEntry: "Eintrag suchen",
        hideNull: "Leere Werte ausblenden",
    },
    modelsTab: {
        modelsTabName: "Modelle",
        modelDataHeading: "Modelldaten",
        downloadText: "Fahrzeugmodelldaten herunterladen",
        modelsIntroText: "Laden Sie Modellinfromationen für ein konkretes Fahrzeugmodell oder laden Sie alle Fahrzeugbilder der Modellübersicht herunter.",
        baumuster: "Baumuster*",
        modelYear: "Modelljahr*",
        changeYear: "Änderungsjahr",
        nationalSalesType: "Marktcode NST",
        technicalData: "Technische Daten",
        modelsContentSettings: "Modelleinstellungen",
        modelsContentSettingsDesc: "Sie können die folgenden Parameter ändern, um Modelle für den gewünschten Einsatzzweck zu erhalten.",
        infoTable: {
            selectMarket: "Einstellung für Land und Sprache, die für die Textelemente und Fahrzeugbeschreibungen genutzt wird.",
            baumuster: "Baumuster für Modelle.",
            modelYear: "Modelljahr für Modelle.",
            changeYear: "Änderungsjahr für Modelle.",
            nationalSalesType: "Marktcode NST für Modelle.",
            technicalData: "Aktivieren oder Deaktivieren der Datenabfrage für Technische Daten.",
        },
        filterModels: "Filteroptionen"
    },
    loadSaveButtons: {
        loadSampleImages: "BEISPIEL BILDER LADEN",
        loadImages: "BILDER LADEN",
        loadImagesTabVideo: "BILDER VIDEO LADEN",
        loadSampleVideo: "BEISPIEL VIDEO LADEN",
        loadVideo: "VIDEO LADEN",
        saveVideo: "VIDEO SPEICHERN",
        loadSampleSpecs: "BEISPIEL DATEN LADEN",
        loadSpecs: "DATEN LADEN",
        saveImages: "BILDER SPEICHERN",
        saveImage: "BILD SPEICHERN", //
        showImages: "Bilder anzeigen",
        saveSettings: "WERTE SPEICHERN",
        loadSettings: "WERTE LADEN",
        resetSettings: "WERTE ZURÜCKSETZEN",
        saveSpecs: "SPEICHERN",
        copySpecs: "KOPIEREN",
        saveComposedImage: "BILDER SPEICHERN",
        loadBackgrounds: "HINTERGRUND LADEN",
        loadSampleData: "BEISPIEL DATEN LADEN",
        loadData: "DATEN LADEN",
        saveData: "DATEN SPEICHERN",
    },
    components: {
        // Images Tab
        dayLight: "Tag",
        nightLight: "Nacht",
        roof: "Dach Offen",
        roofClosed: "Nein",
        roofOpen: "Ja",
        background: "Hintergrund",
        backgroundScene: "Hintergrundbild",
        withoutBackground: "Ohne",
        withBackground: "Mit",
        fileFormat: "Dateiformat",
        imageFormat: "Bildgröße",
        backgroundSelection: "Auswahl Hintergrund",
        cropPosition: "Zuschneiden Position",
        cropPosition43: "Rahmen",
        vehiclePosition: "Fahrzeugposition",
        fallback: "Fallback",
        fallbackOn: "Ja",
        fallbackOff: "Nein",

        // Equipments Tab
        applyFiltersButton: "Filter anwenden",
        showImageTypes: "Zeige Bildtypen",
        single: "Einzel",
        all: "Alle",
        showAllButton: "Alles Aufklappen",

        // Videos Tab
        videoPlayerSettings: "Video Player Einstellungen",
        autoPlay: "Auto Start",
        autoReplay: "Auto Wiederholung",
        videoContentSettings: "Video Inhalt Einstellungen",
        touchDevice: "Touch Gerät",
        light: "Licht",
        muteAudio: "Ton ausschalten",
        equipmentCodesPlaceholder: "Ausstattungscodes",
        videoSceneSettings: "Video Szenen Einstellungen",
        startScene: "Start Szene",
        specScene: "Details Szene",
        outroScene: "Abschluss Szene",
        endScene: "Ende Szene",
        twoEquipmentsScene: "2-SA-Code Szene",
        threeEquipmentsScene: "3-SA-Code Szene",
        videoOutputSettings: "Video Ausgabe Einstellungen",
        copyClipboard: "KOPIEREN",
        outputFormat: "Ausgabe Querformat",
        outputLandscape: "An",
        outputPortrait: "Aus",
        blingsProject: "Blings Projekt",



        // Specifications Tab
        hideNullEntries: "Leere Werte ausblenden",

        // Common
        onButton: "An",
        offButton: "Aus",
        selectMarket: "Auswahl Markt",
        showAll: "Alles Zeigen",
        collapseAll: "Alles Zuklappen",
    },
    infoTexts: {
        imagesFound: "Bilder geladen",
        optionCodesFound: "Ausstattungscodes geladen",
        imageFound: "Bild geladen", // Comment: images found is for multiple images. This one is for single images.
        optionCodeFound: "Ausstattungscode geladen",
    },
    placeholders: {
        apiKeyPlaceholder: "Produktschlüssel (API Key)",
        vinNumberPlaceholder: "FIN",
        vinNumbersPlaceholder: "FIN(s)",
        equipmentCodesPlaceholder: "Ausstattungscodes",
        imageTypesPlaceholder: "Bildtypen",
        imageTypePlaceholder: "Bildtyp",
        searchEntryPlaceholder: "SA-Code Suche",
        description: "Beschreibung",
        parameter: "Parameter"
    },
    tabs: {
        images: "Bilder",
        equipments: "Ausstattungen",
        videos: "Videos",
        specifications: "Spezifikationen",
        models: "Modelle"
    },
    notification: {
        error: "Fehler",
        warning: "Warnung",
        finished: "Fertig",
        info: "Info",
        information: "Information",
        loading: "Laden...",
        gettingImages: "Bilder geladen für: ",
        gettingEquipments: "Lade Ausstattungen für: ",
        gettingEquipmentSample: "Lade Beispiel Ausstattungen",
        failedImage: "Fehler beim Laden Bild für: ",
        loadedImages: "Bilder geladen für: ",
        partiallyLoad: "Es wurden neue FIN-Daten geladen und Ihre vorherigen Werte zusammengeführt. Bitte prüfen Sie das neue Video.",
        fullyLoad: "Benutzerwerte geladen.",
        nothingToSave: "Keine Änderungen vorhanden. Keine Werte zu speichern.",
        notMatchingFilters: "Filterwerte nicht in der SA-Liste gefunden.",
        specificationFilter: "Filterwert <string_name> nicht gefunden:",
        specificationError: "Fehler beim Laden der Daten für: ",
        vehiclesError: "Keine Spezifikationsdaten gefunden für FIN: ",
        emissionError: "Keine WLTP Verbrauchswerte Daten vorhanden für diese VIN: ",
        equipmentError: "Keine Ausstattungsdaten gefunden für FIN: ",
        specificationLoaded: "Daten geladen für: ",
        equipmentCodeCount: "Es können zwei oder drei SA-Codes eingegeben werden.",
        videoCompleted: "Das Video kann bearbeitet werden: {{vin}} and Markt: {{marketID}}",
        videoEmptyScene: "Alle Szenen deaktiviert. Es kann kein Video angezeigt werden.",
        videoNonSufficentImage: "Zu wenig Bilder für das Video verfügbar.",
        invalidVIN: "Invalid VIN", // 
        invalidVINLongDesc: "Die eingegebene FIN ist ungültig.", //
        invalidVINDesc: "You have entered invalid VINs. Please correct.", //
        invalidVINCount: "Zu viele FINs. Bitte korrigieren.", //
        labelBackground: "Hintergrund",
        labelImageF: "Hintergrund",
        switched: "Geändert in:",
        backgroundWithout1: "Nein. Parameter Fahrzeugposition verfügbar.",
        backgroundWith1: "Ja. Parameter Fahrzeugposition nicht verfügbar.",
        backgroundWithout2: "Nein. Parameter Hintergrundbild nicht verfügbar.",
        backgroundWith2: "Ja. Parameter Hintergrundbild verfügbar.",
        imageFormat4_3: "4:3. Parameter Rahmenposition verfügbar.",
        imageFormat16_9: "16:9. Parameter Rahmenposition nicht verfügbar.",
        loadedImageFileSizeError: "Fehler: Dateigröße darf nicht größer als {{fileSize}} MB sein.",
        notCreated: "Das Video kann derzeit nicht erstellt werden.",
        invalidBackgroundErrorMessage: "Neue FIN. Bitte zuerst Hintergründe neu laden (HINTERGRUND LADEN).",
        loadedData: "Laden der Daten für: ",
        failedModelData: "Keine Daten für das Modell gefunden.",
        sample: "Beispiel"
    },
    mainScreen: {
        intro: "Marketing-, Bild- & Videodaten für Ihre Lagerfahrzeuge!", 
        text: "Programmieren ist nicht Ihre Sache? Nutzen Sie hier Ihre Product-Keys, um so einfach wie möglich von digitalen Marketingdaten zu profitieren, die auf den Fahrzeugidentifikationsnummern eines bestimmten Mercedes-Benz oder smart Fahrzeugs basieren. Bequem verfügbar, ohne das Fahrzeug bereits vor Ort zu haben.", 
        exampleTextBold: "Möchten Sie eine innovative Art und Weise sehen, wie Sie Ihren Bestand präsentieren können?", 
        exampleText: "Sehen Sie in unserer Vorschau, was Sie für Ihre Product-Keys bekommen können, um Ihre Kunden zu beeindrucken.",     
        exampleButton: "Zeige Fahrzeuge",
        producstKeyNeeded: "Produktschlüssel vorhanden?",
        purchaseThem: " Sie erhalten diese im",
        portal: "Portal",
        openingTextPartOne: `Willkommen im Mercedes-Benz Retailer Dashboard (vormals Vehicle Images Download Tool): `,
        openingTextPartOneBold: "Marketing-, Bild-& Videodaten für Ihre Lagerfahrzeuge!",
        openingTextPartTwo: "Profitieren Sie vom bequemen und schnellen Zugriff auf Marketingdaten und Original-Bilder von Mercedes-Benz und smart, vom Exterieur und Interieur über Videos bis hin zu Ausstattungsbildern.",
        retailerDashboardVideoText: "Retailer Dashboard: lerne alle Vorteile kennen.",
        headerBar: {
            mercedesBenzData: "MERCEDES-BENZ DATA",
            products: "PRODUKTE",
            contact: "KONTAKT",
            support: "SUPPORT",
            language: "Deutsch",
            dataLink: "https://data.mercedes-benz.com/de/produkte"
        },
        footerBar: {
            imprint: "Anbieter",
            legalNotice: "Rechtliche Hinweise",
            cookies: "Cookie Einstellungen",
            privacyStatement: "Datenschutz",
            termsOfUse: "Nutzungsbedingungen"
        },
        changeLanguage: "Klicken um Sprache auf English einzustellen",
    },
};