export default (obj, searchFilter) => {
    searchFilter.toLowerCase()
  
    let count = 0;
  
    //parser go around every nested object. Then, if there is a nested JSON Object in there, we should count them in order to make them visible in the JSON object 
    const _findCount = (object) => {
      if (typeof (object) === "object" && object !== null) {
        count += Object.keys(object).length;
        Object.keys(object)
        for (const [value] of Object.entries(object)) {
          _findCount(value)
        }
      }
    }
  
    const _searchJSON = (value, key, search) => { //recursive search
      for (let [tempKey, tempValue] of Object.entries(value)) {
        if (tempKey.toLowerCase().includes(search) && isNaN(tempKey)) { //if one of the key is not a null entry, then do not delete it from the JSON object
  
          return true
        }
        else if (typeof (tempValue) === "object" && tempValue !== null) {
          if (_searchJSON(tempValue, tempKey, search)) return true
        } // if the object is JSON, control their keys
        else if (typeof (tempValue) === "string") {
          if (tempValue.toLowerCase().includes(search)) return true
  
        }
      }
      return false; // Object do not found, can be deleted
    }
  
    return JSON.parse(JSON.stringify(obj, (key, value) => {
      if (count > 0) { // allow nested objects if the parent object is searched
        count -= 1;
        return value
      }
      if (key.toLowerCase().includes(searchFilter) && isNaN(key)) { // if json object key value is searched
        _findCount(value);
        return value
      }
      else if (typeof (value) === "object" && value !== null) { //if the object is JSON, search nested
        if (_searchJSON(value, key, searchFilter)) return value
      }
      else if (typeof (value) === "string") {
        if (value.toLowerCase().includes(searchFilter)) return value
  
      }
    }));
  }