export const modelMarketList = [
    {
        market_code: "en_AE",
        market_name: "AE (en)"
    },
    {
        market_code: "en_AQ",
        market_name: "AQ (en)"
    },
    {
        market_code: "es_AR",
        market_name: "AR (es)"
    },
    {
        market_code: "de_AT",
        market_name: "AT (de)"
    },
    {
        market_code: "en_AU",
        market_name: "AU (en)"
    },
    {
        market_code: "hr_BA",
        market_name: "BA (hr)"
    },
    {
        market_code: "fr_BE",
        market_name: "BE (fr)"
    },
    {
        market_code: "bg_BG",
        market_name: "BG (bg)"
    },
    {
        market_code: "pt_BR",
        market_name: "BR (pt)"
    },
    {
        market_code: "de_CH",
        market_name: "CH (de)"
    },
    {
        market_code: "cs_CY",
        market_name: "CY (cs)"
    },
    {
        market_code: "de_DE",
        market_name: "DE (de)"
    },
    {
        market_code: "da_DK",
        market_name: "DK (da)"
    },
    {
        market_code: "et_EE",
        market_name: "EE (et)"
    },
    {
        market_code: "en_EG",
        market_name: "EG (en)"
    },
    {
        market_code: "es_ES",
        market_name: "ES (es)"
    },
    {
        market_code: "fi_FI",
        market_name: "FI (fi)"
    },
    {
        market_code: "fr_FR",
        market_name: "FR (fr)"
    },
    {
        market_code: "en_GB",
        market_name: "GB (en)"
    },
    {
        market_code: "el_GR",
        market_name: "GR (el)"
    },
    {
        market_code: "en_HA",
        market_name: "HA (en)"
    },
    {
        market_code: "en_HK",
        market_name: "HK (en)"
    },
    {
        market_code: "hr_HR",
        market_name: "HR (hr)"
    },
    {
        market_code: "hu_HU",
        market_name: "HU (hu)"
    },
    {
        market_code: "en_ID",
        market_name: "ID (en)"
    },
    {
        market_code: "en_IE",
        market_name: "IE (en)"
    },
    {
        market_code: "en_IN",
        market_name: "IN (en)"
    },
    {
        market_code: "is_IS",
        market_name: "IS (is)"
    },
    {
        market_code: "it_IT",
        market_name: "IT (it)"
    },
    {
        market_code: "ja_JP",
        market_name: "JP (ja)"
    },
    {
        market_code: "en_KR",
        market_name: "KR (en)"
    },
    {
        market_code: "lt_LT",
        market_name: "LT (lt)"
    },
    {
        market_code: "de_LU",
        market_name: "LU (de)"
    },
    {
        market_code: "fr_LU",
        market_name: "LU (fr)"
    },
    {
        market_code: "lv_LV",
        market_name: "LV (lv)"
    },
    {
        market_code: "en_MT",
        market_name: "MT (en)"
    },
    {
        market_code: "en_MX",
        market_name: "MX (en)"
    },
    {
        market_code: "en_MY",
        market_name: "MY (en)"
    },
    {
        market_code: "nl_NL",
        market_name: "NL (nl)"
    },
    {
        market_code: "no_NO",
        market_name: "NO (no)"
    },
    {
        market_code: "en_NZ",
        market_name: "NZ (en)"
    },
    {
        market_code: "pl_PL",
        market_name: "PL (pl)"
    },
    {
        market_code: "pt_PT",
        market_name: "PT (pt)"
    },
    {
        market_code: "ro_RO",
        market_name: "RO (ro)"
    },
    {
        market_code: "hr_RS",
        market_name: "RS (hr)"
    },
    {
        market_code: "ru_RU",
        market_name: "RU (ru)"
    },
    {
        market_code: "sv_SE",
        market_name: "SE (sv)"
    },
    {
        market_code: "en_SG",
        market_name: "SG (en)"
    },
    {
        market_code: "sl_SI",
        market_name: "SI (sl)"
    },
    {
        market_code: "sk_SK",
        market_name: "SK (sk)"
    },
    {
        market_code:"en_SL",
        market_name:"SL (en)"
    },
    {
        market_code:"sl_SL",
        market_name:"SL (sl)"
    },
    {
        market_code: "en_TH",
        market_name: "TH (en)"
    },
    {
        market_code: "tr_TR",
        market_name: "TR (tr)"
    },
    {
        market_code: "en_TW",
        market_name: "TW (en)"
    },
    {
        market_code: "uk_UA",
        market_name: "UA (uk)"
    },
    {
        market_code: "en_VN",
        market_name: "VN (en)"
    },
    {
        market_code: "en_XA",
        market_name: "XA (en)"
    },
    {
        market_code: "en_XD",
        market_name: "XD (en)"
    },
    {
        market_code: "en_XH",
        market_name: "XH (en)"
    },
    {
        market_code: "en_ZA",
        market_name: "ZA (en)"
    },
    {
        market_code: "tr_ZY",
        market_name: "ZY (tr)"
    },
]