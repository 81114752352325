export const ImageTypesRes = {
    A4: "1000x295",
    P4: "1000x295",
    A13: "768x512",
    A14: "200x112",
    A22: "70x40",
    A23: "230x131",
    A24: "740x416",
    A25: "69x66",
    A26: "71x71",
    P26: "71x71",
    A27: "1920x1080",
    P27: "1920x1080",
    W27: "1920x1080",
    A28: "200x133",
    P29: "142x142",
    A30: "1024x576",
    A31: "138x132",
    A34: "800x600",
    P35: "710x710",
    P36: "272x153",
    P37: "590x332",
    P38: "696x392",
    P39: "1600x900",
    A40: "1920x1440",
    A41: "1200x900",
    A42: "1920x1080",
    A50: "1180x430",
    A51: "804x350",
    A52: "1500x1500",
    P52: "1500x1500"
}