import {
	dependencies as fossDependencies,
	generationTime,
	licenseTexts,
} from '../../Assets/fossModules';
import {
	WbGrid
} from '@workbench/react';
import './FossPage.scss';

function FossPage() {
	return (
		<WbGrid>
            <p data-label="foss-generation-time">
                {`Generated at: ${new Intl.DateTimeFormat().format(new Date(generationTime))}`}
            </p>
            <h2 className="subtitle">{"Components"}</h2>
            {fossDependencies.map(({ license, name, version, licenseHash }) => (
                <div key={`${name}${version}`} data-label="foss-dependency">
                    <p>
                        {name} {version}:
                        {licenseHash ? <a href={`#${licenseHash}`}> {license}</a> : <span>{license}</span>}
                    </p>
                </div>
            ))}

            <h2 id="licenses" className="subtitle">Licenses</h2>
            {Object.entries(licenseTexts).map(([key, { name: licenseName, text, dependencies }]) => (
                <div key={key}>
                    <h3 id={key}>{licenseName}</h3>
                    {"Used in: "}
                    <span>
                        {dependencies.map(({ name: dependencyName, version }) => (
                            <span key={`${dependencyName}${version}`}>{`${dependencyName}@${version}`}</span>
                        ))}
                    </span>
                    <p className="license-text">{text}</p>
                </div>
            ))}
		</WbGrid>
	);
}

export default FossPage;