import { Modal } from "antd";

const CustomModal = (props) => {
  const {
    modalType,
    key,
    title,
    okText,
    cancelText,
    onOk,
    onCancel,
    content,
  } = props;

  const modalFunctions = {
    info: Modal.info,
    success: Modal.success,
    error: Modal.error,
    warning: Modal.warning,
    confirm: Modal.confirm,
  };

  const showModalFunction = modalFunctions[modalType];

  if (!showModalFunction) {
    console.error(`Invalid modalType: ${modalType}`);
    return;
  }

  showModalFunction({
    key: key,
    title: title,
    content: content,
    okText: okText,
    cancelText: cancelText,
    onOk: onOk,
    onCancel: onCancel,
  });
};

export default CustomModal;
