import React, { createContext, useContext, useState, useMemo } from "react";

const ImageProviderContext = createContext();
export const useImageProviderContext = () => useContext(ImageProviderContext);

function ImageProvider({ children }) {
    const [imageList, setImageList] = useState({});
    const [apikey, setApikey] = useState("");
    const contextValue = useMemo(() => ({imageList, setImageList, apikey, setApikey}), [imageList, setImageList, apikey, setApikey])

    return (
        <ImageProviderContext.Provider value={contextValue}>
            {children}
        </ImageProviderContext.Provider>
    );
}

export default ImageProvider;
