import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import VideoJS from "./VideoJS";
import useWidth from "../Utils/useWidth";
import { useTranslation } from "react-i18next";
import i18n from "../Utils/i18n";
import { WbBreadcrumbs, WbBreadcrumb, WbHeading, WbText, WbButton } from '@workbench/react';
import { useHistory } from "react-router-dom";

const videoJsOptions_DE = {
  controls: true,
  responsive: true,
  fluid: true,
  sources: [{
    src: "Assets/retailer-dashboard-de.mp4",
    type: 'video/mp4'
  }]
};

const videoJsOptions_EN = {
  controls: true,
  responsive: true,
  fluid: true,
  sources: [{
    src: "Assets/retailer-dashboard-en.mp4",
    type: 'video/mp4'
  }]
};

const tabMap = ["images", "equipments", "videos", "specifications", "models"];

const RD_BASEPATH = window.RD_APP_URL || "https://retailer.data.mercedes-benz.com"

function Intro(props) {

  const { tab, handleTabUpdate } = props
  const width = useWidth()
  const history = useHistory();
  const [mobileState, setMobileState] = useState(false);
  const [videoOptions, setVideoOptions] = useState(videoJsOptions_EN);
  const { t } = useTranslation();

  useEffect(() => {
    i18n.language === "de" ? setVideoOptions(videoJsOptions_DE) : setVideoOptions(videoJsOptions_EN);
  }, [i18n.language])

  useEffect(() => {
    calculateScreenState();
  }, [width])

  const calculateScreenState = () => {
    if (width < 769 && !mobileState) {
      setMobileState(true)
    }
    else if (width >= 769 && mobileState) {
      setMobileState(false)
    }
  }

  function navigateToImages() {
    history.push("/home")
    handleTabUpdate(1);
  }

  function navigateToRetailerDashboard(){
    window.open(`${RD_BASEPATH}/demo`, '_blank');
  }


  return (
    <div className="IntroCard">
      <WbBreadcrumbs>
        <WbBreadcrumb onClick={navigateToImages}>Home</WbBreadcrumb>
        <WbBreadcrumb>{t(`tabs.${tabMap[tab - 1]}`)}</WbBreadcrumb>
      </WbBreadcrumbs>
      <div className="Intro">
        <Row justify="space-between" className="Intro-Container" gutter={[0, 16]}>
          <Col span={mobileState ? 24 : 12}>
            <WbHeading className="IntroHeading" size="xl">
              {t('mainScreen.intro')}
            </WbHeading>
            {mobileState && <div className="Video-Intro">
              <VideoJS options={videoOptions} />
              <div className="Video-Text">{t('mainScreen.retailerDashboardVideoText')}</div>
            </div>}
            <WbText className="Intro-Text" size="l">
              {t('mainScreen.openingTextPartTwo')}
            </WbText>
            <div className="Intro-Example-Text">
              <WbText strong size="l">{t('mainScreen.exampleTextBold')}</WbText>
              <WbText size="l">{t('mainScreen.exampleText')}</WbText>
            </div>
            <WbButton className="Intro-Example-Button" onClick={navigateToRetailerDashboard} variant="secondary">
              {t('mainScreen.exampleButton')}
            </WbButton>
          </Col>
          <Col span={mobileState ? 24 : 12}>
            <Row justify="end" >
                {!mobileState && <div className="Video-Intro">
                  <VideoJS options={videoOptions} />
                  <div className="Video-Text">{t('mainScreen.retailerDashboardVideoText')}</div>
                </div>}
            </Row>
          </Col>
        </Row>
      </div >
    </div >
  )
}

export default Intro;