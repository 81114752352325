import { useEffect, useState } from 'react'
import { Row, Col, Card, Divider, Popover } from 'antd'

function DescriptionDivider({description}) {
    if(!description?.text || !description?.code || description?.text === "N/A") return null;
    return <Divider style={{transform: "translateY(0%)"}} orientation="left"> {description.code} / {description.text} </Divider>
}

function EquipmentRenderList(props) {
    const { imageList, rowCount = 6 } = props;
    const [renderedList, setRenderedList] = useState([]);
    useEffect(() => {
        const tempList = [];
        for (let i = 0; i < imageList.length; i += rowCount) {
            tempList.push(imageList.slice(i, i + rowCount));
        }
        setRenderedList(tempList);
    }, [imageList, rowCount]);   

    return (
        <div>
            <Row className="ImageList">
                {imageList && renderedList.map((rowList, indexRow) => {
                    const id = rowList[indexRow]?.url?.split('/')?.pop()?.split('-')?.shift()
                    let description = rowList[0].description;
                    return (
                    <>
                        <DescriptionDivider description={description}></DescriptionDivider>
                        <Row key={id} gutter={16} style={{ width: "-webkit-fill-available" }} >
                            {rowList.map((key, indexCol) => {
                                const imageKey = `${key.title}-${indexCol}`;
                                return (
                                    <Col key={imageKey} span={24 / rowCount} >
                                        <ImageCard url={key.url} title={key.title} blob={key.blob} imageType={key.imageType} />
                                    </Col>
                                )
                            })}
                        </Row>

                    </>
                    )
                })}
            </Row>
        </div>
    )
}

function ImageCard(props) {
    const { url, title, imageType } = props;
    const download = () => {
        const link = document.createElement("a");
        link.href = url;
        link.download = `${title}.${imageType}`
        link.click();
    }
    return (
        <Popover overlayClassName='ImagePopOver' content={<Card className="ImageCard" bordered={false} >
            <img className="EquipmentCardUrl ImagePopover EquipmentPopover" src={url} alt={title} /></Card>}
            title={title} mouseEnterDelay="0.3">
            <Card className="ImageCard" onClick={() => download()} bordered={false}>
                <img className="EquipmentCardUrl" src={url} alt={title} />
                <div className="ImageCardTitle" >{title}</div>
            </Card>
        </Popover>
    )
}

export default EquipmentRenderList;