import { Card, Popover, Tooltip } from "antd";
import { useMemo, useState } from "react";
import FallbackImage from "../Assets/Images/fallback_vehicle.jpeg";
import FallbackImageNoBG from "../Assets/Images/fallback_vehicle_no_bg.jpeg";
import { useImageProviderContext } from "../Providers/ImageProvider";
import { _getImage } from "../Utils/apiCalls";
import { useTranslation } from "react-i18next";
import { convertImage } from "../Utils/convertImage";

function ImageCard({ vin, imageId, url, title, imageType, background, isDownload }) {
    const ErrorImage = useMemo(() => (background ? FallbackImage : FallbackImageNoBG), [background]);
    const { t } = useTranslation();

    const imageProvider = useImageProviderContext();
    const [isLoaded, setIsLoaded] = useState(false);
    const [status, setStatus] = useState("loaded");

    const download = () => {
        if (isDownload) {
            const link = document.createElement("a");
            link.href = url;
            link.download = `${title}.${imageType}`;
            link.click();
        }
    };

    const reloadImage = () => {
        setStatus("loading");
        if (imageId) {
            const params = { apikey: imageProvider.apikey, imageId };
            return _getImage(params).then((imageData) => {
                if (imageData) {
                    const image = convertImage(imageData.data);
                    imageProvider.setImageList((prev) => ({
                        ...prev,
                        [vin]: [...prev[vin]?.filter((e) => e.imageId !== imageId) || [], { imageId, title, ...image }],
                    }));
                    setTimeout(() => {
                        setStatus("loaded");
                    }, [1000]);                  
                }
            });
        } else {
            imageProvider.setImageList((prev) => {
                return {
                    ...prev,
                    [vin]: [...prev[vin]?.filter((e) => e.title !== title) || [], { title, url }],
                };
            });
            setTimeout(() => {
                setStatus("loaded");
            }, [1000]);
        }
    };

    return (
        <Card className="ImageCard" bordered={false}>
                {(status !== "loaded" || (!url && imageId)) ? (
                    <Tooltip title={t("imagesTab.reloadToClick")}>
                        <div
                            className={`ImageCardUrl ImageCardUrl--empty ${
                                status === "loading" ? `ImageCardUrl--loading` : ""
                            }`}
                            onClick={() => reloadImage()}
                            onKeyDown={() => reloadImage()}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path
                                    d="M12 4.895h-.011v-1.54a.25.25 0 0 0-.379-.214L7.359 5.688a.251.251 0 0 0 0 .429l4.251 2.568a.25.25 0 0 0 .379-.215V6.907L12 6.9a6 6 0 1 1-6 6H4a8 8 0 1 0 8-8z"
                                    fill="#fff"
                                />
                            </svg>
                        </div>
                    </Tooltip>
                ) : (
                    <Popover
                        overlayClassName="ImagePopOver"
                        content={
                            <Card className="ImageCard" bordered={false}>
                                <img
                                    className="ImageCardUrl ImagePopover"
                                    src={url || ErrorImage}
                                    style={isLoaded ? {} : { width: "15vw", height: "15vw" }}
                                    alt={title}
                                    onLoad={() => {
                                        setIsLoaded(true);
                                    }}
                                />
                            </Card>
                        }
                        title={title}
                        mouseEnterDelay="0.3"
                    >
                        <img
                            className="ImageCardUrl"
                            src={url || ErrorImage}
                            onClick={() => download()}
                            onKeyDown={() => download()}
                            onError={() => setStatus("error")}
                            alt={title}
                        />
                    </Popover>
                )}
                <div className="ImageCardTitle">{title}</div>
            </Card>
    );
}

export default ImageCard;
