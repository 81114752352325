import { useEffect, useState, useRef } from 'react'
import { Row, Col, Card, Select, Collapse, Progress, Divider, Tooltip, Space } from 'antd'

import CustomSwitch from './CustomSwitch'
import CustomButton from './CustomButton'
import CustomInput from './CustomInput'
import CustomSelect from './CustomSelect'
import CustomModal from './CustomModal';
import _notification from '../Utils/notification'
import _handleErrors from '../Utils/handleErrors'
import { marketList } from '../Constants/marketList'
import { _createMp4Video, _getMp4Video, _getVideo } from '../Utils/apiCalls'
import sampleLandscapeEn from '../Assets/ExampleVideos/sample-video-landscape_en.json';
import samplePortraitEn from '../Assets/ExampleVideos/sample-video-portrait_en.json';
import sampleLandscapeDe from '../Assets/ExampleVideos/sample-video-landscape_de.json';
import samplePortraitDe from '../Assets/ExampleVideos/sample-video-portrait_de.json';
import sampleVinVideoEn from '../Assets/ExampleVideos/vin-video_en.json';
import sampleVinVideoDe from '../Assets/ExampleVideos/vin-video_de.json';
import VideoAdjust from './VideoAdjust'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import PanelHeader from './PanelHeader'
import { useTranslation } from "react-i18next";
import saveJson from "../Utils/saveJson"

const { Panel } = Collapse;
const { Option } = Select;

function blingsProjectSettings (outputFormat) {
  return [
      {
          label: "productive published",
          settings: {
            project: {
              id : outputFormat ? "m-a7b2caa6-9374-4934-ac61-534e9ec3766a" : "a7b2caa6-9374-4934-ac61-534e9ec3766a",
            }
          }
      },
     {
          label: "productive non-published",
          settings: {
            project: {
              id : outputFormat ? "m-a7b2caa6-9374-4934-ac61-534e9ec3766a" : "a7b2caa6-9374-4934-ac61-534e9ec3766a",
              version: "latest"
            },
          }
      },
     {
          label: "test published",
          settings: {
            project: {
              id: outputFormat ? "tst-m-a7b2caa6-9374-4934-ac61-534e9ec3766a" : "tst-a7b2caa6-9374-4934-ac61-534e9ec3766a",
            }
          }
      },
      {
          label: "test non-published",
          settings: {
            project: {
              id: outputFormat ? "tst-m-a7b2caa6-9374-4934-ac61-534e9ec3766a" : "tst-a7b2caa6-9374-4934-ac61-534e9ec3766a",
              version: "latest"
            },
          }
      }
  ]
}

const equipmentCodeText = "e.g.: 235, 275, 294"

const mapVideoAdjustDataToSDKData = (videoAdjustData, initialVideoData) => {
  const updatedFields = Object.entries(videoAdjustData);
  const updatedData = {};
  const details = {};

  updatedFields.forEach((e, i) => {
    const key = e[0];
    const val = e[1];
    let tempVal;
    switch(key) {
      case 'endImageTransparent':
        if(val !== undefined && val !== '') {
          const regex = /POV=BE(\d{3})/;
          let newPers = val.match(regex)?.[0];
          if(newPers !== undefined && newPers !== '') {
            tempVal = initialVideoData?.endImageTransparent.replace(regex, newPers);
            updatedData[key] = tempVal;
          }
        }
        break;
      case 'logo':
      case 'qr':
      case 'endBkgImage':
        tempVal = val !== undefined && val !== '' ? (typeof val === "string" ? val : val?.url) : undefined;
        tempVal && (updatedData[key] = tempVal);
        break;
      case 'bgimage':
        details[key] = initialVideoData.details.bgimage;
        break;
      case 'title1':
      case 'text1':
      case 'title2':
      case 'text2':
      case 'title3':
      case 'text3':
      case 'title4':
      case 'text4':
      case 'textcolorblack':
        tempVal = val !== undefined && val !== '' ? val : undefined;
        tempVal && (details[key] = tempVal);
        break;
      case 'packages2':
      case 'packages3':
        let tempPackages = {};
        for(let pack in val) {
          pack !== "title" && (tempPackages[pack] = {});
          let tempItem;
          if(typeof val[pack] === 'object') {
            for(let item in val[pack]) {
              tempItem = val[pack][item] !== undefined && val[pack][item] !== '' ? val[pack][item] : undefined;
              tempItem && (tempPackages[pack][item] = tempItem);
            }
          } else {
            tempItem = val[pack] !== undefined && val[pack] !== '' ? val[pack] : undefined;
            tempItem && (tempPackages[pack] = tempItem);
          }
        }
        updatedData[key] = tempPackages;
        break;
      default:
        tempVal = val !== undefined && val !== '' ? val : undefined;
        tempVal && (updatedData[key] = tempVal);
        break;
    }
  })
  let newPackages2 = {...initialVideoData?.packages2};
  if(updatedData.packages2) {
    Object.entries(updatedData.packages2).forEach(([pack, item]) => {
      if(typeof newPackages2[pack] === "object") {
        newPackages2[pack] = {...newPackages2[pack], ...item};
      } else {
        newPackages2[pack] = item;
      }
    });
  }
  let newPackages3 = {...initialVideoData?.packages3};
  if(updatedData.packages3) {
    Object.entries(updatedData.packages3).forEach(([pack, item]) => {
      if(typeof newPackages3[pack] === "object") {
        newPackages3[pack] = {...newPackages3[pack], ...item};
      } else {
        newPackages3[pack] = item;
      }
    });
  }
  return {
    ...initialVideoData, ...updatedData, 
    details: {...initialVideoData.details, ...details}, 
    packages2: {...newPackages2}, 
    packages3: {...newPackages3}
  };
}


function Video(props) {

  const { width, videoImageList, setApikeyEntered, tabNumber, apikeyRef } = props

  // i18next translation
  const { i18n, t } = useTranslation();

  // Blings format check
  const isMobile = window.BlingsPlayer.helpers.utils.isMobile();

  // Local states
  const [apikey, setApikey] = useState(null);
  const [vin, setVin] = useState(null);
  const [latestVinUsed, setLatestVinUsed] = useState(false);
  const [latestEquipmentCode, setLatestEquipmentCode] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [prevNight, setPrevNight] = useState(null);
  const [prevRoof, setPrevRoof] = useState(null);
  const [prevFormat, setPrevFormat] = useState(null);
  const [marketId, setMarketId] = useState("de_DE");
  const [prevMarketId, setPrevMarketId] = useState("");
  const [initialVideoData, setInitialVideoData] = useState(null);
  const [videoAdjustData, setVideoAdjustData] = useState(null);
  const [latestVideoAdjustData, setLatestVideoAdjustData] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [isFilterDisabled, setIsFilterDisabled] = useState(true);
  const [isSample, setIsSample] = useState(false);
  const [mp4VideoLoading, setMp4VideoLoading] = useState(false);
  const [mp4VideoLoadingText, setMp4VideoLoadingText] = useState(t("videosTab.videoProcessingSection.processingFirstMessage"));
  const [mp4DisableButtons, setMp4DisableButtons] = useState(false);
  const [videoResolutionSetting, setVideoResolutionSetting] = useState(i18n.language === "en" ? "Medium" : "Mittel");

  // Video Scene Settings
  const [start, setStart] = useState(false);
  const [outro, setOutro] = useState(true);
  const [noSpec, setNoSpec] = useState(false);
  const [noEndScreen, setNoEndScreen] = useState(false);
  const [noTwoEquipmentScene, setNoTwoEquipmentScene] = useState(true);
  const [noThreeEquipmentScene, setNoThreeEquipmentScene] = useState(true);

  const [disableTwoEquipmentScene, setDisableTwoEquipmentScene] = useState(true);
  const [disableThreeEquipmentScene, setDisableThreeEquipmentScene] = useState(true);

  // Video Content Settings
  const [noTouch, setNoTouch] = useState(false);
  const [prevNoTouch, setPrevNoTouch] = useState(false);
  const [sound, setSound] = useState(true);
  const [night, setNight] = useState(false);
  const [equipmentCodes, setEquipmentCodes] = useState()
  const [roofOpen, setRoofOpen] = useState(false);

  const [outputFormat, setOutputFormat] = useState(isMobile);

  const [imageVideo, setImageVideo] = useState(false);


  // Video Player Settings
  const [noAutoPlay, setNoAutoPlay] = useState(true);
  const [noAutoReplay, setNoAutoReplay] = useState(true);

  // MP4 Video Settings
  const [videoFormat, setVideoFormat] = useState(true);

  const [currentVideoType, setCurrentVideoType] = useState(outputFormat);

  const isImageVideo = useRef(false);
  const isSampleVideo = useRef(false);

  const twoEquipmentRef = useRef(false);
  const threeEquipmentRef = useRef(false);
  const [mp4VideoPercent, setMp4VideoPercent] = useState(0);

  // Modal logic related states
  const [firstRequest, setFirstRequest] = useState(true);
  const [configurationSaved, setConfigurationSaved] = useState(false);

  // Blings Non-productive settings
  const [nonProdBlingsSettings, setNonProdBlingsSettings] = useState(JSON.stringify(blingsProjectSettings(outputFormat)[0].settings));

  useEffect(() => {
    // Solves the dropdown defaultValue bug on language change
    i18n.language === "en" ? setVideoResolutionSetting("Medium") : setVideoResolutionSetting("Mittel");
  }, [i18n.language]);

  useEffect(() => {
    if(apikey){
      apikeyRef.current[tabNumber-1] = 1
      setApikeyEntered((prev => {
        let newApikeyEntered = [...prev];
        newApikeyEntered[tabNumber - 1] = 1;
        return newApikeyEntered;
      }))
    } else {
      apikeyRef.current[tabNumber-1] = 0
      setApikeyEntered((prev) => {
        let newApikeyEntered = [...prev];
        newApikeyEntered[tabNumber - 1] = 0;
        return newApikeyEntered;
      });
    }
  }, [apikey])

  const showVideoAdjust = !start || !outro || !noSpec || !noEndScreen

  const resolutionOptions = [
    {
      name: t("videosTab.videoOutputFormatSection.videoResolutionSettings.videoResolutionVeryLow"),
      value: t("videosTab.videoOutputFormatSection.videoResolutionSettings.videoResolutionVeryLow"),
      imageResValue: 1
    },
    {
      name: t("videosTab.videoOutputFormatSection.videoResolutionSettings.videoResolutionLow"),
      value: t("videosTab.videoOutputFormatSection.videoResolutionSettings.videoResolutionLow"),
      imageResValue: 2
    },
    {
      name: t("videosTab.videoOutputFormatSection.videoResolutionSettings.videoResolutionMedium"),
      value: t("videosTab.videoOutputFormatSection.videoResolutionSettings.videoResolutionMedium"),
      imageResValue: 3
    },
    {
      name: t("videosTab.videoOutputFormatSection.videoResolutionSettings.videoResolutionHigh"),
      value: t("videosTab.videoOutputFormatSection.videoResolutionSettings.videoResolutionHigh"),
      imageResValue: 4
    },
    {
      name: t("videosTab.videoOutputFormatSection.videoResolutionSettings.videoResolutionVeryHigh"),
      value: t("videosTab.videoOutputFormatSection.videoResolutionSettings.videoResolutionVeryHigh"),
      imageResValue: 5
    }
  ]

  // Data for the tables
  const videoAdjustDataTable = [
    {
      key: 1,
      type: t("components.autoPlay"),
      desc: t("videosTab.editSceneTablesPopover.videoAdjustDataTable.autoPlay"),
      default: t("components.offButton")
    }, {
      key: 2,
      type: t("components.autoReplay"),
      desc: t("videosTab.editSceneTablesPopover.videoAdjustDataTable.autoReplay"),
      default: t("components.offButton")
    }
  ]

  const videoContentDataTable = [
    {
      key: 1,
      type: t("components.touchDevice"),
      desc: t("videosTab.editSceneTablesPopover.videoContentDataTable.touchDevice"),
      default: t("components.onButton")
    },
    {
      key: 2,
      type: t("components.muteAudio"),
      desc: t("videosTab.editSceneTablesPopover.videoContentDataTable.muteAudio"),
      default: t("components.offButton")
    },
    {
      key: 3,
      type: t("components.light"),
      desc: t("videosTab.editSceneTablesPopover.videoContentDataTable.light"),
      default: "On"
    },
    {
      key: 4,
      type: t("components.outputFormat"),
      desc: t("videosTab.editSceneTablesPopover.videoContentDataTable.outputFormat"),
      default: "On"
    },
    {
      key: 5,
      type: t("components.selectMarket"),
      desc: t("videosTab.editSceneTablesPopover.videoContentDataTable.selectMarket"),
      default: "de_DE"
    },
    {
      key: 6,
      type: t("components.equipmentCodesPlaceholder"),
      desc: t("videosTab.editSceneTablesPopover.videoContentDataTable.equipmentCodes"),
      default: "Null"
    }
  ]

  const videoScenesDataTable = [
    {
      key: 1,
      type: t("components.startScene"),//"Start Scene",
      desc: t("videosTab.editSceneTablesPopover.videoScenesDataTable.startScene"),
      default: "On"
    },
    {
      key: 2,
      type: t("components.specScene"),//"Spec Scene",
      desc: t("videosTab.editSceneTablesPopover.videoScenesDataTable.specScene"),
      default: "On"
    },
    {
      key: 3,
      type: t("components.twoEquipmentsScene"),//"2-equipments Scene",
      desc: t("videosTab.editSceneTablesPopover.videoScenesDataTable.twoEquipmentsScene"),
      default: "Off"
    },
    {
      key: 4,
      type: t("components.threeEquipmentsScene"),//"3-equipments Scene",
      desc: t("videosTab.editSceneTablesPopover.videoScenesDataTable.threeEquipmentsScene"),
      default: "Off"
    }, {
      key: 5,
      type: t("components.outroScene"),//"Outro Scene",
      desc: t("videosTab.editSceneTablesPopover.videoScenesDataTable.outroScene"),
      default: "On"
    }, {
      key: 6,
      type: t("components.endScene"),//"End Scene",
      desc: t("videosTab.editSceneTablesPopover.videoScenesDataTable.endScene"),
      default: "On"
    },
  ]

  const videoOutputSettingsDataTable = [
    {
      key: 1,
      type: t("videosTab.videoOutputFormatSection.outputFormat"),
      desc: t("videosTab.editSceneTablesPopover.videoOutputSettingsDataTable.outputFormat"),
      default: t("videosTab.editSceneTablesPopover.videoOutputSettingsDataTable.outputFormatDefault")
    },
    {
      key: 2,
      type: t("videosTab.videoOutputFormatSection.videoResolution"),
      desc: t("videosTab.editSceneTablesPopover.videoOutputSettingsDataTable.videoResolution"),
      default: t("videosTab.videoOutputFormatSection.videoResolutionSettings.videoResolutionMedium")
    },
  ]

  const getAllScenes = () => {
    let allScenes = [];
    
    if (isImageVideo.current) {
      allScenes = [...allScenes, "startNoSpec", "outroNoEnd", "endScreen"];
    } else if (isSampleVideo.current) {
      allScenes = [...allScenes, "startNoSpec", "spec", "pack3short", "outroNoEnd", "endScreen"];
    } else {
      if (!start) allScenes.push("startNoSpec");
      if (!noSpec) allScenes.push("spec");
      if (!twoEquipmentRef.current) allScenes.push("pack2short")
      if (!threeEquipmentRef.current) allScenes.push("pack3short")
      if (!outro) allScenes.push("outroNoEnd");
      if (!noEndScreen) allScenes.push("endScreen");
    }

    return allScenes;
  }

  const handleEquipmentCodes = (equipmentCodes) => {
    let pack2code = [];
    let pack3code = [];
    let packType = 0;

    if(!equipmentCodes) return [pack2code, pack3code, packType];

    const equipmentCodesArray = equipmentCodes.split(',')
  
    switch(equipmentCodesArray.length) {
      case 2:
        twoEquipmentRef.current = false
        threeEquipmentRef.current = true
        pack2code = equipmentCodesArray;
        packType = 2;
        break;
      case 3:
        twoEquipmentRef.current = true
        threeEquipmentRef.current = false
        pack3code = equipmentCodesArray;
        packType = 3;
        break;
      default:
    }

    return [pack2code, pack3code, packType];
  }

  const handleOutputFormatChange = (outputFormat) => {
    setOutputFormat(outputFormat);
    const prevSettings = blingsProjectSettings(!outputFormat);
    let settingsIndex;
    prevSettings.map((s,i) => {
      if(JSON.stringify(s.settings) === nonProdBlingsSettings) {
        settingsIndex = i;
      }
      return s;
    })
    const newSetting = blingsProjectSettings(outputFormat)[settingsIndex];
    setNonProdBlingsSettings(JSON.stringify(newSetting.settings));
  }

  
  async function createVinVideo() {
    if (!apikey || !vin) {
      console.log("empty apikey and/or vin");
      return;
    }
    setImageVideo(false);
    setIsSample(false);
    setShowSaveButton(true)
    setVideoLoading(true)
    setIsVideoLoaded(false)

    twoEquipmentRef.current = noTwoEquipmentScene
    threeEquipmentRef.current = noThreeEquipmentScene

    const video_settings = {
      container: document.getElementById('video-container'),
      showTimeline: true,
      posterFrame: 3,
      cinematic: false,
      hideFullScreenBtn: false,
      loaderColor: '#e1ccdc',
      ctrlBtnsColor: '#e1ccdc',
      muted: !sound,
      autoplay: !noAutoPlay,
      autoReplay: !noAutoReplay,
    };

    const tempNight = night
    const tempNoTouch = noTouch
    const tempMarketID = marketId
    const tempRoofOpen = roofOpen
    const tempOutputFormat = outputFormat

    setCurrentVideoType(tempOutputFormat)

    if (checkParameterChanged()) {
      setDisableThreeEquipmentScene(true)
      setDisableTwoEquipmentScene(true)
      
      if (equipmentCodes && !validateEquipmentCode(equipmentCodes)) {
        _notification({ title: t("notification.error"), message: t("notification.equipmentCodeCount"), duration: 10 });
        setVideoLoading(false);
        return
      }

      let [pack2code, pack3code, packType] = handleEquipmentCodes(equipmentCodes)

      const params = {
        vin: vin,
        apikey: apikey,
        marketId: marketId,
        pack2code: pack2code,
        pack3code: pack3code,
        night: night,
        isTouch: !noTouch,
        roofOpen: roofOpen,
        outputFormat: transformOutputFormat(outputFormat)
      }

      try {
        let { data } = await _getVideo(params)

        setInitialVideoData({
          ...data,
          qr: noTouch ? data.qr : "",
          packages2: packType === 2 ? data.packages2 : {},
          packages3: packType === 3 ? data.packages3 : {}
        });

        //skiped to fill "Edit Video Scene" fields with new video data as default
        setVideoAdjustData(null);
        if (false && videoAdjustData) { 
          let d = { ...videoAdjustData };
          if(packType === 0) {
            d.packages2 = null;
            d.packages3 = null;
            twoEquipmentRef.current = true;
            threeEquipmentRef.current = true;
          } else if(packType === 2) {
            d.packages3 = null;
            d.packages2 = data.packages2;
            twoEquipmentRef.current = false;
            threeEquipmentRef.current = true;
          } else if(packType === 3) {
            d.packages2 = null;
            d.packages3 = data.packages3;
            twoEquipmentRef.current = true;
            threeEquipmentRef.current = false;
          }
          data = { ...data, ...mapVideoAdjustDataToSDKData(d, data) };
          setVideoAdjustData((prev) => {
            let newState = {...prev}
            if (packType === 2 && (prev.packages2 === null || prev.packages2 !== data.packages2)) {
              newState.packages2 = data.packages2
            } 
            if (packType === 3 && (prev.packages3 === null || prev.packages3 !== data.packages3)) {
              newState.packages3 = data.packages3
            } 
            if (prev.qr === null && noTouch) {
              newState.qr = data.qr
            } 
            return newState;            
          })
        }

        data.isTouch = !noTouch;
        setVideoData(data)
        makeVideo(data, video_settings, tempOutputFormat)
        _notification({ title: t("notification.info"), message: t("notification.videoCompleted", { vin: vin, marketID: tempMarketID }), duration: 10 });
        
        //Save current switch state for requesting with new parameters
        setLatestVinUsed(vin)
        setPrevNight(tempNight)
        setPrevRoof(tempRoofOpen)
        setPrevNoTouch(tempNoTouch)
        setPrevMarketId(tempMarketID)
        setLatestEquipmentCode(equipmentCodes)
        setPrevFormat(tempOutputFormat)
        setIsFilterDisabled(false)

        handleEquipmentSwitch(packType) // PLACE HERE // handleEquipmentSwitch(tempPackType)
      }
      catch (err) {
        _notification({ title: t("notification.error"), message: _handleErrors(err, vin) })
        const errorMessage = err.message || err;
        console.log(errorMessage);

        setLatestVinUsed(null);
        setPrevNight(null);
        setPrevMarketId(null);
        setPrevNoTouch(null);

        if (isVideoLoaded) {
          destroyVideo();
        }

        setIsFilterDisabled(true)
        setVideoLoading(false);
        setShowSaveButton(false);
      }

    } else {
      setIsFilterDisabled(false);
      handleVideoAdjust(video_settings, tempOutputFormat);
    }
  }

  const validateEquipmentCode = (equipmentCodes) => {
    const packcodes = equipmentCodes.split(",");
    const pack3codeRegex = /^[a-zA-Z0-9]{3}$/;
    const equipmentCodesLength = packcodes.filter(code => (code.match(pack3codeRegex))).length;
    return equipmentCodesLength === 3 || equipmentCodesLength === 2
  }

  const handleEquipmentCodeChange = (e) => {
    const equipmentCodes = e.target.value?.replace(/\s+/g, '').toUpperCase();
    setEquipmentCodes(equipmentCodes)
  }

  const handleVideoAdjust = (video_settings, tempOutputFormat) => {
    try {
      if (videoAdjustData) {
        const changedData = { ...videoData, ...mapVideoAdjustDataToSDKData(videoAdjustData, initialVideoData) };
        setLatestVideoAdjustData(videoAdjustData);
        changedData.isTouch = !noTouch;
        setVideoData(changedData);
        makeVideo(changedData, video_settings, tempOutputFormat)
      } else {
        initialVideoData.isTouch = !noTouch;
        makeVideo(initialVideoData, video_settings, tempOutputFormat)
      }
    } catch (err) {
      _notification({ title: t("notification.error"), message: _handleErrors(err) })
      const errorMessage = err.message || err;
      console.log(errorMessage);
    }
  }


  const saveVinVideo = () => {
    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(videoData, null, 4));
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute("href", dataStr);
    downloadLink.setAttribute("download", `video_${vin}.json`);
    downloadLink.click();
  }

  const saveVinVideoMP4 = () => {
    setMp4VideoLoading(true);
    setMp4VideoLoadingText(t("videosTab.videoProcessingSection.processingFirstMessage"));
    setMp4DisableButtons(true);
    setMp4VideoPercent(0);

    let mp4VideoData = { ...window.player.videoData };
    const allScenes = getAllScenes();

    const currentResolution = resolutionOptions.find(option => option.value === videoResolutionSetting).imageResValue;

    const params = {
      apikey: apikey,
      videoData: mp4VideoData,
      scenes: allScenes,
      name: vin,
      resolution: currentResolution,
      outputFormat: transformOutputFormat(currentVideoType)
    }

    _createMp4Video(params).then(r => {
      console.log(r);
      if (r.status === 200) {
        let interval = setInterval(() => getVideoStatus(r, interval), 5000);
      } else {
        _notification({ title: t("notification.error"), message: t("notification.notCreated") })
        setMp4VideoLoading(false);
        setMp4DisableButtons(false);
      }
    }).catch(err => {
      _notification({ title: t("notification.error"), message: t("notification.notCreated") })
      setMp4VideoLoading(false);
      setMp4DisableButtons(false);
      _notification({ title: t("notification.error"), message: _handleErrors(err) })
    });

  }

  const getVideoStatus = (r, interval) => {
    const params = {
      apikey: apikey,
      id: r.data.videoId,
      outputFormat: transformOutputFormat(outputFormat)
    };

    _getMp4Video(params).then(r => {
      let percentAsString = r.data.ready;
      let percentAsInt = parseInt(percentAsString.substr(0, percentAsString.length - 1));

      setMp4VideoPercent(percentAsInt);

      switch (r.data.code) {
        case "2":
          setMp4VideoLoadingText(t("videosTab.videoProcessingSection.processingSecondMessage"));
          break;
        case "3":
          setMp4VideoLoading(false);
          setMp4DisableButtons(false);
          clearInterval(interval);

          const downloadLink = document.createElement('a');
          downloadLink.setAttribute("href", r.data.downloadLink);
          downloadLink.setAttribute("download", `${vin}.mp4`);
          downloadLink.click();
          downloadLink.remove();
          break;
        default:
          break;
      }

    }).catch(err => {
      _notification({ title: t("notification.error"), message: t("notification.notCreated") })
      setMp4VideoLoading(false);
      setMp4DisableButtons(false);
      clearInterval(interval);
      _notification({ title: t("notification.error"), message: _handleErrors(err) })
    });
  };

  const saveImage = () => {

    const currentResolution = resolutionOptions.find(option => option.value === videoResolutionSetting).imageResValue;

    let fileName = `${vin}-${window.player.animation.currentFrame}`;

    window.player.downloadFrame({ res: currentResolution * 0.2, frame: window.player.animation.currentFrame, fileName: fileName });
  }

  const makeVideo = (response, video_settings, video_format) => {
    if (isVideoLoaded) {
      destroyVideo();
      setIsVideoLoaded(false);
    }

    let allScenes = getAllScenes();

    if (allScenes.length === 0) {
      _notification({ title: t("notification.error"), message: t("notification.videoEmptyScene"), duration: 10 });
      setVideoLoading(false);
      setIsVideoLoaded(false);
      return
    }
    let o = response;
    let s = video_settings;
    let blingsSettings = {
        project: {
          id: getProjectID(video_format)
        },
        data: o,
        settings: s,
        scenes: allScenes
    }

    if(nonProdBlingsSettings) {
      JSON.parse(nonProdBlingsSettings)
      blingsSettings = {
        ...blingsSettings,
        ...JSON.parse(nonProdBlingsSettings)
      } 
    }

    window.BlingsPlayer.create(blingsSettings).then(function (player) {
      window.player = player;
    })

    setTimeout(() => {
      let container = document.getElementById('video-container');
      let parent = document.querySelector('#VideoArea');
      if (parent) {
        parent.appendChild(container);
        container.style.visibility = 'visible';
        container.style.marginBottom = '80px';
      }
      setVideoLoading(false);
      setIsVideoLoaded(true);
      setFirstRequest(false);
    }, 1500);
  
  }

  const handleEquipmentSwitch = (packType) => {

    if (packType === 2) {
      setDisableThreeEquipmentScene(true)
      setNoThreeEquipmentScene(true)
      setDisableTwoEquipmentScene(false)
      setNoTwoEquipmentScene(false)
    } else if (packType === 3) {
      setDisableTwoEquipmentScene(true)
      setNoTwoEquipmentScene(true)
      setDisableThreeEquipmentScene(false)
      setNoThreeEquipmentScene(false)
    } else {
      setNoTwoEquipmentScene(true)
      setNoThreeEquipmentScene(true)
      setDisableThreeEquipmentScene(true)
      setDisableTwoEquipmentScene(true)
    }
  }

  const getProjectID = (outputFormat) => {
    return outputFormat ? "m-a7b2caa6-9374-4934-ac61-534e9ec3766a" : "a7b2caa6-9374-4934-ac61-534e9ec3766a"
  }

  const transformOutputFormat = (outputFormat) => {
    return outputFormat ? "portrait" : "landscape"
  }

  const classSelector = (outputFormat) => {
    return outputFormat ? "video-mobile" : "video-standard"
  }

  const destroyVideo = () => {
    window.player.destroy();
  }

  const createSampleVideo = () => {
    setIsSample(true);
    isSampleVideo.current = true;
    setVideoLoading(true);
    setShowSaveButton(false);
    
    let video_settings = {
      container: document.getElementById('video-container'),
      showTimeline: true,
      posterFrame: 3,
      cinematic: false,
      hideFullScreenBtn: false,
      loaderColor: '#e1ccdc',
      ctrlBtnsColor: '#e1ccdc',
      muted: !sound,
      autoplay: !noAutoPlay,
      autoReplay: !noAutoReplay,
    }
    let data = null;
    const lang = i18n.language;
    const tempOutputFormat = outputFormat;
    
    if (lang === "de") {
      if(outputFormat) {
        data = samplePortraitDe;
      } else {
        data = sampleLandscapeDe;
      }
    } else if (outputFormat) {
        data = samplePortraitEn;
    } else {
      data = sampleLandscapeEn;
    }

    data.isTouch = !noTouch;
    setIsFilterDisabled(true)
    setCurrentVideoType(tempOutputFormat)
    makeVideo(data, video_settings, tempOutputFormat);
    isSampleVideo.current = false;
  }

  const createImageVideo = () => {
    if (videoImageList.length < 11) {
      _notification({ title: t("notification.error"), message: t("notification.videoNonSufficentImage"), duration: 10 });
      return;
    }
    isImageVideo.current = true;
    setVideoLoading(true);
    setShowSaveButton(false);
    setImageVideo(true);
  
    let video_settings = {
      container: document.getElementById("video-container"),
      showTimeline: true,
      posterFrame: 3,
      cinematic: false,
      hideFullScreenBtn: false,
      loaderColor: '#e1ccdc',
      ctrlBtnsColor: '#e1ccdc',
      muted: !sound,
      autoplay: !noAutoPlay,
      autoReplay: !noAutoReplay,
    }
  
    let data = i18n.language === "de" ? sampleVinVideoDe : sampleVinVideoEn;
    const tempOutputFormat = false;
  
    const findImageByTitle = title => videoImageList.find(i => i.title === title)?.url || "";
  
    const intTitles = ["INT1", "INT2", "INT3", "INT4"];
    const extTitles = videoImageList.length < 30 
                      ? ["EXT000", "EXT090", "EXT150", "EXT180", "EXT270", "EXT330"]
                      : ["EXT300", "EXT310", "EXT320", "EXT330", "EXT340", "EXT350"];
  
    intTitles.forEach((title, index) => data.interiorImages[index + 1] = findImageByTitle(title));
    extTitles.forEach((title, index) => data.vehicleRotationImages[index + 1] = findImageByTitle(title));
  
    data.scene1image = findImageByTitle(videoImageList.length < 30 ? "EXT000" : "EXT030");
    data.endImageTransparent = findImageByTitle(videoImageList.length < 30 ? "EXT330" : "EXT3");
  
    data.endBkgImage = `${window.location.origin}/Assets/bg-day-4-3.webp`;
    setCurrentVideoType(tempOutputFormat);
    makeVideo(data, video_settings, tempOutputFormat);
    isImageVideo.current = false;
  }

  const confirmMP4Download = () => {
    CustomModal({
      modalType: "confirm",
      key: 1,
      title: t("videosTab.videoConfirmationSection.confirmationOnContinueMessage"),
      icon: <ExclamationCircleOutlined />,
      content: t("videosTab.videoConfirmationSection.confirmationOnContinueContent"),
      okText: t("videosTab.videoConfirmationSection.confirmationOnContinueOkText"),
      cancelText: t("videosTab.videoConfirmationSection.confirmationOnContinueCancelText"),
      onOk: saveVinVideoMP4
    });
  };

  const checkParameterChanged = () => {
    return vin !== latestVinUsed || prevNight !== night || prevMarketId !== marketId || latestEquipmentCode !== equipmentCodes || prevNoTouch !== noTouch || prevRoof !== roofOpen || prevFormat !== outputFormat;
  }

  async function createDifferentVinVideo() {
    await new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
      createVinVideo();
    });
    if(videoAdjustData && (JSON.stringify(latestVideoAdjustData) !== JSON.stringify(videoAdjustData))){
      setLatestVideoAdjustData(videoAdjustData);
    }
    if(!checkParameterChanged()){
      return;
    } else {
      CustomModal({
        modalType: "info",
        key: 3,
        title: t("notification.information"),
        icon: <ExclamationCircleOutlined />,
        content: t("videosTab.videoConfirmationSection.confirmationCheckDialog"),
        okText: t("videosTab.videoConfirmationSection.confirmationOnContinueOkText"),
      })
    }
  }

  const saveConfigurationsWithConfirmation = () => {
    saveJson(latestVideoAdjustData || initialVideoData, "mb-video-settings");
    setConfigurationSaved(true);
  }

  const checkAgainstSave = () => {
    return checkParameterChanged() || ((JSON.stringify(latestVideoAdjustData) !== JSON.stringify(videoAdjustData)) && configurationSaved);
  }

  const createVinVideoModal = () => {
    if(firstRequest){
      createVinVideo();
    } else {
      if(!checkAgainstSave()) {
        createDifferentVinVideo();
      } else {
        CustomModal({
          modalType: checkParameterChanged() ? "info" : "confirm",
          key: 2,
          title: t("notification.information"),
          icon: <ExclamationCircleOutlined />,
          content: checkParameterChanged() ? t("videosTab.videoConfirmationSection.confirmationOnReloadData", {vin: vin}) : t("videosTab.videoConfirmationSection.confirmationOnSaveConfiguration", {vin: vin}),
          okText: checkParameterChanged() ? t("videosTab.videoConfirmationSection.confirmationOnContinueOkText") : t("videosTab.videoConfirmationSection.confirmationYesButton"),
          cancelText: checkParameterChanged() ? t("videosTab.videoConfirmationSection.confirmationOnContinueCancelText") : t("videosTab.videoConfirmationSection.confirmationNoButton"),
          onOk: async () => {
            createDifferentVinVideo();
            !checkParameterChanged() && saveConfigurationsWithConfirmation();
          },
          onCancel: () => {
            setConfigurationSaved(false);
            createDifferentVinVideo();
          }
        });
      }
    }
  };

  return (
    <div className="VideoTab videoPanel">
      <Card className="PackagePanelInfo" bordered={false}>

        <Row>
          <Col className="DownloadModels">
            {t("videosTab.downloadText")}
          </Col>
        </Row>

        <div className="InputPackage">
          <Row gutter={20} style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "64px", marginTop: "-7px" }}>
            <Col flex={width < 769 ? "auto" : "2"}><CustomInput label={t("placeholders.apiKeyPlaceholder")} getValue={setApikey} password={true} /></Col>
            <Col flex={width < 769 ? "auto" : "2"} ><CustomInput label={t("placeholders.vinNumberPlaceholder")} getValue={setVin} /></Col>
          </Row>

          <Collapse
            ghost={false}
            className="VideoSettingsCollapse"
            expandIconPosition="start"
            key="PlayerSettings"
          >
            <Panel key={1} header={<PanelHeader headerName={t("components.videoPlayerSettings")} description={t("videosTab.editSceneTablesPopover.videoAdjustText")} data={videoAdjustDataTable} width={width} />} className="VideoPanelSettings">
              <Row justify={width < 728 ? "center" : "start"} className="VideoSwitchGroup" gutter={[24,10]}>
                <Col><CustomSwitch label={t("components.autoPlay")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setNoAutoPlay} defaultValue={noAutoPlay} /></Col>
                <Col><CustomSwitch label={t("components.autoReplay")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setNoAutoReplay} defaultValue={noAutoReplay} /></Col>
              </Row>
            </Panel>

            <Panel key={2} header={<PanelHeader headerName={t("components.videoContentSettings")} description={t("videosTab.editSceneTablesPopover.videoContentText")} data={videoContentDataTable} width={width} />} className="VideoPanelSettings">
              <Row align="middle" justify={width < 728 ? "center" : "start"} className="VideoSwitchGroup" gutter={[24,10]}>
                <Col><CustomSwitch label={t("components.touchDevice")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setNoTouch} defaultValue={noTouch} /></Col>
                <Col><CustomSwitch label={t("components.muteAudio")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setSound} defaultValue={sound} /></Col>
                <Col><CustomSwitch label={t("components.light")} onBtn={t("components.dayLight")} offBtn={t("components.nightLight")} status={[t("components.dayLight"), t("components.nightLight")]} getValue={setNight} defaultValue={night} /></Col>
                <Col><CustomSwitch label={t("components.roof")} onBtn={t("components.roofClosed")} offBtn={t("components.roofOpen")} status={[t("components.roofClosed"), t("components.roofOpen")]} getValue={setRoofOpen} defaultValue={roofOpen} /></Col>
                <Col><CustomSwitch label={t("components.outputFormat")} onBtn={t("components.outputLandscape")} offBtn={t("components.outputPortrait")} status={[t("components.onButton"), t("components.offButton")]} getValue={handleOutputFormatChange} defaultValue={outputFormat} /></Col>
                <Col>
                  <div className='CustomSwitchLabel2'> {t("components.selectMarket")}
                    <Select
                      showSearch
                      size={"large"}
                      onChange={value => {
                        setMarketId(value)
                      }}
                      value={marketId}
                      defaultValue={"de_DE"}
                      listHeight={192}
                      style={{ width: 130, margin: 10 }}
                      options={marketList.map(market => ({ label: market.market_name, value: market.market_code }))} />
                  </div>
                </Col>
                {width > 2030 && <Tooltip title={equipmentCodeText}><Col className="VideoCustomInput VideoFixedInput"><CustomInput flex="auto" className="VideoFixedInput" name="packs" onChange={handleEquipmentCodeChange} val={equipmentCodes} label={t("components.equipmentCodesPlaceholder")} /></Col></Tooltip>}
              </Row>
              {width <= 2030 && <div>
                <Divider />
                <Row gutter={[20, 20]} className="VideoSwitchGroup">
                  <Tooltip title={equipmentCodeText}><Col span={width > 710 ? 12 : 24} className="VideoCustomInput"><CustomInput name="packs" onChange={handleEquipmentCodeChange} val={equipmentCodes} label={t("components.equipmentCodesPlaceholder")} /></Col></Tooltip>
                </Row>
              </div>}
            </Panel>

            <Panel key={3} header={<PanelHeader headerName={t("components.videoSceneSettings")} description={t("videosTab.editSceneTablesPopover.videoSceneText")} data={videoScenesDataTable} width={width} title={t("components.videoSceneSettings")} />} className="VideoPanelSettings">
              <Row justify={width < 728 ? "center" : "start"} className="VideoSwitchGroup" gutter={[24,10]}>
                <Col><CustomSwitch label={t("components.startScene")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setStart} defaultValue={start} /></Col>
                <Col><CustomSwitch label={t("components.specScene")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setNoSpec} defaultValue={noSpec} /></Col>
                <Col><CustomSwitch label={t("components.twoEquipmentsScene")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} disabled={disableTwoEquipmentScene} getValue={setNoTwoEquipmentScene} defaultValue={noTwoEquipmentScene} val={noTwoEquipmentScene} /> </Col>
                <Col><CustomSwitch label={t("components.threeEquipmentsScene")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} disabled={disableThreeEquipmentScene} getValue={setNoThreeEquipmentScene} defaultValue={noThreeEquipmentScene} val={noThreeEquipmentScene} /></Col>
                <Col><CustomSwitch label={t("components.outroScene")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setOutro} defaultValue={outro} /></Col>
                <Col><CustomSwitch label={t("components.endScene")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setNoEndScreen} defaultValue={noEndScreen} /></Col>

                <Col span={24}>
                  <div className="videoAdjust">
                    {isVideoLoaded && showVideoAdjust && !isSample && !isMobile && <VideoAdjust
                      configurationSaved={configurationSaved}
                      setConfigurationSaved={setConfigurationSaved}
                      videoAdjustData={videoAdjustData}
                      setVideoAdjustData={setVideoAdjustData}
                      initialVideoData={initialVideoData}
                      isDisabled={isFilterDisabled}
                      scenes={{ start, outro, noSpec, noEndScreen, noTwoEquipmentScene, noThreeEquipmentScene }}
                      width={width} />}
                  </div>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
    
        <Col span={24} className="ButtonPackage" >
          <Row gutter={[16,16]} justify={{xs: "end", sm: "end"}} align="middle" >
            {(window.REACT_APP_ENVIRONMENT === 'DEVELOPMENT' ||  window.REACT_APP_ENVIRONMENT === 'STAGE') &&
              <Col className="blingsSetting">
                    <CustomSelect label={t("components.blingsProject")} value={nonProdBlingsSettings} setValue={setNonProdBlingsSettings} options={blingsProjectSettings(outputFormat).map((project) => ({
                      value: JSON.stringify(project.settings),
                      name: project.label,
                      id: project.label
                    }))} defaultValue={() => blingsProjectSettings(outputFormat)[0]} adjustableWith notify={false} />
              </Col>
            }
              <Col> 
                <CustomButton id="createSampleVideo" label={t("loadSaveButtons.loadSampleVideo")} onClick={createSampleVideo} disabled={videoLoading || mp4DisableButtons} />
              </Col>
              {videoImageList.length > 0 && (
              <Col>
                <CustomButton id="createImageVideo" label={t("loadSaveButtons.loadImagesTabVideo")} onClick={createImageVideo} disabled={outputFormat || videoLoading || mp4DisableButtons} />
              </Col>
              )}
              <Col >
                <CustomButton id="createVinVideo" label={t("loadSaveButtons.loadVideo")} filledIn={false} onClick={createVinVideoModal} disabled={!apikey || !vin || videoLoading || mp4DisableButtons} />
              </Col>
          </Row>
        </Col>
      </Card>

      {(isVideoLoaded && !isSample && !imageVideo) &&

        <Collapse
          ghost={false}
          className="videoOutputSettings"
          expandIconPosition="start"
          key="OutputSettings"
        >

          <Panel key={1} header={<PanelHeader headerName={t("components.videoOutputSettings")} description={t("videosTab.editSceneTablesPopover.videoOutputSettingsDataTable.description")} data={videoOutputSettingsDataTable} width={width} />}>



            <div>
              <Row justify={width < 728 ? "center" : "start"} className="VideoSwitchGroup" gutter={[24,10]}>
                <Col style={{ paddingRight: 50 }}><CustomSwitch label={t("videosTab.videoOutputFormatSection.outputFormat")} onBtn={t("videosTab.editSceneTablesPopover.videoOutputSettingsDataTable.outputFormatDefault2")} offBtn={t("videosTab.editSceneTablesPopover.videoOutputSettingsDataTable.outputFormatDefault")} getValue={setVideoFormat} defaultValue={videoFormat} status={[t("videosTab.editSceneTablesPopover.videoOutputSettingsDataTable.outputFormatDefault2"), t("videosTab.editSceneTablesPopover.videoOutputSettingsDataTable.outputFormatDefault")]} disabled={mp4DisableButtons} /></Col>
              </Row>
            </div>

            {
              !videoFormat && !isSample &&
              <Row className="VideoSwitchGroup" justify="between" style={{ margin: "10px 0 40px" }}>
                <Col>
                  <CustomSelect label={t("videosTab.videoOutputFormatSection.videoResolution")} value={videoResolutionSetting} setValue={setVideoResolutionSetting} options={resolutionOptions} disabled={mp4DisableButtons} adjustableWith={true} />
                </Col>
              </Row>
            }
            {
              mp4VideoLoading &&
              <Row gutter={20} justify="start" style={{ margin: "0 0 40px 28px" }}>
                <Col span={48}>
                  <div className="loadingSpinner">
                    <label className="loadingSpinnerLabel">
                      {mp4VideoLoadingText}
                    </label>
                    <Progress type="circle" width={50} percent={mp4VideoPercent} />
                  </div>
                </Col>
              </Row>
            }

            {!videoFormat && !isSample &&
              <Row justify="end" style={{ marginBottom: "40px" }}>
                <Col><CustomButton id="saveImage" label={t("loadSaveButtons.saveImage")} filledIn={false} onClick={saveImage} style={{ float: "left" }} disabled={mp4DisableButtons} /></Col>
                <Col><CustomButton id="saveVinVideoMp4" label={t("loadSaveButtons.saveVideo")} filledIn={false} onClick={confirmMP4Download} style={{ float: "right", marginLeft: "10px" }} disabled={mp4DisableButtons} /></Col>
              </Row>}

            {videoFormat &&
              <Row gutter={[20, 20]} justify="end" style={{ marginBottom: "40px" }}>
                <Col><CustomButton id="copyVinVideo" label={t("components.copyClipboard")} filledIn={false} onClick={() => navigator.clipboard.writeText(JSON.stringify(videoData, null, 4))} style={{ float: "right" }} disabled={!showSaveButton || mp4DisableButtons} /></Col>
                <Col><CustomButton id="saveVinVideo" label={t("loadSaveButtons.saveVideo")} filledIn={false} onClick={saveVinVideo} style={{ float: "right" }} disabled={!showSaveButton || mp4DisableButtons} /></Col>
              </Row>
            }

       
            
            
          </Panel>
        </Collapse>
      }
      <Row id="VideoArea" className={classSelector(currentVideoType)}>

        <div>
          <div id="video-container" />
        </div>

      </Row>
    </div>
  )
}

export default Video;
