import { WbLink, WbText } from "@workbench/react";
import { useTranslation } from "react-i18next";

function ProductKey(props) {

    const { apikeyEntered, selectedTab } = props

    const { t } = useTranslation();

    return ( apikeyEntered[selectedTab-1] === 0 &&
        <div className="ProductKey">
            <WbText size="l" className="ProductKey-Text">
                <b>{t("mainScreen.producstKeyNeeded")}</b> {t("mainScreen.purchaseThem")}{' '}
                <WbLink
                    href="https://developer.mercedes-benz.com/products?u=Retailer"
                    rel="noopener noreferrer"
                    target="_blank"
                    variant="inline"
                >
                    Mercedes-Benz /developers
                </WbLink>{' '}
                {t("mainScreen.portal")}. <br />
            </WbText>
        </div>
    );
}

export default ProductKey;
