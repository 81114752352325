import { WbLink, WbText } from "@workbench/react";
import { useTranslation } from "react-i18next";

function FooterBar() {

  const { t } = useTranslation();

  const rootList = [
    {
      key: 1,
      url: 'https://developer.mercedes-benz.com/legal/imprint',
      value: 'imprint'
    },
    {
      key: 2,
      url: 'https://developer.mercedes-benz.com/legal/notice',
      value: 'legalNotice',
    },
    {
      key: 3,
      url: '/legal/cookies',
      value: 'cookies',
    },
    {
      key: 4,
      url: '/legal/privacy',
      value: 'privacyStatement',
    },
    {
      key: 5,
      url: 'https://developer.mercedes-benz.com/legal/terms',
      value: 'termsOfUse',
    },
  ];

  return (
    <div className="FooterBar" >
      <div className="FooterLinks" >
			{rootList.map((e) => (
				<WbLink key={e.key} href={e.url} theme="light"> 
          <WbText size="l">
            {t(`mainScreen.footerBar.${e.value}`)}
          </WbText>
        </WbLink>
			))}
      </div>
    </div>
  )
}

export default FooterBar;