import { Col, Row, Collapse, Select } from "antd";
import { useState } from "react";

import PanelHeader from "./PanelHeader";
import { useTranslation } from "react-i18next";
import Image360ViewContainer from "./Image360ViewContainer";

const { Panel } = Collapse;

function Image360View(props) {
  const { images } = props;

  const [currentVin, setCurrentVin] = useState(Object.keys(images)[0]);
  const { t } = useTranslation();


  return (
    <div className="collapse">
      <Collapse
        bordered={true}
        expandIconPosition="start"
        ghost={false}
        className="image360ViewCollapseContainer"
      >
        <Panel
          header={
            <PanelHeader
              headerName={t("imagesTab.image360View.title")}
              description={t("imagesTab.image360View.description")}
            />
          }
        >
          <Row align="middle" className="selectVINBox" gutter={[12, 12]} >
            <Col xs={24}  md={3}>
              <Row className="CustomSwitchLabel">
                  <span> {t("imagesTab.imageCompose.selectVIN")} </span>
              </Row>
            </Col>
            <Col xs={24} md={21}>
              <Row>
                <Select
                  size={"large"}
                  onChange={(value) => {
                    setCurrentVin(value);
                  }}
                  value={currentVin}
                  defaultValue={currentVin}
                  listHeight={192}
                >
                  {Object.keys(images)
                    .filter((vin) =>
                      images[vin].some(
                        (img) => "title" in img && img["title"].startsWith("EXT")
                      )
                    )
                    .map((vin) => {
                      return (
                        <Select.Option key={vin} value={vin}>
                          {" "}
                          {vin}{" "}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Row>
            </Col>
          </Row>

          <Image360ViewContainer  images={images} currentVin={currentVin} />

        </Panel>
      </Collapse>
    </div>
  );
}

export default Image360View;
