import axios from 'axios';
import axiosRetry from 'axios-retry';

const BASEPATH = window.REACT_APP_API_URL || 'https://mbdev-marketing-dashboard-backend-dev-app.azurewebsites.net/backend'

const _axios = axios

axiosRetry(axios, {
  retries: 3, // number of retries
  retryCondition: () => true
});

axiosRetry(_axios, {
  retries: 1, // number of retries
  retryCondition: () => true
});

export const _getVehicleImages = (params) => {
  const { vin, apikey, queries } = params;
  queries["imageUrl"] = true;
  return axios.get(`${BASEPATH}/business_bundle/vehicles/${vin}`, {
    params: queries,
    headers: { "x-api-key": apikey }
  });
}

export const _getImage = (params) => {
  const { imageId, apikey } = params;
  return axios.get(`${imageId}`, {
    responseType: "arraybuffer"
  });
}

export const _getFreemiumImages = (params) => {
  const { marketId } = params;
  return axios.get(`${BASEPATH}/freemium_bundle/${marketId}`);
}

export const _getVideo = (params) => {
  const { vin, apikey, marketId, pack2code, pack3code, night, isTouch, roofOpen, outputFormat } = params;

  let axiosParams = {}
  if (pack2code.length) axiosParams.pack2Codes = pack2code.join(', ')
  if (pack3code.length) axiosParams.pack3Codes = pack3code.join(', ')

  return _axios.get(`${BASEPATH}/videos_bundle/${vin}?locale=${marketId}&night=${night}&isTouch=${isTouch}&roofOpen=${roofOpen}&outputFormat=${outputFormat}`, {
    params: axiosParams,
    headers: {
      "x-api-key": apikey
    }
  });
}

export const _createMp4Video = (params) => {
  const { apikey, videoData, scenes, resolution, name, outputFormat } = params;

  const finalMp4Data = {
    "scenes": scenes,
    "data": videoData,
    "quality": resolution,
    "outputFileName": name,
    "outputFormat": outputFormat
  };
  return _axios.post(`${BASEPATH}/mp4_videos_bundle/create`, {
    headers: { "x-api-key": apikey },
    params: finalMp4Data
  });
}

export const _getMp4Video = (params) => {
  const { apikey, id } = params;

  return _axios.get(`${BASEPATH}/mp4_videos_bundle/${id}`, {
    headers: { "x-api-key": apikey }
  });
}

export const _getEquipment = (params) => {

  const { apikey, vin, marketId } = params;

  return axios.get(`${BASEPATH}/equipment_bundle/${vin}`, {
    headers: { "x-api-key": apikey },
    params: { marketId: marketId }
  })

};

export const _getSpesification = (params) => {

  const { vin, apikey } = params;
  const query = {...params, vin: undefined, apikey: undefined};

  return axios.get(`${BASEPATH}/specification_bundle/${vin}`, {
    headers: { "x-api-key": apikey },
    params: { ...query }
  }).then(response => {
    return response;
  })
    .catch(err => {
      console.log(err.response);
      if (err.response) {
        if (err.response.status === 404) {
          return {
            data: "No data found",
            status: 404
          };
        } else {
          throw err;
        } 
      }
      return err;
    });
};

export const _getEmissionSpecification = (params) => {

  const { marketId, vin, apikey } = params;

  return axios.get(`${BASEPATH}/emission_specification_bundle/${vin}`, {
    headers: { "x-api-key": apikey },
    params: { marketId: marketId }
  }).then(response => {
    if (response.status === 204) {
      return {
        data: "No data found",
        status: 204
      };
    } else {
      return response;
    }
  })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const _getEquipmentSpecification = (params) => {

  const { marketId, vin, apikey } = params;

  return axios.get(`${BASEPATH}/equipment_specification_bundle/${vin}`, {
    headers: { "x-api-key": apikey },
    params: { marketId: marketId }
  }).then(response => {
    if (response.status === 204) {
      return {
        data: "No data found",
        status: 204
      };
    } else {
      return response;
    }
  })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const _getMeServiceSpecification = (params) => {

  const { marketId, vin, apikey } = params;

  return axios.get(`${BASEPATH}/meservice_specification_bundle/${vin}`, {
    headers: { "x-api-key": apikey },
    params: { marketId: marketId }
  }).then(response => {
    if (response.status === 204) {
      return {
        data: "No data found",
        status: 204
      };
    } else {
      return response;
    }
  })
    .catch(err => {
      console.log(err);
      return err;
    });

};

export const _getSimSearchSpecification = (params) => {

  const { marketId, vin, apikey } = params;

  return axios.get(`${BASEPATH}/simsearch_specification_bundle/${vin}`, {
    headers: { "x-api-key": apikey },
    params: { marketId: marketId }
  }).then(response => {
    if (response.status === 204) {
      return {
        data: "No data found",
        status: 204
      };
    } else {
      return response;
    }
  })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const _getRycSpecification = (params) => {

  const { marketId, vin, apikey } = params;

  return axios.get(`${BASEPATH}/ryc_specification_bundle/${vin}`, {
    headers: { "x-api-key": apikey },
    params: { marketId: marketId }
  }).then(response => {
    debugger
    if (response.status === 204) {
      return {
        data: "No data found",
        status: 204
      };
    } else {
      return response;
    }
  })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const _getUrlImage = (params) => {
  const { imageUrl } = params;
  const body = {
    imageUrl: imageUrl
  }
  return axios.post(`${BASEPATH}/images`, body, {
    responseType: "arraybuffer"
  });
}

export const _getUrlImageAll = (params) => {
  const imageUrls = params;
  let axiosArray = []
  imageUrls.forEach(imageUrl => {
    const body = {
      imageUrl: imageUrl.url
    }
    axiosArray.push(axios.post(`${BASEPATH}/images`, body, {
      responseType: "arraybuffer"
    }))
  });
  return axios.all(axiosArray);
}

export const _getAvailableBackgrounds = (params) => {

  const { vin, apikey } = params;

  return axios.get(`${BASEPATH}/content_bundle/backgrounds/${vin}`, {
    headers: { "x-api-key": apikey }
  })

};

export const _getModelInformation = (params) => {
  const { marketId, baumuster, modelYear, changeYear, nationalSalesType, technicalData, apikey } = params;
  return axios.get(`${BASEPATH}/models_ccs_bundle/`, {
    headers: { "x-api-key": apikey },
    params: { marketId, baumuster, modelYear, changeYear, nationalSalesType, technicalData }
  })
};

export const _mock = (data) => {
  return new Promise((resolve, reject) => setTimeout(() => {
    resolve({ data })
  }, 800));
}