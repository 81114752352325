import { Col, Popover } from "antd";
import InfoPanelsTable from "./InfoPanelsTable";
import { InfoCircleTwoTone } from "@ant-design/icons";

function PanelHeader({ headerName, data, description, width, title }) {
  return (
    <Col className="SettingsSection EditPanel">
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div>{headerName}</div>
        {description && (
          <Popover
            onClick={(e)=> e.stopPropagation()}
            placement="right"
            content={
              <InfoPanelsTable
                data={data}
                descText={description}
                width={width}
              />
            }
            title={title}
          >
            <span className="InputSuffixIconVideo">
              <InfoCircleTwoTone twoToneColor="#0078D6"  />
            </span>
          </Popover>
        )}
      </div>
    </Col>
  );
}

export default PanelHeader;
