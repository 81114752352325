import Intro from './Intro'
import Packages from './Packages'

function Dashboard(props) {

  const { selectedTab, setSelectedTab, apikeyEntered, setApikeyEntered, apikeyRef } = props

  return (
    <div className="Dashboard" >
      <Intro tab={selectedTab} handleTabUpdate={setSelectedTab}/>
      <Packages tab={selectedTab} handleTabUpdate={setSelectedTab} apikeyEntered={apikeyEntered} setApikeyEntered={setApikeyEntered} apikeyRef={apikeyRef}/>
    </div>
  )
}

export default Dashboard;