import { useEffect, useState } from "react";

function useWidth(){

    const [width, setWidth] = useState();

useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () =>
      window.removeEventListener("resize", updateWidth);
  }, [])

  const updateWidth = () => {
    const width = window.innerWidth
    setWidth(width)
  }

  return width
}

export default useWidth;