import { Button } from 'antd'

function CustomButton(props) {
  const { label, filledIn = true, onClick, loading = false, disabled = false, style ,id, className} = props;

  return (
    <div className={`CustomButton ${className}`} >
      <Button className={filledIn && "filledInButton"} id={id} onClick={() => onClick()} loading={loading} disabled={disabled} style={style}> {label} </Button >
    </div >
  )
}

export default CustomButton;