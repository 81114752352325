import React, {useState, useRef, useEffect} from "react";

import _notification from '../Utils/notification'

import search from '../Utils/search'
import removeNulls from '../Utils/removeNulls'

import { useTranslation } from 'react-i18next'
import useDebouncedEffect from '../Utils/useDebouncedEffect'


function useFilter(data, searchFilter, nullFilter) {
    const [filteredData, setFilteredData] = useState(null)
    const [notifyKey, setNotifyKey] = useState();
    const prevNotifyKey = useRef();
    const { t } = useTranslation();

    useEffect(() => prevNotifyKey.current = notifyKey);

    const openNotification = (title, message) => {
        const config = {
          title: title,
          message: message,
          key: prevNotifyKey.current,
          onClose: () => {
            setNotifyKey(undefined);
          }
        }
        setNotifyKey(_notification(config));
    }

    useDebouncedEffect(() => {
        if (!data) return;
        try {
          let processedData = data;
    
          if (searchFilter !== "") {
            processedData = search(data, searchFilter);
          }
    
          if (!nullFilter) {
            processedData = removeNulls(processedData);
          }
    
          setFilteredData(processedData);
        }
        catch (error) {
          console.log(error);
          openNotification(t("notification.info"), `String "${searchFilter}" not found with null filter: ${!nullFilter ? "On" : "Off"}.`)
        }
      }, [nullFilter, searchFilter, data], 300)

    return filteredData;
}

export default useFilter;