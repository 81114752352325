import { useEffect, useState } from 'react'
import { Row, Col, Collapse } from 'antd'

import { saveAs } from 'file-saver'

import CustomButton from './CustomButton'
import CustomSwitch from './CustomSwitch';
import { RightOutlined } from '@ant-design/icons'

import ImagesPanel from './ImagesPanel';
import ImageCompose from './ImageCompose';
import Image360View from './Image360View';
import ImageRenderList from './ImageRenderList';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

function ImageList(props) {
  const { imageList, isImagesLoading, saveFileName, imageType, rowCount = 6, bulk = false, background, cropped, loadedBackground, loadedFileFormat } = props;
  const [imagesCount, setImagesCount] = useState(0);
  const [vinsCount, setVinsCount] = useState(0);
  const [saving, setSaving] = useState(false);
  const [renderedList, setRenderedList] = useState([]);
  const [showImages, setShowImages] = useState(bulk);
  const [panels, setPanels] = useState([]);
  const [innerPanels, setInnerPanels] = useState([]);
  const [availablePanels, setAvailablePanels] = useState([]);

  const { t } = useTranslation()

  useEffect(() => {
    const tempRenderList = {};
    setShowImages(bulk);
    let vinsCounter = 0;
    let imagesCounter = 0;
    for (let key in imageList) {
      vinsCounter += 1;
      if (imageList[key].length > 0 && !("errorMessage" in imageList[key][0])) {
        imagesCounter += imageList[key].length;
      }

    }
    setVinsCount(vinsCounter);
    setImagesCount(imagesCounter);
    let i = 0;
    let tmpPanelsList = [];
    for (let key in imageList) {
      if(imageList[key].length === 0 || imageList[key][0]?.errorMessage) continue;
      tempRenderList[key] = imageList[key];
      tmpPanelsList.push(key);
      i++;
    }
    setAvailablePanels(tmpPanelsList);
    setRenderedList(tempRenderList);
  }, [imageList, rowCount]);


  const saveImages = () => {
    setSaving(true);
    const zip = require("jszip")();
    let imageFolder = zip.folder(saveFileName);

    for (let key in imageList) {
      if ("errorMessage" in imageList[key][0]) {
        continue;
      }
      imageList[key].forEach(({ title, blob }) => {
        if (bulk) {
          imageFolder.file(`${key}/${title}.${imageType}`, blob, { createFolders: true });
        }
        else {
          imageFolder.file(`${title}.${imageType}`, blob, { createFolders: true });
        }
      })
    }

    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, saveFileName);
      setSaving(false);
    }).catch(err => {
      console.log(err);
      setSaving(false);
    })
  }

  const panelHandler = (mode) => {
    switch (mode) {
      case 0: //Show All
        setPanels(availablePanels)
        setInnerPanels(true)
        break;

      case 1: //Collapse All
        setPanels([])
        setInnerPanels(false)
        break;

      default:
        break;
    }
  }
  
  const expandIcon= ({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />

  if (showImages) {
    return (
      <div>
        <Row className="FoundImagesInfo" justify="space-between" style={{ marginBottom: "40px" }}>
          <Col className="ImageCounter"><div style={{ paddingTop: "17px" }} ><strong>{`${vinsCount} ${t("placeholders.vinNumberPlaceholder")}${vinsCount > 1 ? "s" : ""}, ${imagesCount} ${imagesCount > 1 ? t("infoTexts.imagesFound") : t("infoTexts.imageFound")}`}</strong></div></Col>
          <Col><div><CustomSwitch label={t("loadSaveButtons.showImages")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"),t("components.offButton")]} span={5} xs={22} getValue={setShowImages} defaultValue={showImages} disabled={isImagesLoading} /></div></Col>
          <Col><CustomButton label={t("loadSaveButtons.saveImages")} filledIn={false} onClick={() => saveImages()} loading={saving} disabled={isImagesLoading} /></Col>
        </Row>
      </div>
    );
  }
  else {
    return (
      <div>
        <Row className="FoundImagesInfo" justify="space-between" style={{ marginBottom: "40px" }}>
          <Col className="ImageCounter" ><div ><strong>{`${vinsCount} ${t("placeholders.vinNumberPlaceholder")}${vinsCount > 1 ? "s" : ""}, ${imagesCount} ${imagesCount > 1 ? t("infoTexts.imagesFound") : t("infoTexts.imageFound")}`}</strong></div></Col>
          <Col><div className="FoundImagesShowImagesContainer"><CustomSwitch label={t("loadSaveButtons.showImages")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} span={5} xs={22} getValue={setShowImages} defaultValue={showImages} disabled={isImagesLoading} /></div></Col>
          <Col><CustomButton label={t("loadSaveButtons.saveImages")} filledIn={false} onClick={() => saveImages()} loading={saving} disabled={isImagesLoading} /></Col>
        </Row>

        <ImagesPanel panelHandler={panelHandler} panels={panels} availablePanels={availablePanels} />

        {
          !isImagesLoading && imagesCount > 0 &&
            <Image360View images={imageList} />
        }

        {
          // Show compose section if all images loaded.
          !isImagesLoading && imagesCount > 0 &&
            <ImageCompose images={imageList} saveFileName={saveFileName + "_COMPOSED"} imageType={imageType} cropped={cropped} loadedBackground={loadedBackground} loadedFileFormat={loadedFileFormat} />
        }

        <Collapse activeKey={panels} onChange={setPanels} bordered={false}
          expandIcon={expandIcon}
          className="ModelsMenu">
          {Object.keys(renderedList).map(vin => (
            <Panel header={vin} key={vin} className="ModelsClasses">
              <ImageRenderList vin={vin} imageList = {renderedList[vin]} rowCount = {rowCount} imageType = {imageType} background = {background} isDownload = {true} innerPanels = {innerPanels}/>
            </Panel>
          ))}
        </Collapse>

        <Row style={{ marginTop: "40px", marginBottom: "120px", display: "flex", justifyContent: "flex-end" }}>
          <Col ><CustomButton label={t("loadSaveButtons.saveImages")} filledIn={false} onClick={() => saveImages()} loading={saving} disabled={isImagesLoading} style={{ float: "right" }} /></Col>
        </Row>

      </div>
    );

  }

}

export default ImageList;