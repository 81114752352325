import { useEffect, useRef, useState } from 'react'
import { Row, Col, Card, Select, Collapse, Typography } from 'antd'

import { _getFreemiumImages, _getUrlImage, _getModelInformation } from '../Utils/apiCalls'
import { modelMarketList } from '../Constants/modelMarketList'
import _notification from '../Utils/notification'
import _handleErrors from '../Utils/handleErrors'

import ModelsImageList from './ModelsImageList'
import PanelHeader from './PanelHeader'
import CustomInput from './CustomInput'
import CustomSwitch from './CustomSwitch'
import CustomButton from './CustomButton'
import SpecificationLabel from './SpesificationLabel'
import JsonWithSearchAndSave from './JsonWithSearchAndSave'
import ExampleModelsData from "../Assets/ExampleModels/example_vehicle_models_baumaster.json"

import { RightOutlined } from '@ant-design/icons'

import { WbText } from '@workbench/react'

import { saveAs } from 'file-saver'

import useWidth from '../Utils/useWidth'
import saveJson from '../Utils/saveJson'
import useFilter from '../Utils/useFilter'

import { useTranslation } from 'react-i18next'

const { Panel } = Collapse;
const { Option } = Select;


function Models(props) {

  const { rowCount, setApikeyEntered, apikeyRef, tabNumber } = props;
  const [apikey, setApikey] = useState(null);
  const [marketId, setMarketId] = useState("de_DE");
  const [baumuster, setBaumuster] = useState(null);
  const [modelYear, setModelYear] = useState(null);
  const [changeYear, setChangeYear] = useState(null);
  const [nationalSalesType, setNationalSalesType] = useState(null);
  const [noTechnicalData, setNoTechnicalData] = useState(false);
  const [saveFileName, setSaveFileName] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [isImagesLoading, setIsImagesLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false); 
  const [isApiKeyData, setIsApiKeyData] = useState(false);
  const [modelInfo, setModelInfo] = useState(null);
  const [notifyKey, setNotifyKey] = useState();
  const [ellipsis, setEllipsis] = useState(false)
  
  const [nullFilter, setNullFilter] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const renderModelInfo = useFilter(modelInfo, filterInput, nullFilter)

  const { Paragraph } = Typography;

  const { t } = useTranslation();

  const width = useWidth();

  const prevImageList = useRef();
  const prevNotifyKey = useRef();
  useEffect(() => prevImageList.current = imageList);
  useEffect(() => prevNotifyKey.current = notifyKey);

  useEffect(() => {
    width <= 650 ? setEllipsis(true) : setEllipsis(false)
  }, [width])

  useEffect(() => {
    if(apikey){
      apikeyRef.current[tabNumber-1] = 1
      setApikeyEntered((prev => {
        let newApikeyEntered = [...prev];
        newApikeyEntered[tabNumber - 1] = 1;
        return newApikeyEntered;
      }))
    } else {
      apikeyRef.current[tabNumber-1] = 0
      setApikeyEntered((prev) => {
        let newApikeyEntered = [...prev];
        newApikeyEntered[tabNumber - 1] = 0;
        return newApikeyEntered;
      });
    }
  }, [apikey])

// Data for the tables
const modelsSettingsDataTable = [
  {
    key: 1,
    type: t("components.selectMarket"),
    desc: t("modelsTab.infoTable.selectMarket"),
    default: "de_DE"
  }, {
    key: 2,
    type: t("modelsTab.baumuster"),
    desc: t("modelsTab.infoTable.baumuster"),
  }, {
    key: 3,
    type: t("modelsTab.modelYear"),
    desc: t("modelsTab.infoTable.modelYear"),
  }, {
    key: 4,
    type: t("modelsTab.changeYear"),
    desc: t("modelsTab.infoTable.changeYear"),
  }, {
    key: 5,
    type: t("modelsTab.nationalSalesType"),
    desc: t("modelsTab.infoTable.nationalSalesType"),
  }, {
    key: 6,
    type: t("modelsTab.technicalData"),
    desc: t("modelsTab.infoTable.technicalData"),
  }
]

  const count = imageList.length;

  const getFreemiumImages = () => {

    const params = { marketId };
    let vehicleImages;
    const imageList = [];

    setIsImagesLoading(true);
    vehicleImages = _getFreemiumImages(params).then(resp => {
      if (resp.data) {
        for (let i = 0; i < Object.keys(resp.data).length; i++) {
          imageList.push({ title: resp.data[i].title, url: resp.data[i].url, type: resp.data[i].type })
        }
      }
      return imageList;
    });

    vehicleImages.then(async newImageList => {
      setImageList([]);
      console.log("Images started to Load");
      await loadImages(newImageList);
      console.log("Images finished loading");
      setIsImagesLoading(false);
      openNotification(t("notification.finished"), t("notification.loadedImages") + marketId.split("_")[1]);
    }).catch(err => {
      const errorMessage = _handleErrors(err, marketId.split("_")[1]);
      console.log(errorMessage);
      setIsImagesLoading(false);
      openNotification(t("notification.error"), errorMessage);
    });

    setSaveFileName("Freemium Images - " + marketId.split("_")[1]);
  }

  const getFreemiumImage = (imageUrl) => {
    const params = { imageUrl };
    return _getUrlImage(params).then(resp => {
      if (resp.data) {
        return resp.data;
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const getModelsInformation = () => {
    let params = { marketId, baumuster, modelYear, changeYear, nationalSalesType, technicalData: !noTechnicalData, apikey };
    params = Object.fromEntries(Object.entries(params).filter(([k,v]) => v !== "" && v !== undefined));
    setModelInfo(null);
    setIsDataLoading(true);
    openNotification(t("notification.loading"), `Getting data for: marketId=${marketId}, baumuster=${baumuster}, modelYear=${modelYear}`);;
    _getModelInformation(params).then(resp => {
      const { data } = resp;
      setModelInfo(data);
      setIsDataLoading(false);
      console.log(data);
      setIsApiKeyData(true);
      openNotification(t("notification.finished"), t("notification.loadedData") + `marketId=${marketId}, baumuster=${baumuster}, modelYear=${modelYear}`);
    }).catch(err => {
      const errorMessage = err?.response?.data?.errorMessage;
      console.log(errorMessage);
      setIsDataLoading(false);
      openNotification(t("notification.error"), t("notification.failedModelData"));
    });
  }

  const getSampleModelsInformation = () => {
    setModelInfo(null);
    setIsApiKeyData(false);
    setModelInfo(ExampleModelsData);
  }

  const loadImages = async (newImageList) => {
    await newImageList.reduce(async (previousCall, nextImage) => {
      await previousCall;
      const { url, title, type } = nextImage;
      return getFreemiumImage(url).then(async imageData => {
        const image = convertImage(imageData);
        console.log(title + ": is loaded");
        setImageList([...prevImageList.current, { title, type, ...image }])
      }).catch(err => {
        console.log(title + ": failed loading");
        _notification({ title: t("notification.error"), message: t("notification.failedImage") + title });
        console.log(err);
      })
    }, Promise.resolve());
  }

  const convertImage = (imageData, imageType) => {
    let arrayBufferView = new Uint8Array(imageData);
    let blob = new Blob([arrayBufferView], { type: imageType });
    let urlCreator = window.URL || window.webkitURL;
    let url = urlCreator.createObjectURL(blob);
    return { url, blob }
  }

  const openNotification = (title, message) => {
    const config = {
      title: title,
      message: message,
      key: prevNotifyKey.current,
      onClose: () => {
        setNotifyKey(undefined);
      }
    }
    setNotifyKey(_notification(config));
  }

  const saveImages = () => {
    const zip = require("jszip")();
    let imageFolder = zip.folder(saveFileName);
    imageList.forEach(({ title, blob }) => {
      imageFolder.file(`${title}.png`, blob);
    })
    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, saveFileName);
    }).catch(err => {
      console.log(err);
    })
  }

  const saveImagesByFile = () => {
    const zip = require("jszip")();
    let result = imageList.map(a => a.type);
    let carImageFolder, vanImageFolder, smartImageFolder;

    if (result.includes("PASSENGER_CAR")) {
      carImageFolder = zip.folder("Cars");
    }
    if (result.includes("VAN")) {
      vanImageFolder = zip.folder("Vans");
    }
    if (result.includes("SMART")) {
      smartImageFolder = zip.folder("Smart");
    }

    imageList.forEach(({ type, title, blob }) => {
      if (type === "PASSENGER_CAR") {
        carImageFolder.file(`${title}.png`, blob);
      }
      if (type === "VAN") {
        vanImageFolder.file(`${title}.png`, blob);
      }
      if (type === "SMART") {
        smartImageFolder.file(`${title}.png`, blob);
      }
    })

    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, saveFileName);
    }).catch(err => {
      console.log(err);
    })
  }

  const saveModelData = () => {
    saveJson(modelInfo, `${baumuster}-${modelYear}_modelData.json`)
  }

  const expandIcon=({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />
  const copyCollapsable = (data) => (
    <Paragraph
      copyable={{
        text: JSON.stringify(data, null, 2),
        tooltips: [t("specificationsTab.copyTheData"), t("specificationsTab.copiedData")],
      }} />
  )

  return (
    <div className="Models modelsPanel PackagePanel">
      <Card className="PackagePanelInfo" bordered={false} style={{ paddingTop: 26, paddingLeft: 15}}>
        <Row className="DownloadModels" gutter={[0,16]}>
          <Col span={24}>
            {t("modelsTab.downloadText")}
          </Col>
          <Col span={24}>
            {t("modelsTab.modelsIntroText")}
          </Col>
        </Row>

        <div className="InputPackage">
          <Row gutter={width < 710 ? 0 : 20} style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "64px", marginTop: "-7px" }}>
            <Col flex={width < 769 ? "auto" : "2"} ><CustomInput label={t("placeholders.apiKeyPlaceholder")} getValue={setApikey} password={true} /></Col>
          </Row>

          <Collapse
            ghost={false}
            className="ModelsSettingsCollapse"
            expandIconPosition="start"
            key="ModelsSettings"
          >
            <Panel key={1} header={<PanelHeader headerName={t("modelsTab.modelsContentSettings")} description={t("modelsTab.modelsContentSettingsDesc")} data={modelsSettingsDataTable} width={width} />} className="ModelsPanelSettings">
              <Row className="ImageSwitchGroup" gutter={[10, 10]} >
                <Col>
                  <div className="CustomSwitchLabel2">{t("components.selectMarket")}
                    <Select
                      showSearch
                      size={"large"}
                      onChange={value => {
                        setMarketId(value);
                      }}
                      value={marketId}
                      defaultValue={"de_DE"}
                      listHeight={192}
                      style={{ width: 100, margin: 10 }}
                    >
                      {modelMarketList.map(market => {
                        return <Option key={market.market_code} value={market.market_code}>{market.market_name}</Option>
                      })}
                  </Select>
                  </div>
                </Col>
                <Col><CustomInput label={t("modelsTab.baumuster")} getValue={setBaumuster} /></Col>
                <Col><CustomInput label={t("modelsTab.modelYear")} getValue={setModelYear} /></Col>
                <Col><CustomInput label={t("modelsTab.changeYear")} getValue={setChangeYear} /></Col>
                <Col><CustomInput label={t("modelsTab.nationalSalesType")} getValue={setNationalSalesType} /></Col>
                <Col><CustomSwitch label={t("modelsTab.technicalData")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} getValue={setNoTechnicalData} defaultValue={noTechnicalData} /></Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
        <div className="ButtonPackage">
          <Row gutter={[16, 16]} justify={"end"}>
            <Col><CustomButton label={t("loadSaveButtons.loadImages")} onClick={getFreemiumImages} loading={isImagesLoading} /></Col>
            <Col><CustomButton label={t("loadSaveButtons.loadSampleData")} onClick={getSampleModelsInformation} disabled={isDataLoading} /></Col>
            <Col><CustomButton label={t("loadSaveButtons.loadData")} onClick={getModelsInformation} loading={isDataLoading} disabled={!apikey || !baumuster || !modelYear} /></Col>
          </Row>
        </div>
      </Card>
      {modelInfo && <JsonWithSearchAndSave title={t("modelsTab.filterModels")} input={modelInfo} save={isApiKeyData && saveModelData} filterInput={[filterInput, setFilterInput]} nullFilter={[nullFilter, setNullFilter]}>
            {
              <Panel header={t("modelsTab.modelDataHeading")} key="1" className="ModelsClasses" extra={modelInfo && copyCollapsable(modelInfo)}>
                <SpecificationLabel data={renderModelInfo} />
              </Panel>
            }
        </JsonWithSearchAndSave>
      }
      {imageList.length > 0 && <Row justify="space-between" style={{ marginBottom: "40px" }}>
        <Col span={24 / rowCount}><div style={{ paddingTop: "10px" }} className = "ImageCounter" ><strong>{`${count} ${count > 1 ? t("infoTexts.imagesFound") : t("infoTexts.imageFound")}`}</strong></div></Col>
        <Col span={4}><CustomButton label={t("loadSaveButtons.saveImages")} filledIn={false} onClick={() => saveImages()} isImagesLoading={isImagesLoading} style={{ float: "right" }} /></Col>
      </Row>}
      <Collapse bordered={false}
        defaultActiveKey={['1', '2', '3']}
        expandIcon={expandIcon}
        className="ModelsMenu">

        {imageList.filter(obj => {
          return obj.type === "PASSENGER_CAR"
        }).length > 0 && <Panel header="Mercedes-Benz Cars" key="1" className="ModelsClasses">
            <Collapse bordered={false}
              defaultActiveKey={['4', '5', '6', '7']}
              expandIcon={expandIcon}
              className="ModelsInnerMenu">
              {imageList.filter(obj => {
                return !obj.title.includes("Mercedes-AMG") && !obj.title.includes("Mercedes-EQ") && !obj.title.includes("Mercedes-Maybach") && obj.type === "PASSENGER_CAR";
              }).length > 0 && <Panel header="Mercedes-Benz" key="4" className="ModelsInnerMenu">
                  <ModelsImageList imageList={imageList.filter(obj => {
                    return (!obj.title.includes("Mercedes-AMG") && !obj.title.includes("Mercedes-EQ") && !obj.title.includes(("Mercedes-Maybach"))) && obj.type === "PASSENGER_CAR"
                  })} imageType={"png"} rowCount={rowCount} />
                </Panel>}
              {imageList.filter(obj => {
                return obj.title.includes("Mercedes-EQ") && obj.type === "PASSENGER_CAR"
              }).length > 0 && <Panel header="Mercedes-EQ" key="5" className="ModelsInnerMenu">
                  <ModelsImageList imageList={imageList.filter(obj => {
                    return obj.title.includes("Mercedes-EQ") && obj.type === "PASSENGER_CAR"
                  })} imageType={"png"} rowCount={rowCount} />
                </Panel>}
              {imageList.filter(obj => {
                return obj.title.includes("Mercedes-AMG") && obj.type === "PASSENGER_CAR"
              }).length > 0 && <Panel header="Mercedes-AMG" key="6" className="ModelsInnerMenu">
                  <ModelsImageList imageList={imageList.filter(obj => {
                    return obj.title.includes("Mercedes-AMG") && obj.type === "PASSENGER_CAR"
                  })} imageType={"png"} rowCount={rowCount} />
                </Panel>}
              {imageList.filter(obj => {
                return obj.title.includes("Mercedes-Maybach") && obj.type === "PASSENGER_CAR"
              }).length > 0 && <Panel header="Mercedes-Maybach" key="7" className="ModelsInnerMenu">
                  <ModelsImageList imageList={imageList.filter(obj => {
                    return obj.title.includes("Mercedes-Maybach") && obj.type === "PASSENGER_CAR"
                  })} imageType={"png"} rowCount={rowCount} />
                </Panel>}
            </Collapse>
          </Panel>}

        {imageList.filter(obj => {
          return obj.type === "VAN"
        }).length > 0 && <Panel header="Mercedes-Benz Vans" key="2" className="ModelsClasses">
            <ModelsImageList imageList={imageList.filter(obj => {
              return obj.type === "VAN"
            })} imageType={"png"} rowCount={rowCount} />
          </Panel>}

        {imageList.filter(obj => {
          return obj.type === "SMART"
        }).length > 0 && <Panel header="Smart" key="3" className="ModelsClasses">
            <ModelsImageList imageList={imageList.filter(obj => {
              return obj.type === "SMART"
            })} imageType={"png"} rowCount={rowCount} />
          </Panel>}

      </Collapse>
      {imageList.length > 0 && <Row justify="space-between" style={{ marginTop: "40px", marginBottom: "120px" }}>
        <Col span={4}></Col>
        <Col span={4}><CustomButton label={t("loadSaveButtons.saveImages")} filledIn={false} onClick={() => saveImagesByFile()} isImagesLoading={isImagesLoading} style={{ float: "right" }} /></Col>
      </Row>}
    </div>
  )
}

export default Models;