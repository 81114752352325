import { Col, Row, Tooltip, Popover, Descriptions, Collapse } from "antd";
import { useEffect, useState } from "react";
import _notification from "../Utils/notification";
import CustomButton from "./CustomButton";
import CustomInput from "./CustomInput";
import CustomSwitch from "./CustomSwitch";

import { CaretDownOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { WbText } from "@workbench/react";

const DescItem = Descriptions.Item;
const { Panel } = Collapse;

const EquipmentFilter = (props) => {

    const { equipmentFilterHandler, oneFilterHandler } = props

    useEffect(() => {
        handleFilter();
        handleOneFilter(true);
    }, []);

    const [imageFilter, setImageFilter] = useState(" ");
    const [equipmentFilter, setEquipmentFilter] = useState(" ");
    const [filter, setFilter] = useState(true);

    const { t } = useTranslation()

    useEffect(() => {
        if(!filter)  _notification({ title: t("components.warning"), message: disabledFilterText , duration: 10 });
    }, [filter]);

    const optionCodeText = "e.g.: A23, A24, A40"
    const equipmentCodeText = "e.g.: 235, 275, 294"
    const filterText = t("equipmentsTab.showImageTypesHoverInfoBox")
    const disabledFilterText = t("equipmentsTab.disabledFilterText")

    function handleFilter() {

        let tempImageFilter = imageFilter.replace(/ /g, '')
        let tempEquipmentFilter = equipmentFilter.replace(/ /g, '')

        tempImageFilter = tempImageFilter.toUpperCase().split(",")
        tempEquipmentFilter = tempEquipmentFilter.toUpperCase().split(",")
        equipmentFilterHandler(tempEquipmentFilter, tempImageFilter)
    }

    function handleOneFilter(value) {
        setFilter(value);
        oneFilterHandler(value);
    }

    const imageTypesInfoTitle = (
        <div className="InputPopup InfoPopupTitle" >
            <span>
                {t("equipmentsTab.applyFiltersHoverInfoBox")}
            </span>
        </div>
    )

    const imageTypesInfoContent = (
        <div className="InputPopup InfoPopupContent" >
            <Descriptions
                bordered
                size="small"
                column={3}
            >
                <DescItem label= {t("placeholders.imageTypePlaceholder")} >{t("equipmentsTab.equipmentFilter.resolution")}</DescItem>
                <DescItem>{t("equipmentsTab.equipmentFilter.size")}</DescItem>
                <DescItem>{t("equipmentsTab.equipmentFilter.aspectRatio")}</DescItem>

                <DescItem label="A23">230x131</DescItem>
                <DescItem>{t("equipmentsTab.equipmentFilter.small")}</DescItem>
                <DescItem></DescItem>

                <DescItem label="A24">740x416</DescItem>
                <DescItem>{t("equipmentsTab.equipmentFilter.medium")}</DescItem>
                <DescItem>16:9</DescItem>

                <DescItem label="P29">142x142</DescItem>
                <DescItem>{t("equipmentsTab.equipmentFilter.small")}</DescItem>
                <DescItem>{t("equipmentsTab.equipmentFilter.square")}</DescItem>

                <DescItem label="A30">1024x576</DescItem>
                <DescItem>{t("equipmentsTab.equipmentFilter.large")}</DescItem>
                <DescItem>16:9</DescItem>

                <DescItem label="A31">138x132</DescItem>
                <DescItem>{t("equipmentsTab.equipmentFilter.medium")}</DescItem>
                <DescItem>4:3</DescItem>

                <DescItem label="A34">800x600</DescItem>
                <DescItem>{t("equipmentsTab.equipmentFilter.large")}</DescItem>
                <DescItem>4:3</DescItem>

                <DescItem label="P35">710x710</DescItem>
                <DescItem>{t("equipmentsTab.equipmentFilter.large")}</DescItem>
                <DescItem>{t("equipmentsTab.equipmentFilter.square")}</DescItem>

                <DescItem label="A40">1920x1440</DescItem>
                <DescItem>HD</DescItem>
                <DescItem>4:3</DescItem>

                <DescItem label="A42">1920x1080</DescItem>
                <DescItem>HD</DescItem>
                <DescItem>16:9</DescItem>
            </Descriptions>
        </div>
    )
    const expandIcon=({ isActive }) => <CaretDownOutlined rotate={isActive ? 180 : 0} style={{transform:"scale(2) scaleX(2.25) scaleY(1.5)", marginRight: 15}} />;

    return (
        <Collapse bordered={false}
        expandIcon={expandIcon}
        className="ModelsMenu EquipmentFilterCollapse"
        expandIconPosition="end"
        >
            <Panel header={
                    <WbText size="m">
                        {t("equipmentsTab.filterEquipments")}
                    </WbText>
                } className="FilterEquipmentClass">
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <WbText> 
                            {t("equipmentsTab.filterEquipmentsIntroText")}
                        </WbText>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[30, 30]} align="middle">
                            <Tooltip title={equipmentCodeText}>
                                <Col xs={24} md={12} lg={6}>
                                    <CustomInput label={t("components.equipmentCodesPlaceholder")} getValue={setEquipmentFilter} />
                                </Col>
                            </Tooltip>
                            <Tooltip title={filter ? optionCodeText : disabledFilterText}>
                                <Col xs={24} md={12} lg={6}>
                                    <CustomInput 
                                        Info={() => <Popover placement="right" content={imageTypesInfoContent} title={imageTypesInfoTitle}>
                                                    <span className='InputSuffixIcon'><InfoCircleTwoTone /></span>
                                                    </Popover>}                                     
                                        label={t("placeholders.imageTypesPlaceholder")}
                                        getValue={setImageFilter}
                                        disabled={!filter} 
                                    />
                                </Col>
                            </Tooltip>
                            <Tooltip title={filterText}>
                                <Col xs={24} md={12} lg={6}>
                                    <CustomSwitch className="testx" label={t("components.showImageTypes")} onBtn={t("components.single")} offBtn={t("components.all")} status={[t("components.single"),t("components.all")]} defaultValue={true} getValue={handleOneFilter} />
                                </Col>
                            </Tooltip>
                            <Col xs={24} md={12} lg={6}>
                                <CustomButton filledIn={false} label={t("components.applyFiltersButton")} onClick={handleFilter} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Panel>
        </Collapse>
    );
}

export default EquipmentFilter;