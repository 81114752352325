import { useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Select } from 'antd'

import CustomButton from './CustomButton';
import CustomInput from './CustomInput';
import _notification from '../Utils/notification'
import _handleErrors from '../Utils/handleErrors';
import { _getEquipment, _getUrlImage, _getUrlImageAll } from '../Utils/apiCalls';
import { marketList } from '../Constants/marketList'
import EquipmentList from './EquipmentList';
import { ImageTypesRes } from './ImageTypes';
import ExampleEquipmentImages from "./ExampleEquipmentImages.json"
import { useTranslation } from 'react-i18next';
import { filterEquipmentList } from '../Utils/equipmentsHelper';

const { Option } = Select;

function Equipment(props) {

    const { rowCount, width, setApikeyEntered, tabNumber, apikeyRef } = props;
    const [apikey, setApikey] = useState(null);
    const [vin, setVin] = useState(null);
    const [isImagesLoading, setIsImagesLoading] = useState(false);
    const [isExampleImagesLoading, setIsExampleImagesLoading] = useState(false);
    const [notifyKey, setNotifyKey] = useState();

    const [marketId, setMarketId] = useState("de_DE");
    const [imageList, setImageList] = useState([]);
    const [equipmentList, setEquipmentList] = useState([])

    const [examples, setExamples] = useState(false);

    let tempImageList = [];
    let tempEquipmentList = [];

    const saveFileName = "Equipments"

    const prevImageList = useRef();
    const prevEquipmentList = useRef();
    const prevNotifyKey = useRef();

    const { t } = useTranslation();

    useEffect(() => prevImageList.current = imageList);
    useEffect(() => prevEquipmentList.current = equipmentList);
    useEffect(() => prevNotifyKey.current = notifyKey);
    useEffect(() => {
        if(apikey){
          apikeyRef.current[tabNumber-1] = 1
          setApikeyEntered((prev => {
            let newApikeyEntered = [...prev];
            newApikeyEntered[tabNumber - 1] = 1;
            return newApikeyEntered;
          }))
        } else {
          apikeyRef.current[tabNumber-1] = 0
          setApikeyEntered((prev) => {
            let newApikeyEntered = [...prev];
            newApikeyEntered[tabNumber - 1] = 0;
            return newApikeyEntered;
          });
        }
      }, [apikey])

    const getEquipments = async () => {
        setExamples(false);
        if (!apikey || !vin) {
            console.log("empty apikey and/or vin");
            return;
        }
        const params = { apikey, vin, marketId }

        setIsImagesLoading(true);
        if(vin.length===17){
            openNotification(t("notification.loading"), t("notification.gettingEquipments") + vin);
        }
        const cachedEquipment = getFromCache(params);
        
        if (cachedEquipment) {
            getEquipmentImagesFromList(Promise.resolve([cachedEquipment.imageList, cachedEquipment.equipmentList]), vin);
            return;
        }
        
        try {
            const response = await _getEquipment(params);
    
            if(response.data) {
                const tempImageList = mapImageList(response.data)
                const tempEquipmentList = mapEquipmentImageList(response.data)
    
                const cacheData = {
                    vin:vin,
                    imageList: tempImageList,
                    equipmentList: tempEquipmentList,
                    expiry: new Date().getTime() + 7200000,
                    apikey: apikey,
                }
                
                let storedEquipments = JSON.parse(localStorage.getItem("equipments"));
    
                if(storedEquipments.length>=5){
                    storedEquipments.shift();
                }
        
                storedEquipments.push(cacheData);
                localStorage.setItem("equipments", JSON.stringify(storedEquipments));
    
                getEquipmentImagesFromList(Promise.resolve([tempImageList, tempEquipmentList]), vin);
            }
        } catch (err) {
            const errorMessage = _handleErrors(err, vin);
            console.log(errorMessage);
            setIsImagesLoading(false);
            openNotification(t("notification.error"), errorMessage);
        } 
    }

    async function loadImages(tempList) {
        await tempList.reduce(async (previousCall, nextImage) => {
            await previousCall;
            const { url, category, description, title, fileName } = nextImage;
            return getImage(url).then(async imageData => {
                const image = convertImage(imageData, url);
                console.log(category + ": is loaded");
                setImageList([...prevImageList.current, { category, title, fileName, description, ...image }])
            }).catch(err => {
                console.log(category + ": failed loading");
                console.log("title: " + title);
                console.log("fileName: " + fileName);
                _notification({ category: t("notification.error"), message: t("notification.failedImage") + category });
                console.log(err);
            })
        }, Promise.resolve());
    }

    async function loadEquipment(tempList) {

        const tempImageList = tempList;
        let imageArray = [];
        let splittedTitle = tempImageList[0].title.split("/")
        let name = splittedTitle[1]
        let description = tempImageList[0].description

        let dividerName = `${splittedTitle[1]}/${splittedTitle[2]}`
        if (description?.text && description.text !== 'N/A') {
            dividerName += `/ ${description.text}`
        }

        if (tempImageList[0].title !== "") {
            await _getUrlImageAll(tempImageList).then(async imageDataArray => {
                
                for (let index = 0; index < imageDataArray.length; index++) {
                    const nextImage = imageDataArray[index];
                    const { category, title, fileName, description, resolution } = tempImageList[index];            
                    const image = convertImage(nextImage.data, tempImageList[index].url);
                    imageArray.push({ category, title, fileName, description, resolution, ...image });
                }

                setEquipmentList([...prevEquipmentList.current, { images: imageArray, name: name, dividerName: dividerName }])
            }).catch(err => {
                console.log("category" + ": failed loading");
                console.log("name: " + name);
                console.log("dividerName: " + dividerName);
                _notification({ dividerName: t("notification.error"), message: t("notification.failedImage") + dividerName });
                console.log(err);
            })
        }
        else {
            await new Promise(function (resolve, reject) {
                setTimeout(resolve, 500);
            });
            setEquipmentList([...prevEquipmentList.current])
        }
    }

    const convertImage = (imageData, imageURL) => {
        let x = imageURL.split(".");
        let imageType = x[x.length - 1]
        let arrayBufferView = new Uint8Array(imageData);
        let blob = new Blob([arrayBufferView], { type: imageType });
        let urlCreator = window.URL || window.webkitURL;
        let url = urlCreator.createObjectURL(blob);
        return { url, blob, imageType }
    }

    const getImage = (imageUrl) => {
    return _getUrlImage({imageUrl}).then(resp => {
            if (resp.data) {
                return resp.data;
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const openNotification = (title, message) => {
        const config = {
            title: title,
            message: message,
            key: prevNotifyKey.current,
            onClose: () => {
                setNotifyKey(undefined);
            }
        }
        setNotifyKey(_notification(config));
    }

    const getFromCache = (params) => {
        const { vin, apikey } = params;
        let cachedFIN = null;
        let equipments= localStorage.getItem("equipments");
        
        if(!equipments){
          localStorage.setItem("equipments", JSON.stringify([]));
          return null;
        }else{
          equipments=JSON.parse(equipments);
        }
        let tempEquipmens= [...equipments];
        let spliceCount=0;
        equipments.forEach((element, index, array)=>{
          if(new Date().getTime() > element.expiry){
            //Remove element if it expired
            tempEquipmens.splice(index-spliceCount, 1);
            localStorage.setItem("equipments",JSON.stringify(tempEquipmens));
            spliceCount++;
          }
          else if(
            element.vin===vin && element.apikey === apikey
            ){
            cachedFIN=element;
          }
        });
        if (!cachedFIN) return null;
        return cachedFIN;
    }

    const getExampleImages = () => {
        setIsExampleImagesLoading(true);
        setExamples(true);
        try { 
            let p1 = Promise.resolve(createExampleImageList());
            getEquipmentImagesFromList(p1, "sample");
        } catch {
            setIsExampleImagesLoading(false);
        }
    }

    const createExampleImageList = () => {
        setIsExampleImagesLoading(true);
        openNotification(t("notification.loading"), t("notification.gettingEquipmentSample"));
        const imageList = mapImageList(ExampleEquipmentImages)
        const equipmentImageList = mapEquipmentImageList(ExampleEquipmentImages)
        return [imageList, equipmentImageList];
    }

    const mapImageList = (imageList) => {
        tempImageList = [];
        const EQUIPMENTS = ["ENGINE", "TRIM", "RIM", "UPHOLSTERY", "PAINT", "PAINT2"];

        for (const equipment of EQUIPMENTS) {
            console.log(`${equipment} is mapping...`);
            const tempList = [];
        
            let description = {};
            if (imageList[equipment]?.desc?.text) {
            description.text = imageList[equipment].desc.text;
            description.code = imageList[equipment].desc.code;
            }
        
            for (const key of Object.keys(imageList[equipment]).sort().reverse()) {
            if (key === "desc") {
                continue;
            }
        
            const tempObj = {
                title: `${equipment} / ${key} / ${ImageTypesRes[key]}`,
                fileName: `${equipment}/${equipment}_${key}`,
                category: equipment,
                subCategory: null,
                description,
                resolution: key,
                url: imageList[equipment][key]
            };
        
            tempList.push(tempObj);
            }
            tempImageList = tempImageList.concat(tempList.filter((obj) => obj.url != null));
        }
        return tempImageList;
    }

    const mapEquipmentImageList = (equipmentImagesList) => {
        try {
            console.log("Equipment is mapping");
                    Object.keys(equipmentImagesList.EQUIPMENTS).forEach(equipment => {
                        //change the order from 0,1,2,0,1,2 to 0,0,1,1,2,2
                        let k = 1 //changed in every iteration (Equipment Code/0, Equipment Code/1,...), thus it works like a pointer of a iteration. 
                        let y = []
    
                        Object.keys(equipmentImagesList.EQUIPMENTS[equipment]).sort().forEach(imageTypes => {
    
                            if (imageTypes === "desc") {
                                return false;
                            }
    
                            let n = 0 // changed in every url(0,0,1,1,2,2) so that label can be changed as 0,1,2
    
                            Object.keys(equipmentImagesList.EQUIPMENTS[equipment][imageTypes].urls).forEach(urls => {
    
                                let description = {};
                                if (equipmentImagesList.EQUIPMENTS[equipment]?.desc?.text !== 'N/A') {
                                    description.code = equipmentImagesList.EQUIPMENTS[equipment].desc.code
                                    description.text = equipmentImagesList.EQUIPMENTS[equipment].desc.text
                                }
    
                                let t = {
                                    title: `EQUIPMENT / ${equipment} / ${urls} / ${imageTypes} / ${ImageTypesRes[imageTypes]}`,
                                    fileName: `EQUIPMENT/${equipment}/${urls}/EQUIPMENT_${equipment}_${urls}_${imageTypes}`,
                                    category: "EQUIPMENT",
                                    description: description,
                                    subCategory: equipment, url: equipmentImagesList.EQUIPMENTS[equipment][imageTypes].urls[urls],
                                    resolution: imageTypes
                                }
                                y.splice(n * k, 0, t)
                                n++
    
                            })
    
                            k++
    
                        })
                        tempEquipmentList = tempEquipmentList.concat(y)
    
                    })
                    tempEquipmentList = tempEquipmentList.concat({
                         title: ``, fileName: ``, category: "EQUIPMENT", subCategory: "", url: "", resolution: ""
                    })

                    return tempEquipmentList;
        } catch (error) { console.log("Equipment has null parameter or there is an Error") }
    }

    async function getEquipmentImagesFromList(r2, vinNumber) {
        r2.then(async (newImageLists) => {
            let newEquipmentList = newImageLists[1];
            let newImageList = newImageLists[0];
            newEquipmentList = filterEquipmentList(newEquipmentList);
            //newEquipmentList.push({ images: [], name: "das////" })
            setEquipmentList([]);
            setImageList([]);

            console.log("Images started to Load");
            await loadImages(newImageList);

            await newEquipmentList.reduce(async (previousCategory, equipmentCategory) => {

                await previousCategory;
                await loadEquipment(equipmentCategory.images);

            }, Promise.resolve());

            console.log("Images finished loading");
            setIsExampleImagesLoading(false);
            setIsImagesLoading(false);
            openNotification(t("notification.finished"), t("notification.gettingImages") + vinNumber);
        }).catch(err => {
            const errorMessage = _handleErrors(err, vinNumber);
            console.log(errorMessage);
            setIsImagesLoading(false);
            openNotification(t("notification.error"), errorMessage);
        });
    }

    return (
        <div className="BusinessTab PackagePanel equipmentsPanel">
            <Card className="PackagePanelInfo" bordered={false}>
                <Row>
                    <Col className="DownloadModels">
                        {t("equipmentsTab.downloadText")}
                    </Col>
                </Row>

                <div className="InputPackage">
                    <Row gutter={20} style={{ display: "flex", justifyContent: "space-evenly", marginTop: "-7px" }}>
                        <Col flex={width < 769 ? "auto" : "2"} ><CustomInput label={t("placeholders.apiKeyPlaceholder")} getValue={setApikey} password={true} /></Col>
                        <Col flex={width < 769 ? "auto" : "2"} ><CustomInput label={t("placeholders.vinNumberPlaceholder")} getValue={setVin} /></Col>
                    </Row>
                </div>

                <Row className="SwitchGroup SingleSwitch">
                    <Col>
                        <div className="CustomSwitchLabel2">{t("components.selectMarket")}
                            <Select
                                showSearch
                                size={"large"}
                                onChange={value => {
                                    setMarketId(value);
                                }}
                                value={marketId}
                                defaultValue={"de_DE"}
                                listHeight={192}
                                style={{ width: 100, margin: 10 }}
                            >

                                {marketList.map(market => {
                                    return <Option key={market.market_code} value={market.market_code}> {market.market_name} </Option>
                                })}
                            </Select>
                        </div>
                    </Col>
                </Row>
                <div className="ButtonPackage">
                    <Row gutter={[20, 20]} justify={"end"} className="ButtonPack">
                        <Col><CustomButton label={t("loadSaveButtons.loadSampleImages")} onClick={getExampleImages} loading={isExampleImagesLoading} disabled={isExampleImagesLoading || isImagesLoading} /></Col>
                        <Col><CustomButton onClick={getEquipments} loading={isImagesLoading} label={t("loadSaveButtons.loadImages")} disabled={!apikey || !vin || isExampleImagesLoading} /></Col>
                    </Row>
                </div>


            </Card>

            {!examples && <EquipmentList imageList={imageList} equipmentList={equipmentList} isImagesLoading={isImagesLoading} saveFileName={saveFileName} rowCount={rowCount} />}
            {examples && <EquipmentList imageList={imageList} equipmentList={equipmentList} rowCount={rowCount} disabled={examples} />}
        </div>
    );
}

export default Equipment;