import React from "react";
import { Row, Col, Collapse } from 'antd'

import CustomInput from './CustomInput'
import CustomSwitch from './CustomSwitch'
import CustomButton from './CustomButton'

import { CaretDownOutlined,RightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const { Panel } = Collapse;
const ExpandIconCaretDownOutlined = ({ isActive }) => <CaretDownOutlined rotate={isActive ? 180 : 0} style={{ transform: "scale(2) scaleX(2.25) scaleY(1.5)", marginRight: 15 }} />
const ExpandIconRightOutlined = ({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />

function JsonWithSearchAndSave(props){
    const {title, save, children} = props;
    const [panels, setPanels] = props.collapse || [];
    const [filterInput, setFilterInput] = props.filterInput;
    const [,setNullFilter] = props.nullFilter;

    const { t } = useTranslation();
   
    const handleInputChange = (e) => {
      setFilterInput(e.target.value)
    }

    function handleNullSwitch(value) {
      setNullFilter(value)
    }

    return (
      <>
        {save && (<Row gutter={20} justify="end" style={{ marginBottom: "40px" }}>
            <Col><CustomButton label={t("loadSaveButtons.saveData")} filledIn={false} onClick={save} style={{ float: "right" }} /></Col>
        </Row>)}
        <Collapse 
            bordered={false}
            expandIcon={ExpandIconCaretDownOutlined}
            className="ModelsMenu"
            expandIconPosition="end">
            <Panel header={title} className="FilterEquipmentClass" id='Specification'>
            <Row align='middle' gutter={[64,20]} justify="start">
                <Col xs={24} sm={8} ><CustomInput label={t("filter.searchEntry")} val={filterInput} onChange={handleInputChange} /></Col>
                <Col xs={24} sm={6}><CustomSwitch label={t("filter.hideNull")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"), t("components.offButton")]} defaultValue={false} getValue={handleNullSwitch} /></Col>
            </Row>
            </Panel>
        </Collapse>
        <Collapse 
          bordered={false}
          expandIcon={ExpandIconRightOutlined}
          className="ModelsMenu"
          {...(panels && setPanels && {
            activeKey: panels,
            onChange: setPanels,
          })}
        >
          {children}
        </Collapse>
      </>  
    )
  }
  
  export default JsonWithSearchAndSave;