export const getFromCache = (params) => {
    const { vin, apikey, queries } = params;
    const { night, roofOpen, background, uniformImage, fileFormat, cropped, cropPosition, backgroundItem } = queries;
    let images = localStorage.getItem("images");

    if (!images) {
      localStorage.setItem("images", JSON.stringify([]));
      return null;
    } else {
      images = JSON.parse(images);
    }
    let tempImages = [...images];
    let spliceCount = 0;
    images.forEach((element, index, array) => {
      if (new Date().getTime() > element.expiry) {
        //Remove element if it expired
        tempImages.splice(index - spliceCount, 1);
        localStorage.setItem("images", JSON.stringify(tempImages));
        spliceCount++;
      }
      else if (
        element.vin === vin && element.apikey === apikey &&
        element.night === night && element.roofOpen === roofOpen &&
        element.background === background && element.fileFormat === fileFormat &&
        element.cropped === cropped &&
        element.cropPosition === cropPosition &&
        element.uniformImage === uniformImage &&
        element.backgroundItem === backgroundItem
      ) {
        // what was this block for?
      }
    });
}
