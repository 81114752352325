import { useState } from 'react';
import { Select } from 'antd';
import _notification from '../Utils/notification';
import { useTranslation } from "react-i18next";

const { Option } = Select;
const fixedWithCSS = { width: 100, height: 34, marginRight: 10 };
const adjustableWithCSS = { height: 34, marginRight: 10 };

function CustomDropdown(props) {

    const { label, value, size, options, defaultValue, disabled, setValue, adjustableWith, backgroundSelectMessage = false, showSearch, notify=true } = props;

    const [key, setKey] = useState();
    const { t } = useTranslation();
    function onChange(value) {
        setValue(value);
        notify && openNotification(value);
    }

    function openNotification(value) {
        const notificationType = t("notification.switched");
        const background = backgroundSelectMessage ? t(`imagesTab.backgrounds.${value}`) + " (" + value + ")" : value;
        const message = notificationType + " " + background;
        const config = {
            title: label,
            message: message,
            duration: 5,
            key,
            onClose: () => {
                setKey(undefined);
            }
        }
        setKey(_notification(config));
    }


    return (
        <div className="CustomSwitch CustomSelect">
            <div className="CustomSwitchLabel"> {label} </div>
            <Select
                showSearch={showSearch}
                style={adjustableWith ? adjustableWithCSS : fixedWithCSS}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                size={size}
                disabled={disabled}
                dropdownMatchSelectWidth={!adjustableWith}
            >
                {options.map((opt) => <Option key={opt.id} value={opt.value}>{opt.name}</Option>)}
            </Select>
        </div>
    )
}

export default CustomDropdown;