export default (obj) => {
    const _checkJSON = (value) => { //recursive null founder
      for (let tempValue of Object.values(value)) {
  
        if (typeof (tempValue) === "object" && tempValue !== null) {
          if (_checkJSON(tempValue)) return true // if the object is JSON, control their keys
        }
        else if (tempValue !== null) { //if one of the key is not a null entry, then do not delete it from the JSON object
          return true
        }
      }
      return false; // Object do not found, can be deleted
    }
  
    return JSON.parse(JSON.stringify(obj, (key, value) => {
      if (Array.isArray(value)) {
        return value.filter(x => x);
      }
      if (typeof (value) === "object" && value !== null && _checkJSON(value)) { //if the object is JSON
        return value
      }
      return value ?? undefined
    }));
}