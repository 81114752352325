import { Popover } from "antd";
import CustomInput from "./CustomInput";
import ImageInput from "./ImageInput";
import Content from "./Content";

function CustomInputUpload({name, input, img, label, onChange, onImageSet, onImageDelete, popoverTitle}) {
  const isObj = input && typeof input === "object";
  let val = isObj ? input?.fileName : input;
  let imgUrl = isObj ? input?.url : img?.url || input;
  let disabled = isObj ? true : (img?.fileName && img?.url)
  return (
      <div style={{display: "flex", justifyContent:"space-between"}} className="CustomImageInput CustomImageInput--small">
          <div style={{width: "100%"}}>
              <CustomInput  style={{flex: 1}} name={name} val={val} label={label} onChange={onChange} disabled={disabled}/>
          </div>
          <Popover content={<Content url={imgUrl} />} title={popoverTitle}>
              <div>
                  <ImageInput uploadText="" oldImageUrl={imgUrl} name={name} onImageSet={onImageSet} onImageDelete={onImageDelete} fileSize={10} /> 
              </div>
          </Popover>
      </div> 
  )
}

export default CustomInputUpload;