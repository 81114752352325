export function filterEquipmentList(imageList) {

    const tempList = [{}];
    tempList[0].images = []

    if (imageList.length > 0) {

        let previousImage = ''
        let currentImage = imageList[0].title.split('/')

        tempList[tempList.length - 1].images.push(imageList[0])
        tempList[tempList.length - 1].name = currentImage[1] + "/" + currentImage[2]

        try {
            for (let i = 1; i < imageList.length; i++) {
                previousImage = imageList[i - 1].title.split('/')
                currentImage = imageList[i].title.split('/')

                if (previousImage[1] !== currentImage[1] || previousImage[2] !== currentImage[2]) {
                    tempList.push({})
                    tempList[tempList.length - 1].images = []
                }
                tempList[tempList.length - 1].images.push(imageList[i])
                tempList[tempList.length - 1].name = currentImage[1] + "/" + currentImage[2]
            }
        } catch (e) { 
        console.log(e)
        return null }
    }

    return tempList
}