import { Row, Col, Card, Divider, Popover } from 'antd'
import React from 'react';

function EquipmentCategoryList(props) {
    const { imageList, rowCount = 6 } = props;
    const renderedList = React.useMemo(() => {
        const tempList = [];
        for (let j = 0; j < imageList.length; j++) {
            tempList.push({})
            tempList[j].name = imageList[j].name;
            tempList[j].dividerName = imageList[j].dividerName;
            tempList[j].images = []

            for (let i = 0; i < imageList[j].images.length; i += rowCount) {
                tempList[j].images.push(imageList[j].images.slice(i, i + rowCount));
            }
        }
        
        return tempList;
    }, [imageList, rowCount]);


    return (
        <div className='EquipmentCategoryList'>
            <Row className="ImageList">

                {(imageList[0].images.length > 0 && renderedList.length > 0) && renderedList.map((imageRenderList, x) => {
                    return (
                        <>
                            <Divider orientation="left" key={imageRenderList.dividerName}>{imageRenderList.dividerName}</Divider>
                            {
                                imageRenderList.images.map((rowList, indexRow) => {
                                    const fileName = rowList[indexRow]?.fileName;
                                    return (
                                        <Row key={fileName} gutter={16} style={{ width: "-webkit-fill-available" }} >
                                            {
                                                rowList.map((key, indexCol) => {
                                                    const id = rowList[indexRow]?.url?.split('/')?.pop()?.split('-')?.pop();
                                                    return (

                                                        <Col key={id} span={24 / rowCount} >
                                                            <ImageCard url={key.url} title={key.title} blob={key.blob} imageType={key.imageType} />
                                                        </Col>
                                                    )
                                                })}
                                        </Row>
                                    )
                                })
                            }
                        </>)
                })}
            </Row>
        </div>
    )
}


function ImageCard(props) {
    const { url, title, imageType } = props;
    const download = () => {
        const link = document.createElement("a");
        link.href = url;
        link.download = `${title}.${imageType}`
        link.click();
    }
    return (
        <Popover overlayClassName='ImagePopOver' content={<Card className="ImageCard" bordered={false} >
            <img className="EquipmentCardUrl ImagePopover EquipmentPopover" src={url} alt={title} /></Card>}
            title={title} mouseEnterDelay="0.3">
            <Card className="ImageCard" onClick={() => download()} bordered={false}>
                <img className="EquipmentCardUrl" src={url} alt={title} />
                <div className="ImageCardTitle" >{title}</div>
            </Card>
        </Popover>
    )
}

export default React.memo(EquipmentCategoryList);