import { useEffect, useState } from "react"
import { Switch } from 'antd'
import _notification from '../Utils/notification';
import { useTranslation } from "react-i18next";

function CustomSwitch(props) {
  const { label, onBtn, offBtn, defaultValue = false, val, getValue, disabled = false, onChange, status =" ", className} = props;

  const [value, setValue] = useState(defaultValue);
  const [key, setKey] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const openNotification = () => {
    const config = {
      title: label,
      message: `${t("notification.switched")} ${value ? status[0] :status[1]}`,
      duration: 5,
      key,
      onClose: () => {
        setKey(undefined);
      }
    }
    setKey(_notification(config));
  }

  const handleChange = (e) => {
    setValue(e);
    getValue(e);
    openNotification();
  }

  return (
    <div className={`CustomSwitch ${className}`}>
      <div className="CustomSwitchLabel" data-testid = "label">{label}</div>
      <div className="CustomSwitchOnOff" >
        <div className="OnOffLabel OnLabel" data-testid = "onLabel">{onBtn}</div>
        <Switch checked={val || value} onChange={onChange || ((e) => handleChange(e))} disabled={disabled} />
        <div className="OnOffLabel OffLabel" data-testid = "offLabel">{offBtn}</div>
      </div>
    </div>
  )
}

export default CustomSwitch;