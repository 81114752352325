import { Row, Col, Collapse } from "antd";
import CustomButton from "./CustomButton";
import ImageRenderList from "./ImageRenderList";
import { RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import ImagesPanel from "./ImagesPanel";
import { useEffect, useState } from "react";
import Image360View from "./Image360View";
import ImageCompose from "./ImageCompose";

const { Panel } = Collapse;

function ExampleImageList(props) {
  const { imageList, rowCount = 6 } = props;

  const count = imageList["W1K2971552A00000"].length;
  const exampleImageName = "W1K2971552A00000";

  const { t } = useTranslation();
  const [panels, setPanels] = useState([]);
  const [availablePanels, setAvailablePanels] = useState([]);

  const panelHandler = (mode) => {
    switch (mode) {
      case 0: //Show All
        setPanels(availablePanels);
        break;
      case 1: //Collapse All
        setPanels([]);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setAvailablePanels([exampleImageName]);
  }, [imageList]);

  const expandIcon = ({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />;

  return (
    <div>
      <Row justify="space-between" style={{ marginBottom: "40px" }}>
        <Col span={12}>
          <div style={{ paddingTop: "10px" }} className="ImageCounter">
            <strong>{`${count} ${t("infoTexts.imagesFound")}`}</strong>
          </div>
        </Col>
        <Col span={4}>
          <CustomButton
            label={t("loadSaveButtons.saveImages")}
            filledIn={false}
            disabled={true}
            style={{ float: "right" }}
          />
        </Col>
      </Row>
      <Row justify="space-between" style={{ marginBottom: "40px" }}>
        <Col span={24}>
          <ImagesPanel
            panelHandler={panelHandler}
            panels={panels}
            availablePanels={availablePanels}
          />
        </Col>
      </Row>
      <Image360View images={imageList} />
      <ImageCompose images={imageList} sample={true} />
      <Collapse
        activeKey={panels}
        onChange={setPanels}
        bordered={false}
        expandIcon={expandIcon}
      >
        <Panel header={exampleImageName} key={exampleImageName} className="ModelsClasses">
          <ImageRenderList
            vin={exampleImageName}
            imageList={imageList[exampleImageName]}
            rowCount={rowCount}
            innerPanels={true}
          />
        </Panel>
      </Collapse>
      <Row
        justify="space-between"
        style={{ marginTop: "40px", marginBottom: "120px" }}
      >
        <Col span={4}></Col>
        <Col span={4}>
          <CustomButton
            label={t("loadSaveButtons.saveImages")}
            filledIn={false}
            disabled={true}
            style={{ float: "right" }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ExampleImageList;
