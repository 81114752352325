import {Col, Row} from "antd";
import {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import Exterior360View from "./Exterior360View";
import Interior360View from "./Interior360View";
import useWidth from "../Utils/useWidth";
import {checkImage} from "../Utils/checkImage";
import { compareTitle } from "../Utils/compareTitle";
import { WbText, WbIcon } from "@workbench/react";

const imageHelper = async (images, key) => {
    const promiseList = images.filter((image) => image.title.includes(key)).sort(compareTitle).map((image) => checkImage(image.url));
    return Promise.allSettled(promiseList)
        .then((img) => img.filter(element=>element.status==='fulfilled').map(element=>element.value.path))
        .catch((e) => console.log(e));
};

function Image360ViewContainer(props) {
    const { images, currentVin } = props;
    const [interiorImages, setInteriorImages] = useState([]);
    const [externalImages, setExternalImages] = useState([]);
    const width = useWidth();

    const [resetExteriorView, setResetExteriorView] = useState(true);
    const [disableExteriorViewReset, setDisableExteriorViewReset] = useState(false);
    const [resetInteriorView, setResetInteriorView] = useState(true);
    const [disableInteriorViewReset, setDisableInteriorViewReset] = useState(false);


    const renderResetExteriorView = (event) => {
        setResetExteriorView(false);
        setDisableExteriorViewReset(true);
        setTimeout(() => {setResetExteriorView(true)}, 500);
        setTimeout(() => {setDisableExteriorViewReset(false)}, 2000);
    };

    const renderResetInteriorView = () => {
        setResetInteriorView(false);
        setDisableInteriorViewReset(true);
        setTimeout(() => {setResetInteriorView(true)}, 500);
        setTimeout(() => {setDisableInteriorViewReset(false)}, 2000);
    };

    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            console.log(images[currentVin])
            const intImages = await imageHelper(images[currentVin], "BIS");
            const extImages = await imageHelper(images[currentVin], "EXT");
            console.log(intImages)
            setInteriorImages(intImages);
            setExternalImages(extImages);
        })();
    }, [currentVin, images]);

    const calculateSpan = () => {
        if(width < 768) return 24;
        return interiorImages.length === 6 ? 11 : 24
    }

    return (
        <Row className="View360Container" gutter={[32, 32]}>
            {externalImages.length >= 4 && (
                <Col span={24}>
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Row gutter={[16,16]} align="middle">
                                <Col>
                                    <WbText size="l">
                                            {t("imagesTab.image360View.headingExterior")}
                                    </WbText>
                                </Col>
                                <Col>
                                    <WbIcon style={disableExteriorViewReset ? {color: "grey"} : {}} name="bds/reset/24" onClick={renderResetExteriorView} onKeyDown={renderResetExteriorView}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            {resetExteriorView && <Exterior360View images={externalImages}/>}
                        </Col>
                    </Row>
                </Col>
            )}
            {interiorImages.length === 6 && (
                <Col span={24}>
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Row gutter={[16,16]} align="middle">
                                <Col>
                                    <WbText size="l">
                                        {t("imagesTab.image360View.headingInterior")}
                                    </WbText>
                                </Col>
                                <Col>
                                    <WbIcon style={disableExteriorViewReset ? {color: "grey"} : {}} name="bds/reset/24" onClick={renderResetInteriorView} onKeyDown={renderResetInteriorView}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            {resetInteriorView && <Interior360View images={interiorImages}/>}
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    );
}

export default Image360ViewContainer;
