import { Col, Collapse, Row } from "antd";
import { useEffect, useState } from "react";
import CustomButton from "./CustomButton";
import { saveAs } from 'file-saver'
import { RightOutlined } from '@ant-design/icons'
import EquipmentRenderList from "./EquipmentRenderList";
import EquipmentCategoryList from "./EquipmentCategoryList";
import EquipmentFilter from "./EquipmentFilter";
import _notification from "../Utils/notification";
import EquipmentPanel from "./EquipmentPanel";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

const EquipmentList = (props) => {

    const { imageList, equipmentList, isImagesLoading, saveFileName, rowCount = 6, disabled } = props;
    let count = imageList.length + (equipmentList.length * 5);

    const [imageFilter, setImageFilter] = useState([""]);
    const [equipmentFilter, setEquipmentFilter] = useState([""]);
    const [equipmentRenderList, setEquipmentRenderList] = useState([]);
    const [filter, setFilter] = useState(true);

    const [saving, setSaving] = useState(false);
    let availableCategories = []

    const [panels, setPanels] = useState(availableCategories);
    const [availablePanels, setAvailablePanels] = useState(availableCategories);

    const { t } = useTranslation()

    const engineList = imageList.filter(image => image.category === "ENGINE");
    const trimList = imageList.filter(image => image.category === "TRIM");
    const rimList = imageList.filter(image => image.category === "RIM");
    const upholesteryList = imageList.filter(image => image.category === "UPHOLSTERY");
    const paintList1 = imageList.filter(image => image.category === "PAINT");
    const paintList2 = imageList.filter(image => image.category === "PAINT2");

    useEffect(() => {

        availableCategories = []
        if (engineList.length > 0) availableCategories.push("1")
        if (trimList.length > 0) availableCategories.push("2")
        if (rimList.length > 0) availableCategories.push("3")
        if (upholesteryList.length > 0) availableCategories.push("4")
        if (paintList1.length > 0) availableCategories.push("5")
        if (paintList2.length > 0) availableCategories.push("6")
        availableCategories.push("7")
        setAvailablePanels(availableCategories)

    }, [imageList])

    function applyImageFilter(tempEquipmentRendertList, imageFilter, filter) {
        if (filter) {
            if (imageFilter[0] !== "") {
                for (const group of tempEquipmentRendertList) {
                    group.images = group.images.filter(image => imageFilter.includes(image.resolution))
                }
            }
        } else {
            for (const group of tempEquipmentRendertList) {
                group.images = [group.images[0]];
            }
        }
        return tempEquipmentRendertList;
    }

    function filterEquipmentListByGroup(tempEquipmentRendertList, equipmentFilter) {
        let filteredList = [];
        for (let group of tempEquipmentRendertList) {
            if (group.name.includes(equipmentFilter)) {
                filteredList.push(group);
            }
        }
        return filteredList;
    }



    useEffect(() => {

        let tempEquipmentRendertList = JSON.parse(JSON.stringify(equipmentList));
        if (imageFilter[0] === "" && equipmentFilter[0] === "" && equipmentList.length > 0 && filter) {
            setEquipmentRenderList(equipmentList)
        }
        else if (equipmentList.length > 0) {
            if (equipmentFilter[0] !== "") {
                tempEquipmentRendertList=filterEquipmentListByGroup(tempEquipmentRendertList, equipmentFilter)

            }
            tempEquipmentRendertList=applyImageFilter(tempEquipmentRendertList, imageFilter, filter)

            if (tempEquipmentRendertList.length === 0) {
                tempEquipmentRendertList.push({ images: [], name: "" })
            }

            if ((tempEquipmentRendertList[0].name === "" || tempEquipmentRendertList[0].images.length === 0) && !isImagesLoading) _notification({ title: t("notification.warning"), message: t("notification.notMatchingFilters"), duration: 10 });
            setEquipmentRenderList(tempEquipmentRendertList)
        }
        else if (equipmentList.length === 0) {
            setEquipmentRenderList(tempEquipmentRendertList)
        }
    }, [equipmentFilter, equipmentList, filter]);

    const [totalOptionCodeCount, setTotalOptionCodeCount] = useState(0)
    const [optionCodeCounts, setOptionCodeCounts] = useState({
        engine: 0,
        trim: 0,
        rim: 0,
        upholestry: 0,
        paint1: 0,
        paint2: 0,
        equipment: 0
    });

    useEffect(() => {
        if (imageList.length === 0) return;

        setOptionCodeCounts({
            engine: engineList.length > 0 ? 1 : 0,
            trim: trimList.length > 0 ? 1 : 0,
            rim: rimList.length > 0 ? 1 : 0,
            upholestry: upholesteryList.length > 0 ? 1 : 0,
            paint1: paintList1.length > 0 ? 1 : 0,
            paint2: paintList2.length > 0 ? 1 : 0,
            equipment: (equipmentList.length > 0 && equipmentList[0].images.length > 0) ? equipmentList.length : 0,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageList, equipmentList])

    useEffect(() => {
        const counts = Object.values(optionCodeCounts)
        if (counts.every(count => count === 0)) return;
        const totalCounts = counts.reduce((acc, curr) => acc + curr);
        setTotalOptionCodeCount(totalCounts)
    }, [optionCodeCounts])

    const saveImages = () => {

        const zip = require("jszip")();
        let imageFolder = zip.folder(saveFileName);

        imageList.forEach(({ title, blob, fileName, imageType }) => {
            imageFolder.file(`${fileName}.${imageType}`, blob);
        });


        equipmentList.forEach(category => {
            category.images.forEach(({ title, blob, fileName, imageType }) => {
                imageFolder.file(`${fileName}.${imageType}`, blob);
            });
        });


        zip.generateAsync({ type: "blob" }).then(content => {
            saveAs(content, saveFileName);
            setSaving(false);
        }).catch(err => {
            console.log(err);
            setSaving(false);
        })
    }

    const panelHandler = (mode) => {
        switch (mode) {
            case 0: //Show All
                setPanels(availablePanels)
                break;

            case 1: //Collapse All
                setPanels([])
                break;
        }
    }

    const equipmentFilterHandler = (equipmentFilter, imageFilter) => {
        setImageFilter(imageFilter);
        setEquipmentFilter(equipmentFilter);
    }

    const oneFilterHandler = (filterValue) => {

        setFilter(filterValue);
    }
    const expandIcon=({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />

    const countText = `${count} ${count > 1 ? t("infoTexts.imagesFound") : t("infoTexts.imageFound")}`;
    const totalText = `${totalOptionCodeCount} ${totalOptionCodeCount > 1 ? t("infoTexts.optionCodesFound") : t("infoTexts.imageFound")}`;

    return (

        <div>

            {imageList.length > 0 && <div>
                <Row justify="space-between" style={{ marginBottom: "40px" }} align="middle">
                    <Col span={12}>
                        <div style={{ paddingTop: "10px" }} className="ImageCounter">
                            <strong>{countText} / {totalText}</strong>
                        </div>
                    </Col>
                    <Col span={6} style={{}} ><CustomButton label={t("loadSaveButtons.saveImages")} filledIn={false} onClick={() => saveImages()} loading={saving} disabled={disabled} style={{ float: "right" }} /></Col>
                </Row>
            </div>}

            {imageList.length > 0 && <div>
                <EquipmentPanel panelHandler={panelHandler} panels={panels} availablePanels={availablePanels} />
            </div>}

            <Collapse bordered={false}
                activeKey={panels}
                onChange={setPanels}
                expandIcon={expandIcon}
                className="ModelsMenu">

                {engineList.length > 0 &&
                    <Panel header={t("equipmentsTab.engineComponent")} key="1" className="ModelsClasses">
                        <EquipmentRenderList imageList={engineList} rowCount={rowCount} />
                    </Panel>}

                {trimList.length > 0 &&
                    <Panel header={t("equipmentsTab.trimComponent")} key="2" className="ModelsClasses">
                        <EquipmentRenderList imageList={trimList} rowCount={rowCount} />
                    </Panel>}

                {rimList.length > 0 &&
                    <Panel header={t("equipmentsTab.rimComponent")} key="3" className="ModelsClasses">
                        <EquipmentRenderList imageList={rimList} rowCount={rowCount} />
                    </Panel>}

                {upholesteryList.length > 0 &&
                    <Panel header={t("equipmentsTab.upholsteryComponent")} key="4" className="ModelsClasses">
                        <EquipmentRenderList imageList={upholesteryList} rowCount={rowCount} />
                    </Panel>}

                {paintList1.length > 0 &&
                    <Panel header={t("equipmentsTab.paintComponent")} key="5" className="ModelsClasses">
                        <EquipmentRenderList imageList={paintList1} rowCount={rowCount} />
                    </Panel>}

                {paintList2.length > 0 &&
                    <Panel header={t("equipmentsTab.upperPaintComponent")} key="6" className="ModelsClasses">
                        <EquipmentRenderList imageList={paintList2} rowCount={rowCount} />
                    </Panel>}

                {equipmentRenderList.length > 0 && <EquipmentFilter key="efilter" equipmentFilterHandler={equipmentFilterHandler} oneFilterHandler={oneFilterHandler} />}

                {equipmentRenderList.length > 0 &&
                    <Panel header={t("equipmentsTab.equipmentComponent")} key="7" className="ModelsClasses">
                        <EquipmentCategoryList imageList={equipmentRenderList} rowCount={rowCount} />
                    </Panel>}
            </Collapse>

            {imageList.length > 0 &&
                <Row justify="space-between" style={{ marginTop: "40px", marginBottom: "120px" }}>
                    <Col span={4}></Col>
                    <Col span={4}><CustomButton label={t("loadSaveButtons.saveImages")} filledIn={false} onClick={() => saveImages()} loading={saving} disabled={disabled} style={{ float: "right" }} /></Col>
                </Row>}


        </div>
    );
}

export default EquipmentList;