export const marketList = [
    {
        market_code: "en_AE",
        market_name: "AE (en)"
    },
    {
        market_code: "en_AR",
        market_name: "AR (en)"
    },
    {
        market_code: "de_AT",
        market_name: "AT (de)"
    },
    {
        market_code: "en_BE",
        market_name: "BE (en)"
    },
    {
        market_code: "bg_BG",
        market_name: "BG (bg)"
    },
    {
        market_code: "en_BH",
        market_name: "BH (en)"
    },
    {
        market_code: "en_CA",
        market_name: "CA (en)"
    },
    {
        market_code: "de_CH",
        market_name: "CH (de)"
    },
    {
        market_code: "fr_CH",
        market_name: "CH (fr)"
    },
    {
        market_code: "it_CH",
        market_name: "CH (it)"
    },
    {
        market_code: "es_CU",
        market_name: "CU (es)"
    },
    {
        market_code: "de_DE",
        market_name: "DE (de)"
    },
    {
        market_code: "da_DK",
        market_name: "DK (da)"
    },
    {
        market_code: "es_DO",
        market_name: "DO (es)"
    },
    {
        market_code: "en_DO",
        market_name: "DO (en)"
    },
    {
        market_code: "es_ES",
        market_name: "ES (es)"
    },
    {
        market_code: "en_ES",
        market_name: "ES (en)"
    },
    {
        market_code: "fi_FI",
        market_name: "FI (fi)"
    },
    {
        market_code: "en_FI",
        market_name: "FI (en)"
    },
    {
        market_code: "fr_FR",
        market_name: "FR (fr)"
    },
    {
        market_code: "en_GB",
        market_name: "GB (en)"
    },
    {
        market_code: "hu_HU",
        market_name: "HU (hu)"
    },
    {
        market_code: "en_IE",
        market_name: "IE (en)"
    },
    {
        market_code: "en_IL",
        market_name: "IL (en)"
    },
    {
        market_code: "de_IT",
        market_name: "IT (de)"
    },
    {
        market_code: "it_IT",
        market_name: "IT (it)"
    },
    {
        market_code: "en_KR",
        market_name: "KR (en)"
    },
    {
        market_code: "ko_KR",
        market_name: "KR (ko)"
    },
    {
        market_code: "fr_MA",
        market_name: "MA (fr)"
    },
    {
        market_code: "en_MA",
        market_name: "MA (en)"
    },
    {
        market_code: "en_NL",
        market_name: "NL (en)"
    },
    {
        market_code: "ar_OM",
        market_name: "OM (ar)"
    },
    {
        market_code: "en_OM",
        market_name: "OM (en)"
    },
    {
        market_code: "pl_PL",
        market_name: "PL (pl)"
    },
    {
        market_code: "en_PL",
        market_name: "PL (en)"
    },
    {
        market_code: "ro_RO",
        market_name: "RO (ro)"
    },
    {
        market_code: "en_RO",
        market_name: "RO (en)"
    },
    {
        market_code: "sv_SE",
        market_name: "SE (sv)"
    },
    {
        market_code:"en_SL",
        market_name:"SL (en)"
    },
    {
        market_code:"sl_SL",
        market_name:"SL (sl)"
    },
    {
        market_code: "en_US",
        market_name: "US (en)"
    },
    {
        market_code: "en_XH",
        market_name: "XH (en)"
    },
]
