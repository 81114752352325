import { Col, Row, Popover, Collapse, Typography, Input, Slider, Divider } from "antd";
import { useEffect, useState, useRef } from "react";
import _notification from "../Utils/notification";
import CustomInput from "./CustomInput";
import CustomSwitch from "./CustomSwitch";

import CustomButton from './CustomButton'
import Joi from "joi"
import { CaretDownOutlined } from '@ant-design/icons';
import PanelHeader from "./PanelHeader";
import { useTranslation } from "react-i18next";
import saveJson from "../Utils/saveJson";
import Content from "./Content";
import CustomInputUpload from "./CustomInputUpload";

const { Panel } = Collapse;

let modifiedEndImageTransparent = "";

const VideoAdjust = (props) => {

    const { videoAdjustData, setVideoAdjustData, initialVideoData, width, scenes, configurationSaved, setConfigurationSaved } = props;

    const { t } = useTranslation();

    const videoEditText = t("videosTab.editSceneTablesPopover.videoAdjustPanel.videoEditText")
    const videoEditTextStartScene = t("videosTab.editSceneTablesPopover.videoAdjustPanel.videoEditTextStartScene")
    const videoEditTextSpecScene = t("videosTab.editSceneTablesPopover.videoAdjustPanel.videoEditTextSpecScene")
    const videoEditTextOutroScene = t("videosTab.editSceneTablesPopover.videoAdjustPanel.videoEditTextOutroScene")


    // Data Tables

    const editVideoEndSceneDataTable = [
        {
            key: 1,
            type: t("videosTab.endSceneEntries.dealerLogo"),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoEndSceneDataTable.dealerLogo"),
        },
        {
            key: 2,
            type: t("videosTab.endSceneEntries.backgroundImage"),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoEndSceneDataTable.backgroundImage"),
        },
        {
            key: 3,
            type: t("videosTab.endSceneEntries.textMessage"),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoEndSceneDataTable.textMessage")
        },
        {
            key: 4,
            type: t("videosTab.endSceneEntries.buttonLabel"),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoEndSceneDataTable.buttonLabel")
        },
        {
            key: 5,
            type: t("videosTab.endSceneEntries.buttonLink"),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoEndSceneDataTable.buttonLink"),
        },
        {
            key: 6,
            type: t("videosTab.endSceneEntries.qrCode"),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoEndSceneDataTable.qrCode"),
        },
        {
            key: 7,
            type: t("videosTab.endSceneEntries.vehiclePerspective"),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoEndSceneDataTable.vehiclePerspective"),
        },

    ]

    const editVideoStartSceneDataTable = [
        {
            key: 1,
            type: t("videosTab.editVideoSceneLabels.sceneLabel", { number: 1 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoStartSceneDataTable.scene1text"),
        },
        {
            key: 2,
            type: t("videosTab.editVideoSceneLabels.sceneLabel", { number: 2 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoStartSceneDataTable.scene2text")
        },
        {
            key: 3,
            type: t("videosTab.editVideoSceneLabels.sceneLabel", { number: 3 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoStartSceneDataTable.scene3text")
        }
    ]

    const editVideoSpecSceneDataTable = [
        {
            key: 1,
            type: t("videosTab.editVideoSceneLabels.titleLabel", { number: 1 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoSpecSceneDataTable.title1")
        },
        {
            key: 2,
            type: t("videosTab.editVideoSceneLabels.sceneLabel", { number: 1 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoSpecSceneDataTable.text1")
        },
        {
            key: 3,
            type: t("videosTab.editVideoSceneLabels.titleLabel", { number: 2 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoSpecSceneDataTable.title2")
        },
        {
            key: 4,
            type: t("videosTab.editVideoSceneLabels.sceneLabel", { number: 2 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoSpecSceneDataTable.text2")
        },
        {
            key: 5,
            type: t("videosTab.editVideoSceneLabels.titleLabel", { number: 3 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoSpecSceneDataTable.title3")
        },
        {
            key: 6,
            type: t("videosTab.editVideoSceneLabels.sceneLabel", { number: 3 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoSpecSceneDataTable.text3")
        },
        {
            key: 7,
            type: t("videosTab.editVideoSceneLabels.titleLabel", { number: 4 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoSpecSceneDataTable.title4")
        },
        {
            key: 8,
            type: t("videosTab.editVideoSceneLabels.sceneLabel", { number: 4 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoSpecSceneDataTable.text4")
        },
        {
            key: 8,
            type: t("videosTab.editVideoSceneLabels.videoEditBlackColor"),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoSpecSceneDataTable.textColourBlack")
        },
    ]

    const editVideo2EquipmentScene = [
        {
            key: 1,
            type: t("videosTab.editVideoSceneLabels.titleLabel", { number: "" }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideo2EquipmentScene.title")
        },
        {
            key: 2,
            type: t("videosTab.editVideoSceneLabels.imageLabel", { number: 1 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideo2EquipmentScene.image1")
        },
        {
            key: 3,
            type: t("videosTab.editVideoSceneLabels.textLabel", { number: 1 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideo2EquipmentScene.text1")
        },
        {
            key: 4,
            type: t("videosTab.editVideoSceneLabels.imageLabel", { number: 2 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideo2EquipmentScene.image2")
        },
        {
            key: 5,
            type: t("videosTab.editVideoSceneLabels.textLabel", { number: 2 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideo2EquipmentScene.text2")
        },
    ]

    const editVideo3EquipmentScene = [
        {
            key: 1,
            type: t("videosTab.editVideoSceneLabels.titleLabel", { number: "" }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideo3EquipmentScene.title")
        },
        {
            key: 2,
            type: t("videosTab.editVideoSceneLabels.imageLabel", { number: 1 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideo3EquipmentScene.image1")
        },
        {
            key: 3,
            type: t("videosTab.editVideoSceneLabels.textLabel", { number: 1 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideo3EquipmentScene.text1")
        },
        {
            key: 4,
            type: t("videosTab.editVideoSceneLabels.imageLabel", { number: 2 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideo3EquipmentScene.image2")
        },
        {
            key: 5,
            type: t("videosTab.editVideoSceneLabels.textLabel", { number: 2 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideo3EquipmentScene.text2")
        },
        {
            key: 6,
            type: t("videosTab.editVideoSceneLabels.imageLabel", { number: 3 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideo3EquipmentScene.image3")
        },
        {
            key: 7,
            type: t("videosTab.editVideoSceneLabels.textLabel", { number: 3 }),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideo3EquipmentScene.text3")
        }
    ]

    const editVideoOutroSceneDataTable = [
        {
            key: 1,
            type: t("videosTab.editVideoSceneLabels.outroText"),
            desc: t("videosTab.editSceneTablesPopover.videoAdjustPanel.editVideoOutroSceneDataTable.outroText")
        }
    ]

    // Destruct initial /video response 
    const { logo,
        endBtnText,
        endBkgImage,
        endBtnLink,
        endImageTransparent,
        endText,
        qr,
        scene1text,
        scene2text,
        scene3text,
        outroText,
        details: {
            textcolorblack,
            title1,
            text1,
            title2,
            text2,
            title3,
            text3,
            title4,
            text4
        },
        packages3,
        packages2
    } = initialVideoData || {}

    const startScene = { scene1text, scene2text, scene3text };
    const specScene = { title1, text1, title2, text2, title3, text3, title4, text4, textcolorblack };
    const outroScene = { outroText }
    const packages2Scene = { packages2 }
    const packages3Scene = { packages3 }
    const endScene = { logo, endBtnText, endBkgImage, endBtnLink, endImageTransparent, endText, qr }

    // Toggle state of scenes
    const { start, outro, noSpec, noEndScreen, noTwoEquipmentScene, noThreeEquipmentScene } = scenes;

    const defaultVideoAdjustData = { ...startScene, ...specScene, ...packages2Scene, ...packages3Scene, ...outroScene, ...endScene }
    const initialVideoAdjustData = videoAdjustData || defaultVideoAdjustData
    const [inputs, setInputs] = useState(initialVideoAdjustData)
    const [newStringForImage, setNewStringForImage] = useState(endImageTransparent);
    const [imgs, setImgs] = useState({});

    const fileInputRef = useRef(null);

    const [filterSpan, setFilterSpan] = useState(calculateSpan(width));

    const updateVideoAdjustData = (newData) => {
        setVideoAdjustData(newData);
    }

    useEffect(() => {
        setFilterSpan(calculateSpan(width))
    }, [width])

    useEffect(() => {
        const value = findSliderValueOfPerspective();
        sliderCarouselChange(value);
    }, [endImageTransparent])


    // Array of related input keys for the active scenes ordered by scene
    // Uset for extracting only active scene values from inputs
    const [activeInputKeys, setActiveInputKeys] = useState([])
    useEffect(() => {
        let keys = [];
        if (!start) keys = [...keys, ...Object.keys(startScene)]
        if (!noSpec) keys = [...keys, ...Object.keys(specScene)]
        if (!outro) keys = [...keys, ...Object.keys(outroScene)]
        if (!noEndScreen) keys = [...keys, ...Object.keys(endScene)]
        if (!noTwoEquipmentScene) keys = [...keys, ...Object.keys(packages2Scene)]
        if (!noThreeEquipmentScene) keys = [...keys, ...Object.keys(packages3Scene)]
        setActiveInputKeys(keys);
    }, [start, outro, noSpec, noEndScreen, noTwoEquipmentScene, noThreeEquipmentScene])

    const updateInputs = (payload) => {
        setInputs((prev) => ({ ...prev, ...payload }))
        updateVideoAdjustData((prev) => ({
            ...prev,
            ...payload
        }))
        Object.keys(payload).forEach((key) => {
            if(imgs && imgs[key]) {
                setImgs((prev) => ({...prev, [key]: undefined}))
            }
        })
    }

    const updateImageInputs = (payload) => {
        setImgs((prev) => ({...prev, ...payload}))
        updateVideoAdjustData((prev) => ({...prev, ...payload}))
        Object.keys(payload).forEach((key) => {
            setInputs((prev) => ({...prev, [key]: payload[key].fileName}))
        })
    }

    const onImageSet = (img) => {
        setImgs((prev) => ({
            ...prev, 
            [img.name]: {
                url: img.url, 
                fileName: img.fileName
            }
        }))

        setInputs((prev) => ({
            ...prev,
            [img.name]: img.fileName 
        }))
        
        updateVideoAdjustData((prev) => ({
            ...inputs,
            ...prev,
            [img.name]: {
                url: img.url,
                fileName: img.fileName
            }
        }))
    }

    const onImageDelete = (name) => {
        if(imgs && imgs[name]) {
            setImgs((prev) => ({
                ...prev,
                [name]: undefined
            }))
        }

        if(inputs && inputs[name]) {
            setInputs((prev) => ({
                ...prev,
                [name]: undefined
            }))
        }

        if(videoAdjustData && videoAdjustData[name]) {
            updateVideoAdjustData((prev) => ({
                ...prev,
                [name]: undefined
            }))
        }
    }

    const handleUrlChange = (event) => {
        const { name, value } = event.target;
        setInputs((prev) => ({ ...prev, [name]: value }))
        updateVideoAdjustData((prev) => ({
            ...inputs,
            ...prev,
            [name]: value
        }))
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        let searchKey = name.split("/")
        if (searchKey[0] !== "d") {
            setInputs({ ...inputs, [name]: value })
            updateVideoAdjustData({
                ...inputs,
                [name]: value
            })
        }

        else {
            //Follow the route given from searchKey array, finally update the key
            let tempInputs = JSON.parse(JSON.stringify(inputs))
            let deepKey = tempInputs[searchKey[1]]
            searchKey.splice(0, 2)

            for (let i = 0; i < (searchKey.length) - 1; i++) {
                deepKey = deepKey[searchKey[i]]
            }

            deepKey[searchKey[searchKey.length - 1]] = value
            setInputs(tempInputs)
            updateVideoAdjustData(tempInputs)
        }
    }

    const validateVideoData = (obj) => {

        let videoData;
        let validationSchema;

        if (!noEndScreen) {
            const { logo, endBtnText, endBkgImage, endBtnLink, endImageTransparent, endText, qr, packages2, packages3 } = obj;

            videoData = {
                ...videoData,
                logo,
                endBtnText,
                endBkgImage,
                endBtnLink,
                endImageTransparent,
                endText,
                qr,
                packages2,
                packages3
            }

            validationSchema = {
                ...validationSchema,
                "endBtnText": Joi.string().required(),
                "logo": Joi.alternatives().try(Joi.object({
                    "name": Joi.string().required(),
                    "fileName": Joi.string().required(),
                    "url": Joi.string().required()
                }), Joi.string()).required(),
                "endBkgImage": Joi.alternatives().try(Joi.object({
                    "name": Joi.string().required(),
                    "fileName": Joi.string().required(),
                    "url": Joi.string().required()
                }), Joi.string()).required(),
                "endBtnLink": Joi.string().required(),
                "endImageTransparent": Joi.string().pattern(/^POV=BE(\d{3})$/).required(),
                "endText": Joi.string().required(),
                "qr": Joi.alternatives().try(Joi.object({
                    "name": Joi.string().required(),
                    "fileName": Joi.string().required(),
                    "url": Joi.string().required()
                }), Joi.string()).required()
            }
        }

        if (!start) {
            const { scene1text, scene2text, scene3text } = obj;

            videoData = {
                ...videoData,
                scene1text,
                scene2text,
                scene3text,
            }

            validationSchema = {
                ...validationSchema,
                "scene1text": Joi.string().required(),
                "scene2text": Joi.string().required(),
                "scene3text": Joi.string().required(),
            }
        }

        if (!noSpec) {
            const { title1, text1, title2, text2, title3, text3, title4, text4, textcolorblack } = obj;

            videoData = {
                ...videoData,
                title1,
                text1,
                title2,
                text2,
                title3,
                text3,
                title4,
                text4,
                textcolorblack
            }

            validationSchema = {
                ...validationSchema,
                "title1": Joi.string().required(),
                "text1": Joi.string().required(),
                "title2": Joi.string().required(),
                "text2": Joi.string().required(),
                "title3": Joi.string().required(),
                "text3": Joi.string().required(),
                "title4": Joi.string().required(),
                "text4": Joi.string().required(),
                "textcolorblack": Joi.boolean().required()
            }
        }

        if (!noTwoEquipmentScene) {
            const { packages2 } = obj
            videoData = {
                ...videoData,
                packages2
            }

            validationSchema = {
                ...validationSchema,
                "packages2": Joi.object({
                    "1": Joi.object({
                        "code": Joi.string().required(),
                        "image": Joi.string().required(),
                        "text": Joi.string().required()
                    }).required(),
                    "2": Joi.object({
                        "code": Joi.string().required(),
                        "image": Joi.string().required(),
                        "text": Joi.string().required()
                    }).required(),
                    "title": Joi.string().required()
                }).required()
            }
        }

        if (!noThreeEquipmentScene) {
            const { packages3 } = obj
            videoData = {
                ...videoData,
                packages3
            }

            validationSchema = {
                ...validationSchema,
                "packages3": Joi.object({
                    "1": Joi.object({
                        "code": Joi.string().required(),
                        "image": Joi.string().required(),
                        "text": Joi.string().required()
                    }).required(),
                    "2": Joi.object({
                        "code": Joi.string().required(),
                        "image": Joi.string().required(),
                        "text": Joi.string().required()
                    }).required(),
                    "3": Joi.object({
                        "code": Joi.string().required(),
                        "image": Joi.string().required(),
                        "text": Joi.string().required()
                    }).required(),
                    "title": Joi.string().required()
                }).required()
            }
        }

        if (!outro) {
            const { outroText } = obj;

            videoData = {
                ...videoData,
                outroText,
            }

            validationSchema = {
                ...validationSchema,
                "outroText": Joi.string().required()
            }
        }


        const schema = Joi.object(validationSchema).options({ abortEarly: false })

        let value = schema.validate(videoData);

        if (value.error) {
            const invalidKeys = value.error.details.map((i) => i.context.key)
            const replacements = invalidKeys.reduce((acc, key) => {
                return {
                    ...acc,
                    [key]: imgs[key] || inputs[key]
                }
            }, {})
            videoData = { ...videoData, ...replacements }
            _notification({ title: t("notification.info"), message: t("notification.partiallyLoad"), duration: 10 });
        } else {
            _notification({ title: t("notification.info"), message: t("components.fullyLoad"), duration: 10 });
        }
        return videoData;
    }


    const handleRestoreToDefaults = () => {
        if (!defaultVideoAdjustData) return;
        setInputs(defaultVideoAdjustData)
        setImgs({})
        updateVideoAdjustData(null);
    }

    const handleSaveContent = async () => {
        const fileName = "mb-video-settings";
        let videoConst = []
        activeInputKeys.forEach((curr) => {
            if (inputs[curr] !== defaultVideoAdjustData[curr]) videoConst = { ...videoConst, [curr]: imgs[curr] || inputs[curr]}
        }, {})

        if (configurationSaved || videoConst.length === 0) {
            _notification({ title: t("notification.information"), message: t("notification.nothingToSave"), duration: 10 }); 
            return
        }
        
        setConfigurationSaved(true);
        // Data Post-Processing
        handlePackages(videoConst)
        handleSavePov(videoConst)
        // Data Post-Processing
        saveJson(videoConst, fileName)
    }

    const handleSavePov = (videoData) => {
        const regex = /POV=BE(\d{3})/;
        const match = inputs.endImageTransparent.match(regex);
        if (match && inputs.endImageTransparent !== defaultVideoAdjustData.endImageTransparent) {
            const value = match[1];
            videoData.endImageTransparent = `POV=BE${value}`
        }

    }

    const handleLoadPov = (videoData) => {
        const regex = /^POV=BE(\d{3})$/;
        const match = videoData.endImageTransparent.match(regex);
        if (match) {
            const value = match[0];
            let imageAngle = value.split("POV=BE")
            if (imageAngle[1] >= 0 && imageAngle[1] <= 359 && imageAngle[1]%10 === 0) {
                const newEndImageTransparent = newStringForImage.replace(/POV=BE\d{3}/, value);
                videoData.endImageTransparent = newEndImageTransparent
            }
            else { videoData.endImageTransparent = defaultVideoAdjustData.endImageTransparent }
        }
        else { videoData.endImageTransparent = inputs.endImageTransparent }

    }

    const handlePackages = (videoData) => {
        if (videoData.packages2) {
            delete videoData.packages2[1].detailslink
            delete videoData.packages2[2].detailslink
        } else if (videoData.packages3) {
            delete videoData.packages3[1].detailslink
            delete videoData.packages3[2].detailslink
            delete videoData.packages3[3].detailslink
        }

    }

    const handleLoadContent = (event) => {
        let file = event.target.files[0];
        if (file) {
            const fileReader = new FileReader();
            fileReader.readAsText(file, "UTF-8");
            fileReader.onload = e => {
                const target = e.target;
                const result = target?.result;
                try {
                    const resultJson = JSON.parse(result)
                    const validVideoData = validateVideoData(resultJson);
                    const [strings, objects] = Object.entries(validVideoData).reduce((acc, [key, value]) => {
                        if(typeof value === 'string') {
                            acc[0][key] = value;
                        } else if(typeof value === 'object' && value !== null) {
                            acc[1][key] = value;
                        }
                        return acc;
                    }, [{}, {}]);
                    updateImageInputs(objects)
                    handleLoadPov(strings)
                    updateInputs(strings)
                } catch (e) {
                    _notification({ title: "Error", message: `${t("notification.partiallyLoad")} ${e}`, duration: 10 });
                }
            }
        }
        event.target.value = null;
    }

    const sliderCarouselChange = (value) => {
        if(value === undefined)
            return;
        if (value === 0 || value === "0") {
            modifiedEndImageTransparent = newStringForImage.replace(/POV=BE\d{3}/, `POV=BE000`);
        }
        else if (value > 0 && value < 100) {
            modifiedEndImageTransparent = newStringForImage.replace(/POV=BE\d{3}/, `POV=BE0${value}`);
        }
        else {
            modifiedEndImageTransparent = newStringForImage.replace(/POV=BE\d{3}/, `POV=BE${value}`);
        }
        setNewStringForImage(modifiedEndImageTransparent);
        setInputs((prev) => ({ ...prev, "endImageTransparent": modifiedEndImageTransparent }))
        updateVideoAdjustData((prev) => ({
            ...prev,
            "endImageTransparent": modifiedEndImageTransparent
        }))
    }

    const findSliderValueOfPerspective = () => {
        const regex = /POV=BE(\d{3})/;
        const match = inputs.endImageTransparent.match(regex);
        if (match) {
            const value = match[1];
            if (value === "000") {
                return `${value.slice(-1)}`;
            }
            else if (value > 0 && value < 100) {
                return `${value.slice(-2)}`;
            } else {
                return value;
            }
        }
    }
    const expandIcon=({ isActive }) => <CaretDownOutlined rotate={isActive ? 180 : 0} style={{ transform: "scale(2) scaleX(2.25) scaleY(1.5)", marginRight: 20, marginBottom: 10 }} />

    return (
        <div className="VideoAdjustCollapse">
            <Collapse bordered={false}
                expandIcon={expandIcon}
                expandIconPosition="end"
                className=""
                key="startScene"
            >
                <Panel key={0} header={<PanelHeader headerName={t("videosTab.editVideoSceneHeadings.editAllScenes")} />} style={{ "border": "1px solid #000", "backgroundColor": "#f8f8f8" }}>

                    <Row gutter={[40, 40]} style={{ display: "flex", justifyContent: "space-between", }}>

                        <Col><Typography>{t("videosTab.editVideoScenesIntroText")}</Typography></Col>

                        <Col span={24} style={{ paddingLeft: 0 }}>

                            <Collapse
                                ghost={true}
                                className="VideoSettingsCollapse"
                                expandIconPosition="start"
                                key="PlayerSettings"
                            >

                                {!start &&
                                    <Panel key={1} header={<PanelHeader headerName={t("components.startScene")} data={editVideoStartSceneDataTable} description={videoEditTextStartScene} width={width} title={t("videosTab.editVideoSceneHeadings.editVideoStartSceneTitle")} />} className="VideoPanelSettings">
                                        <Row gutter={[20, 20]}>
                                            <Col span={filterSpan}><CustomInput key="scene1text" name="scene1text" val={inputs.scene1text} label={editVideoStartSceneDataTable[0].type} onChange={handleInputChange} /></Col>
                                            <Col span={filterSpan}><CustomInput name="scene2text" val={inputs.scene2text} label={editVideoStartSceneDataTable[1].type} onChange={handleInputChange} /></Col>
                                            <Col span={filterSpan}><CustomInput name="scene3text" val={inputs.scene3text} label={editVideoStartSceneDataTable[2].type} onChange={handleInputChange} /></Col>
                                        </Row>
                                    </Panel>
                                }


                                {!noSpec &&
                                    <Panel key={2} header={<PanelHeader headerName={t("components.specScene")} data={editVideoSpecSceneDataTable} description={videoEditTextSpecScene} width={width} title={t("videosTab.editVideoSceneHeadings.editVideoSpecSceneTitle")} />} className="VideoPanelSettings">
                                        <Row gutter={[20, 20]}>
                                            <Col span={filterSpan}><CustomInput name="title1" val={inputs.title1} label={editVideoSpecSceneDataTable[0].type} onChange={handleInputChange} /></Col>
                                            <Col span={filterSpan}><CustomInput name="text1" val={inputs.text1} label={editVideoSpecSceneDataTable[1].type} onChange={handleInputChange} /></Col>
                                            <Col span={filterSpan}><CustomInput name="title2" val={inputs.title2} label={editVideoSpecSceneDataTable[2].type} onChange={handleInputChange} /></Col>
                                            <Col span={filterSpan}><CustomInput name="text2" val={inputs.text2} label={editVideoSpecSceneDataTable[3].type} onChange={handleInputChange} /></Col>
                                            <Col span={filterSpan}><CustomInput name="title3" val={inputs.title3} label={editVideoSpecSceneDataTable[4].type} onChange={handleInputChange} /></Col>
                                            <Col span={filterSpan}><CustomInput name="text3" val={inputs.text3} label={editVideoSpecSceneDataTable[5].type} onChange={handleInputChange} /></Col>
                                            <Col span={filterSpan}><CustomInput name="title4" val={inputs.title4} label={editVideoSpecSceneDataTable[6].type} onChange={handleInputChange} /></Col>
                                            <Col span={filterSpan}><CustomInput name="text4" val={inputs.text4} label={editVideoSpecSceneDataTable[7].type} onChange={handleInputChange} /></Col>
                                            <Col ><CustomSwitch label={t("videosTab.editVideoSceneLabels.videoEditBlackColor")} onBtn={t("components.onButton")} offBtn={t("components.offButton")} status={[t("components.onButton"),t("components.offButton")]} val={!inputs.textcolorblack} onChange={(isChecked) => handleInputChange({ target: { name: "textcolorblack", value: !isChecked } })} /></Col>
                                        </Row>
                                    </Panel>
                                }


                                {(!noTwoEquipmentScene && inputs.packages2 && Object.keys(inputs.packages2)?.length !== 0) &&
                                    <Panel key={3} header={<PanelHeader headerName={t("components.twoEquipmentsScene")} data={editVideo2EquipmentScene} description={videoEditText} width={width} title={t("videosTab.editVideoSceneHeadings.editVideoEndSceneTitle")} />}>
                                        <Row gutter={[20, 20]}>
                                            <Col span={filterSpan}><CustomInput name="d/packages2/title" val={inputs.packages2.title} label={editVideo2EquipmentScene[0].type} onChange={handleInputChange} /></Col>
                                            <Divider orientation="left">{inputs.packages2[1].code}</Divider>
                                            <Popover content={<Content url={inputs.packages2[1].image} />} title="Image 1"><Col span={filterSpan}><CustomInput name="d/packages2/1/image" val={inputs.packages2[1].image} label={editVideo2EquipmentScene[1].type} onChange={handleInputChange} /></Col></Popover>
                                            <Col span={filterSpan}><CustomInput name="d/packages2/1/text" val={inputs.packages2[1].text} label={editVideo2EquipmentScene[2].type} onChange={handleInputChange} /></Col>
                                            <Divider orientation="left">{inputs.packages2[2].code}</Divider>
                                            <Popover content={<Content url={inputs.packages2[2].image} />} title="Image 2"><Col span={filterSpan}><CustomInput name="d/packages2/2/image" val={inputs.packages2[2].image} label={editVideo2EquipmentScene[3].type} onChange={handleInputChange} /></Col></Popover>
                                            <Col span={filterSpan}><CustomInput name="d/packages2/2/text" val={inputs.packages2[2].text} label={editVideo2EquipmentScene[4].type} onChange={handleInputChange} /></Col>
                                        </Row>
                                    </Panel>
                                }

                                {(!noThreeEquipmentScene && inputs.packages3 && Object.keys(inputs.packages3)?.length !== 0) &&
                                    <Panel key={4} header={<PanelHeader headerName={t("components.threeEquipmentsScene")} data={editVideo3EquipmentScene} description={videoEditText} width={width} title={t("videosTab.editVideoSceneHeadings.editVideoEndSceneTitle")} />}>
                                        <Row gutter={[20, 20]}>
                                            <Col span={filterSpan}><CustomInput name="d/packages3/title" val={inputs.packages3.title} label={editVideo2EquipmentScene[0].type} onChange={handleInputChange} /></Col>
                                            <Divider orientation="left">{inputs.packages3[1].code}</Divider>
                                            <Popover content={<Content url={inputs.packages3[1].image} />} title="Image 1"><Col span={filterSpan}><CustomInput name="d/packages3/1/image" val={inputs.packages3[1].image} label={editVideo2EquipmentScene[1].type} onChange={handleInputChange} /></Col></Popover>
                                            <Col span={filterSpan}><CustomInput name="d/packages3/1/text" val={inputs.packages3[1].text} label={editVideo2EquipmentScene[2].type} onChange={handleInputChange} /></Col>
                                            <Divider orientation="left">{inputs.packages3[2].code}</Divider>
                                            <Popover content={<Content url={inputs.packages3[2].image} />} title="Image 2"><Col span={filterSpan}><CustomInput name="d/packages3/2/image" val={inputs.packages3[2].image} label={editVideo2EquipmentScene[3].type} onChange={handleInputChange} /></Col></Popover>
                                            <Col span={filterSpan}><CustomInput name="d/packages3/2/text" val={inputs.packages3[2].text} label={editVideo2EquipmentScene[4].type} onChange={handleInputChange} /></Col>
                                            <Divider orientation="left">{inputs.packages3[3].code}</Divider>
                                            <Popover content={<Content url={inputs.packages3[3].image} />} title="Image 3"><Col span={filterSpan}><CustomInput name="d/packages3/3/image" val={inputs.packages3[3].image} label={editVideo3EquipmentScene[5].type} onChange={handleInputChange} /></Col></Popover>
                                            <Col span={filterSpan}><CustomInput name="d/packages3/3/text" val={inputs.packages3[3].text} label={editVideo3EquipmentScene[6].type} onChange={handleInputChange} /></Col>
                                        </Row>
                                    </Panel>
                                }

                                {!outro &&
                                    <Panel key={5} header={<PanelHeader headerName={t("components.outroScene")} data={editVideoOutroSceneDataTable} description={videoEditTextOutroScene} width={width} title={t("videosTab.editVideoSceneHeadings.editVideoOutroSceneTitle")} />} className="VideoPanelSettings">
                                        <Row gutter={[20, 20]}>
                                            <Col span={filterSpan}><CustomInput name="outroText" val={inputs.outroText} label={editVideoOutroSceneDataTable[0].type} onChange={handleInputChange} /></Col>
                                        </Row>
                                    </Panel>
                                }

                                {!noEndScreen &&
                                    <Panel key={6} header={<PanelHeader headerName={t("components.endScene")} data={editVideoEndSceneDataTable} description={videoEditText} width={width} title={t("videosTab.editVideoSceneHeadings.editVideoEndSceneTitle")} />}>
                                        <Row gutter={[20, 20]}>
                                            <Col span={filterSpan}>
                                                <CustomInputUpload name="logo" input={inputs.logo} img={imgs.logo} label={editVideoEndSceneDataTable[0].type} onChange={handleUrlChange} onImageSet={onImageSet} onImageDelete={onImageDelete} popoverTitle="Dealer Logo"/>
                                            </Col>
                                            <Col span={filterSpan}>
                                                <CustomInput name="endBtnText" val={inputs.endBtnText} label={editVideoEndSceneDataTable[3].type} onChange={handleInputChange} />
                                            </Col>
                                            <Col span={filterSpan}>
                                                <CustomInputUpload name="endBkgImage" input={inputs.endBkgImage} img={imgs.endBkgImage} label={editVideoEndSceneDataTable[1].type} onChange={handleUrlChange} onImageSet={onImageSet} onImageDelete={onImageDelete} popoverTitle="Background Image"/>
                                            </Col>
                                            <Col span={filterSpan}>
                                                <CustomInput onChange={handleInputChange} val={inputs.endBtnLink} name="endBtnLink" label={editVideoEndSceneDataTable[4].type} />
                                            </Col>
                                            <Col span={filterSpan}>
                                                <CustomInput val={inputs.endText} name="endText" label={editVideoEndSceneDataTable[2].type} onChange={handleInputChange} />
                                            </Col>
                                            <Col span={filterSpan}>
                                                <CustomInputUpload name="qr" input={inputs.qr} img={imgs.qr} label={editVideoEndSceneDataTable[5].type} onChange={handleUrlChange} onImageSet={onImageSet} onImageDelete={onImageDelete} popoverTitle="QR Code" />
                                            </Col>
                                            <Col span={filterSpan}>
                                                <div style={{ border: "1px solid #ccc" }}>
                                                    <div className="CustomInput">
                                                        {<div className="CustomInputLabel" >{t("videosTab.endSceneEntries.vehiclePerspective")}</div>}
                                                        {(<Input className="CustomInputValue" value={`${findSliderValueOfPerspective()}°`} bordered={false} disabled={true} />)}
                                                    </div>
                                                    <img style={{ width: "100%" }} src={inputs.endImageTransparent} alt=""></img>
                                                    <div style={{ padding: "0px 20px" }}>
                                                        <Slider onChange={sliderCarouselChange} value={findSliderValueOfPerspective()} min={0} max={350} step={10} tipFormatter={value => `${value}°`} />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Panel>
                                }

                            </Collapse>
                        </Col>


                        <Col span={24}><Typography >{t("videosTab.editVideoScenesClosureText")}</Typography></Col>

                        <Col span={24}>
                            <Row gutter={[20, 10]} justify="end">
                                <Col><CustomButton label={t("loadSaveButtons.saveSettings")} filledIn={false} onClick={handleSaveContent} /></Col>
                                <Col><CustomButton label={t("loadSaveButtons.loadSettings")} filledIn={false} onClick={() => fileInputRef.current?.click()} /></Col>
                                <Col><CustomButton label={t("loadSaveButtons.resetSettings")} filledIn={false} onClick={handleRestoreToDefaults} /></Col>
                            </Row>
                        </Col>

                        {/* HIDDEN INPUT */}
                        <input type="file" onChange={handleLoadContent} ref={fileInputRef} name="" id="fileUpload" hidden></input>

                    </Row>

                </Panel>

            </Collapse>
        </div>
    );
}

const calculateSpan = (width) => width > 710 ? 12 : 24

export default VideoAdjust;