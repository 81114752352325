
import { DeleteFilled, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import _notification from '../Utils/notification';
import { useTranslation } from "react-i18next";
import { WbIcon } from '@workbench/react';



const ImageInput = (props) => {
    const { name, onImageSet, onImageDelete, disabled, oldImageUrl, fileSize, uploadText = "Upload" } = props;

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(oldImageUrl);
    const [imageObject, setImageObject] = useState();
    const { t } = useTranslation();

    //This useEffect created to handle settings-file based changes.
    useEffect(() => {
        if (!oldImageUrl) {
            deleteImage();
        }
        else if (oldImageUrl !== imageUrl) {
            setImageUrl(oldImageUrl);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oldImageUrl]);

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', (event) => callback(reader.result, event));
        reader.readAsDataURL(img.originFileObj);
    };


    const beforeUpload = file => {
        if (imageUrl) {
            deleteImage();
        }

        const isLessThenAllowedFileSize = file.size * 0.000001 < fileSize;
        if (!isLessThenAllowedFileSize) {
            _notification({ message: t("notification.loadedImageFileSizeError", { fileSize: fileSize }) });
            return false
        }

        const isTypeValid = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'image/svg+xml';
        return isTypeValid;
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
        else if (info.file.status === 'error') {
            setLoading(false);
        }

        else if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file, (url, event) => {
                const fileName = info.file.name;
                const _loadedImageUrl = event.target.result;
                const image = document.createElement('img');
                image.src = _loadedImageUrl;
                image.addEventListener('load', () => {
                    const { width, height } = image;
                    setImageUrl(url);
                    setLoading(false);
                    setImageObject(image);
                    onImageSet({ name, url, width, height, fileName })
                });


            });
        }
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    const deleteImage = (e = null) => {
        if (e != null) {
            e.stopPropagation();
        }

        if (typeof imageObject?.remove === 'function') {
            imageObject?.remove();
        }

        setImageUrl(null);
        onImageDelete(name);

    }

    const uploadButton = (
        <div>
            <WbIcon name="bds/upload/24" style={uploadText ? {position: "absolute", left: 0, marginLeft: 40} : {}}/>

            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginLeft: 10,
                    fontWeight: "bold"
                }}
            >
                {uploadText}
            </div>
        </div>
    );
    return (
        <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            customRequest={dummyRequest}
            disabled={disabled}
        >
        <div>
        </div>
            {imageUrl ? (
                
                <div style={{ position: 'relative' }}>

                    <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                            width: '100%',
                            fontSize: 0
                        }}
                    />
                    <span style={{ position: "absolute", left: '50%', top: '50%', transform: 'translate(-50%,-50%)', color: '#0078D6', fontSize:"18px", cursor: 'pointer' }} onClick={deleteImage} onKeyDown={deleteImage}>
                        <DeleteFilled  />
                    </span>
                </div>
            ) : (
                uploadButton
            )}
        </Upload>
    );
};

export default ImageInput;