import { useEffect, useMemo, useState } from "react";
import { Collapse } from "antd";

import { RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import ImageRenderItem from "./ImageRenderItem";

const { Panel } = Collapse;
const ImageRenderList = (props) => {
    const { vin, imageList, rowCount, imageType, background, isDownload, innerPanels } = props;

    const { t } = useTranslation();

    const [extList, setExtList] = useState([]);
    const [bisList, setBisList] = useState([]);
    const [intList, setIntList] = useState([]);
    const [panels, setPanels] = useState([]);

    useMemo(() => {
        if (imageList) {
            const tempExtList = imageList
                .filter((image) => image.title.match("EXT"))
                .sort((a, b) => a.title.localeCompare(b.title));
            const tempbBisList = imageList
                .filter((image) => image.title.match("BIS"))
                .sort((a, b) => a.title.localeCompare(b.title));
            const tempIntList = imageList
                .filter((image) => image.title.match("INT") || image.title.match("BET"))
                .sort((a, b) => a.title.localeCompare(b.title));
            setExtList(tempExtList);
            setBisList(tempbBisList);
            setIntList(tempIntList);
        }
    }, [imageList, rowCount]);

    useEffect(() => {
        handlePanels(innerPanels);
    }, [innerPanels]);

    function handlePanels(innerPanels) {
        const tempAvailablePanels = [];

        if (extList.length > 0) tempAvailablePanels.push(0);
        if (intList.length > 0) tempAvailablePanels.push(1);
        if (bisList.length > 0) tempAvailablePanels.push(2);

        if (innerPanels) {
            setPanels(tempAvailablePanels);
        } else {
            setPanels([]);
        }
    }
    const expandIcon=({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />

    return (
        <Collapse
            bordered={false}
            expandIcon={expandIcon}
            activeKey={panels}
            onChange={setPanels}
            className="ModelsMenu"
        >
            {extList.length > 0 && (
                <Panel header={t("imagesTab.layouts.ext")} key={0} className="ModelsInnerMenu">
                    <ImageRenderItem
                        vin={vin}
                        imageList={extList}
                        imageType={imageType}
                        background={background}
                        rowCount={rowCount}
                        isDownload={isDownload}
                    />
                </Panel>
            )}
            {intList.length > 0 && (
                <Panel header={t("imagesTab.layouts.int")} key={1} className="ModelsInnerMenu">
                    <ImageRenderItem
                        vin={vin}
                        imageList={intList}
                        imageType={imageType}
                        background={background}
                        rowCount={rowCount}
                        isDownload={isDownload}
                    />
                </Panel>
            )}
            {bisList.length > 0 && (
                <Panel header={t("imagesTab.layouts.bis")} key={2} className="ModelsInnerMenu">
                    <ImageRenderItem
                        vin={vin}
                        imageList={bisList}
                        imageType={imageType}
                        background={background}
                        rowCount={rowCount}
                        isDownload={isDownload}
                    />
                </Panel>
            )}
        </Collapse>
    );
};

export default ImageRenderList;
