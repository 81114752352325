function Content(props) {
    const { url } = props
    const imageRegex = /^(data:image\/[a-zA-Z0-9+.-]+;base64,|(https:\/\/)(www\.)?[a-zA-Z0-9@:%._\+~#=-]{1,256}\.[a-zA-Z0-9]{1,6}[\/a-zA-Z0-9@:%._\+~#=-]{1,256}(.png|.jpg|.webp|.jpeg))/g;
    const result = imageRegex.test(url)
    if (result) {
        return <img src={url} style={{ "width": 200 }} alt="" />
    }
    else {
        return <div>Preview is not available</div>
    }
}

export default Content;
