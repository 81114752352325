import {
    WbGrid
} from '@workbench/react';


function CookiesPage() {
    return <WbGrid>
        <div><div class="page-content"><section class=""><h1 class="section__title">Statement on the use of cookies, the analysis of usage data and the use of analysis tools</h1></section><div class="paragraph-wrapper section-spacing-large"><div data-v-7042a4e9="" class="text-paragraph"><p><span><span><span>We use cookies and similar software tools such as HTML5 Storage or Local Shared Objects (together "cookies") to identify your interests and particularly popular areas of our websites and use this information to improve the design of our websites and make them even more user-friendly. For the same purposes we use the analysis tool Google Analytics; cookies may also be used here.</span></span></span></p>

            <ol>
                <li><strong><span lang="EN-US"><span>Functions and use of cookies</span></span></strong><br />
                    &nbsp;
                    <ol>
                        <li><span lang="EN-US"><span>Cookies are small files that are placed on your desktop, notebook or mobile device by a website you visit. From this we can, for example, recognize whether there has already been a connection between your device and our websites, or which language or other settings you prefer. Cookies may also contain personal data.</span></span><br />
                            &nbsp;</li>
                        <li><span lang="EN-US"><span>By using our websites, you agree to the use of cookies.<br />
                            <br />
                            You can also visit our website without consenting to the use of cookies. This means that you can refuse such use and delete cookies at any time by making the appropriate settings on your device. This is done as follows:</span></span><br />
                            &nbsp;
                            <ul>
                                <li><span lang="EN-US"><span>Most browsers are pre-set to automatically accept cookies. You can change this setting by activating the setting *do not accept cookies* in your browser.</span></span><br />
                                    &nbsp;</li>
                                <li><span lang="EN-US"><span>You can delete existing cookies at any time. You can find out how this works in detail in the instructions of your browser or device manufacturer.</span></span><br />
                                    &nbsp;</li>
                                <li>For information on deactivating Local Shared Objects, see the following link:<br />
                                    <br />
                                    <a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager02.html">Information on deactivating Local Shared Objects</a><br />
                                    &nbsp;</li>
                                <li><span lang="EN-US"><span>Like the use of cookies, their rejection or deletion is also linked to the device used and also to the browser used in each case. You must therefore reject or delete the cookies separately for each of your devices and, if you use several browsers, also for each browser.</span></span><br />
                                    &nbsp;</li>
                            </ul>
                        </li>
                        <li><span lang="EN-US"><span>If you decide against the use of cookies, it is possible that not all functions of our websites are available to you or that individual functions are only available to you to a limited extent.</span></span><br />
                            &nbsp;</li>
                        <li><span lang="EN-US"><span>We categorize cookies as follows:</span></span><br />
                            &nbsp;
                            <ol>
                                <li><span><span><span><em>Essential cookies (type 1)</em></span></span></span><br />
                                    <span><span><span>These cookies are absolutely necessary for the functions of the web pages. Without these cookies we would not be able to offer you services such as the vehicle configurator, for example.</span></span></span><br />
                                    &nbsp;</li>
                                <li><em><span lang="EN-US"><span>Functional cookies (type 2)</span></span></em><br />
                                    <span><span><span>These cookies facilitate the operation and improve the functions of the web pages. For example, we store your language settings in functional cookies.</span></span></span><br />
                                    &nbsp;</li>
                                <li><em><span lang="EN-US"><span>Performance cookies (Type 3)</span></span></em><br />
                                    <span><span><span>These cookies collect information about how you use our website. This enables us to identify which parts of our Internet offer are particularly popular and thus improve our offer for you. Please also read the section "Analysis of usage data".</span></span></span><br />
                                    &nbsp;</li>
                                <li><em><span lang="EN-US"><span>Third-party cookies (type 4)</span></span></em><br />
                                    <span><span><span>These cookies are set by third parties, e.g. social networks such as Facebook, Twitter and Google+, whose content you can integrate via the "social plug-ins" offered on our websites. For more information on the use and function of the social plug-ins, please refer to section 4 of our Privacy Statement.</span></span></span><br />
                                    <br />
                                    <a href="/legal/privacy">Privacy statement</a><br />
                                    &nbsp;</li>
                            </ol>
                        </li>
                        <li><span><span><span>We use the following cookies on our website:</span></span></span><br />
                            <br />
                            <em><span>cookieSession</span></em><br />
                            <span><span>This cookie stores the session ID, which pools related requests to the server and assigns them to a session.</span></span><br />
                            <br />
                            <span><span>cookiesAccepted</span></span><br />
                            This cookie indicates that you agree to the usage of cookies on our webpages. It is set once you have accepted the cookie information banner.<br />
                            <br />
                            <span><em>informational dialogs</em></span><br />
                            Depending on your usage, there will be a number of cookies that persist the information that you dismissed a dialog. The name of the cookies will contain the name of the dialog that was dismissed.<br />
                            <br />
                            <em><span>product announcements</span></em><br />
                            Depending on your usage and the number of product announcements that we provide, there will be a number of cookies that indicate that you dismissed a product announcement. It is set once you close a product announcement on a product page.<br />
                            The name of this cookie cannot be predicted, as it contains the hash of the product announcement. An example name would be “7923586b244df3f4b44c4032e76a07e0”<br />
                            &nbsp;</li>
                    </ol>
                </li>
                <li><strong><span lang="EN-US"><span>Analysis of usage data; use of analysis tools</span></span></strong><br />
                    &nbsp;
                    <ol>
                        <li><span><span>We would like to tailor the content of our websites as precisely as possible to your interests and in this way improve our offer for you. In order to identify usage preferences and particularly popular areas of the websites, we use the following analysis tool: Google Analytics.</span></span><br />
                            &nbsp;</li>
                        <li><span><span>When using these analysis tools, data may be transferred to servers located in the USA and processed there. Please note the following: In the USA, the European Union considers that there is no "adequate level of protection" for the processing of personal data in accordance with EU standards. However, this level of protection can be replaced for individual companies by certification according to the so-called "EU-U.S. Privacy Shield".</span></span><br />
                            &nbsp;</li>
                        <li><span><span>If you do not want us to collect and analyze information about your visit to our website using the analysis tools mentioned above, you can object to this at any time with effect for the future ("opt-out").</span></span><br />
                            &nbsp;</li>
                        <li><span><span>We implement your objection by setting an opt-out cookie in your browser. This cookie is only used to associate your objection. Please note that for technical reasons, an opt-out cookie only works in the browser in which it was set. If you delete the cookies or use a different browser or device, please opt-out again.</span></span><br />
                            &nbsp;</li>
                        <li><span><span>Below you will find information on the providers of the analysis tools we use and the respective opt-out options:</span></span><br />
                            &nbsp;
                            <ul>
                                <li><span><span><strong>Google Inc. ("Google"):</strong></span></span><br />
                                    <span><span>Google is certified according to the EU-U.S. Privacy Shield.</span></span><br />
                                    <span lang="EN-US"><span>You can prevent the transfer of your data and its collection and processing by Google. Google informs about this via the following link: </span></span><a href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=e</a><span>&nbsp;</span></li>
                            </ul>
                        </li>
                    </ol>
                </li>
            </ol></div></div></div></div>
    </WbGrid>
}

export default CookiesPage;