import { WbIcon, WbText } from '@workbench/react';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWidth from '../Utils/useWidth';

const MBDataLinks = {
    en: "https://data.mercedes-benz.com/products",
    de: "https://data.mercedes-benz.com/de/produkte"
  }

function SubHeaderBar() {
    const { i18n, t } = useTranslation();
    const width = useWidth()
    const [language, setLanguage] = useState(navigator.language.split("-")[0] === "de" ? "English" : "Deutsch");
    const [expandedMenu, setExpandedMenu] = useState(false);
    const [hoverLeft, setHoverLeft] = useState("");
    const [hoverWidth, setHoverWidth] = useState("");

    useEffect(() => {
        language === "English" ? i18n.changeLanguage("de") : i18n.changeLanguage("en");
    }, [i18n, language])

    useEffect(() => {
        adaptNavbar()
    }, [width])

    function adaptNavbar() {
        if (window.innerWidth >= 992) {
            setExpandedMenu(false);
        }
    }

    const styles = {
        left: hoverLeft,
        width: hoverWidth,
    }

    function updateHover(element) {
        const boundries = element.target.getBoundingClientRect()
        setHoverLeft(boundries.left);
        setHoverWidth(boundries.width);
    }

    function removeHover() {
        setHoverWidth(0)
    }

    function handleLanguage() {
        if(language === "English"){
            setLanguage("Deutsch")
        } else {
            setLanguage("English")
        }
    }

    return (
        <div className="sub-header-container">
            <div className="sub-header">
                <div className='HeaderLinks-hover' style={styles} />
                <Row justify="space-between" align={"middle"} className="sub-header-row">
                    <Col xs={12} lg={0}>
                        <div className="retailer-dashboard-logo" />
                    </Col>
                    <Col className={expandedMenu ? "ExpandedHeaderLinks" : "HeaderLinks"} xxl={12} >
                        <span className="HeaderLink" >
                            <a
                                href={t("mainScreen.headerBar.dataLink")}
                                rel="noopener noreferrer"
                                target="_blank"
                                onMouseEnter={updateHover}
                                onMouseLeave={removeHover}
                            >
                                {t("mainScreen.headerBar.mercedesBenzData")}
                            </a>
                        </span>
                        <span className="HeaderLink">
                            <a
                                href="https://developer.mercedes-benz.com/products?u=Retailer"
                                rel="noopener noreferrer"
                                target="_blank"
                                onMouseEnter={updateHover}
                                onMouseLeave={removeHover}
                            >
                                {t("mainScreen.headerBar.products")}
                            </a>
                        </span>
                        <span className="HeaderLink">
                            <a
                                href="https://developer.mercedes-benz.com/contact/vehicle_images/business_inquiry"
                                rel="noopener noreferrer"
                                target="_blank"
                                onMouseEnter={updateHover}
                                onMouseLeave={removeHover}
                            >
                                {t("mainScreen.headerBar.contact")}
                            </a>
                        </span>
                        <span className="HeaderLink">
                            <a
                                href="https://developer.mercedes-benz.com/contact/vehicle_images/technical_inquiry"
                                rel="noopener noreferrer"
                                target="_blank"
                                onMouseEnter={updateHover}
                                onMouseLeave={removeHover}
                            >
                                {t("mainScreen.headerBar.support")}
                            </a>
                        </span>
                    </Col>
                    <Col>
                        <span className='languageIcon-container' onClick={handleLanguage} onKeyDown={handleLanguage}>
                            <WbIcon className='languageIcon' name='language'/>
                            <WbText>{language} </WbText>
                        </span>
                    </Col>
                </Row>
            </div >
        </div>
    );
}

export default SubHeaderBar;
