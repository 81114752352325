// Defaults
const defaultOptions = {
    format: 'image/png',
    quality: 1,
    width: undefined,
    height: undefined,
    Canvas: undefined,
    crossOrigin: undefined
};

const _drawImages = (ctx, image) => {
    ctx.globalAlpha = image.opacity ? image.opacity : 1;
    if (image.width && image.height) {
        return ctx.drawImage(image.img, image.x || 0, image.y || 0, image.width, image.height);
    }
    else {
        return ctx.drawImage(image.img, image.x || 0, image.y || 0);
    }
}

const _loadSources = (Image, sources, options) => {
    return sources.map(source => new Promise((resolve, reject) => {
        // Convert sources to objects
        if (source.constructor.name !== 'Object') {
            source = { src: source };
        }

        // Resolve source and img when loaded
        const img = new Image();
        img.crossOrigin = options.crossOrigin;
        img.onerror = () => reject(new Error('Couldn\'t load image'));
        img.onload = () => resolve({ ...source, img});
        img.src = source.src;
    }));
}

// Return Promise
const mergeImages = (sources = [], options = {}) => new Promise(resolve => {
    options = { ...defaultOptions, ...options};

    // Setup browser/Node.js specific variables
    const canvas = options.Canvas ? new options.Canvas() : window.document.createElement('canvas');
    const Image = options.Image || window.Image;

    // Load sources
    const images = _loadSources(Image, sources, options);

    // Get canvas context
    const ctx = canvas.getContext('2d');

    // When sources have loaded
    resolve(Promise.all(images)
        .then(images => {
            // Set canvas dimensions
            const getSize = dim => options[dim] || Math.max(...images.map(image => image.img[dim]));
            canvas.width = getSize('width');
            canvas.height = getSize('height');

            // Draw images to canvas
            images.forEach((img) => _drawImages(ctx, img));

            if (options.Canvas && options.format === 'image/jpeg') {
                // Resolve data URI for node-canvas jpeg async
                return new Promise((resolve, reject) => {
                    canvas.toDataURL(options.format, {
                        quality: options.quality,
                        progressive: false
                    }, (err, jpeg) => {
                        if (err) {
                            reject(err);
                            return;
                        }
                        resolve(jpeg);
                    });
                });
            }

            // Resolve all other data URIs sync
            return canvas.toDataURL(options.format, options.quality);
        }));
});

export default mergeImages;