import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import {translationsEn} from "../Constants/Translations/translationsEn";
import {translationsDe} from "../Constants/Translations/translationsDe";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources: {
      en: {
        translation: translationsEn
      },
      de: {
        translation: translationsDe
      }
    }
  });

export default i18n;