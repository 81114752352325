import React, { useRef, useEffect } from "react";

function Interior360View(props) {
    const { images } = props;
    const ref360InteriorView = useRef();

    useEffect(() => {
        if (ref360InteriorView.current) {
            // Change order as 3-1-4-5-0-2
            ref360InteriorView.current.textureUrls = [images[3], images[1], images[4], images[5], images[0], images[2]];
        }
        ref360InteriorView.current.fieldOfView = 100;
    }, [images]);

    return <wb-sphere-view ref={ref360InteriorView} />;
}

export default Interior360View;
